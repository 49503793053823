import { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Loader, useEscape, useForm, useOutsideClick } from '@hometap/htco-components';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import { showNotification } from 'utils/toasts';
import { REQUEST_REGGORA_ORDER_REVISION } from '../../../ApplicationReview/sections/HomeValuationsController/reggoraRequests';
import SliderFormTitle from './common/SliderFormTitle';
import { Divider } from '@mui/material';
import SliderFormBody from './common/SliderFormBody';
import SliderFormFooter from './common/SliderFormFooter';
import SliderFormContainer from './common/SliderFormContainer';
import RevisionRequestForm from './forms/RevisionRequestForm';
import './common/SliderForm.scss';

type RevisionRequestSliderProps = {
  orderId: string;
  trackId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refetch: () => Promise<void>;
};

const RevisionRequestSlider = ({ orderId, trackId, isOpen, setIsOpen, refetch }: RevisionRequestSliderProps) => {
  const [errorBlockMessage, setErrorBlockMessage] = useState<null | string>(null);

  const [requestRevision, { loading: requestRevisionLoading }] = useMutation(REQUEST_REGGORA_ORDER_REVISION);

  const { registerField, updateFormData, isFormValid, formData } = useForm({
    title: '',
    revisionReason: '',
  });

  const handleCloseSlider = () => {
    updateFormData({
      title: '',
      revisionReason: '',
    });

    setIsOpen(false);
    setErrorBlockMessage(null);
  };

  useEscape(true, () => handleCloseSlider());

  const handleRevisionRequest = async () => {
    const queryVariables = {
      orderId: orderId,
      trackId: trackId,
      title: formData?.title,
      revisionReason: formData?.revisionReason,
    };

    try {
      await requestRevision({ variables: queryVariables });
      showNotification({
        type: 'success',
        title: 'Revision Request Successfully Submitted',
      });
      await refetch();
      handleCloseSlider();
    } catch (error: unknown) {
      setErrorBlockMessage(
        `The revision request could not be created due to "${
          error instanceof Error ? error?.message : 'an unknown error'
        }".`,
      );
    }
  };

  /**
   * Allows the slider to be closed when clicking outside of the slider.
   * @param {MouseEvent} event - A click event.
   */
  const handleBackdropClick = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
    if (event instanceof KeyboardEvent) return;
    const target = event.target as HTMLElement;
    const cssClass = target?.className;
    if (
      cssClass.includes('htco-MenuDropdownItem') ||
      cssClass.includes('AppraisalsNewAppraisalButton') ||
      cssClass.includes('SliderFormSubmit')
    ) {
      return;
    }

    handleCloseSlider();
  };
  const ref = useRef(null);
  useOutsideClick(ref.current, handleBackdropClick);

  return (
    <SliderFormContainer ref={ref} isVisible={isOpen}>
      <SliderFormTitle title="Revision request" onClose={handleCloseSlider} closeDisabled={requestRevisionLoading} />
      <Divider />
      <SliderFormBody>
        <RevisionRequestForm registerField={registerField} disabled={requestRevisionLoading} />
        {errorBlockMessage && (
          <ErrorBlock
            icon="list"
            error={
              <>
                {errorBlockMessage} Please try again or{' '}
                <a href="https://hometap.slack.com/archives/CBN5REDQ9">reach out</a> to report the issue.
              </>
            }
          />
        )}
      </SliderFormBody>
      <Divider />
      <SliderFormFooter className="justify-end">
        <div className="flex items-center">
          <Button onClick={handleCloseSlider} theme="secondary" size={'small'}>
            Cancel
          </Button>
          {requestRevisionLoading ? (
            <div className="ml-[16px] flex h-[40px] w-[148px] items-center justify-center rounded-lg bg-neutral-dark-30">
              <Loader type="dot-pulse" theme="inverse" />
            </div>
          ) : (
            <Button
              className="SliderFormSubmit"
              size={'small'}
              onClick={() => handleRevisionRequest()}
              disabled={!isFormValid || requestRevisionLoading || !formData?.title || !formData?.revisionReason}
            >
              Create request
            </Button>
          )}
        </div>
      </SliderFormFooter>
    </SliderFormContainer>
  );
};

export default RevisionRequestSlider;
