import React, { useCallback, useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { gql } from '@apollo/client';
import { Accordion, Button, FormError, Icon, Loader, Tooltip } from '@hometap/htco-components';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { getTrackDetailPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { FINAL_APPROVED, MORE_INFO_NEEDED, NO_PASS, getReviewStatusesMap, getTasksMap } from './TaskListUtils';
import { getGraphQLError } from 'utils/errors';
import useCurrentUser from 'hooks/useCurrentUser';
import { getFirstIncompleteTask } from '../../trackTasksUtils';
import TaskGroupItem from '../TaskGroupItem/TaskGroupItem';
import ApplicationChanges from '../ApplicationChanges/ApplicationChanges';
import MenuDropdown from '../../../../../components/MenuDropdown/MenuDropdown';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './TaskList.scss';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import isEmpty from 'lodash/isEmpty';

const TaskList = ({
  tasks,
  upcomingTasks,
  loading,
  reviewStatus,
  reviewStatusChoices,
  canChangeReviewStatusObj,
  onChangeReviewStatus,
  error,
}) => {
  const { applicationUnlockStatus, isTrackDisabled } = useOutletContext();
  const { taskId, trackId } = useParams();
  const navigate = useNavigate();
  const { isInApplicationSpecialistGroup } = useCurrentUser();

  const { createTasksUrl } = getTrackDetailPageUrls(trackId);

  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [initialReviewStatus, setInitialReviewStatus] = useState(reviewStatus?.value);

  const { value: reviewStatusValue, label: reviewStatusLabel } = reviewStatus;
  const isFinalApproved = reviewStatusValue === FINAL_APPROVED;
  const errorText = getGraphQLError(error);

  const tasksMap = getTasksMap(tasks, reviewStatus);
  const reviewStatusesMap = getReviewStatusesMap(reviewStatusChoices);

  const isReviewStatusDisabled =
    isEmpty(canChangeReviewStatusObj) || !canChangeReviewStatusObj?.canSelect || !reviewStatusChoices.length;

  const updateExpandedAccordions = useCallback(
    reviewStatus => {
      if (reviewStatus && !expandedAccordions.includes(reviewStatus)) {
        setExpandedAccordions([...expandedAccordions, reviewStatus]);
      }
    },
    [expandedAccordions],
  );

  useEffect(() => {
    if (reviewStatusValue && initialReviewStatus === undefined) {
      /**
       * save initial review status to always show the correct chosen
       * task when we hide/open the TaskList by TaskDrawer
       * also if we open the task from the table
       * in the separate useEffect it works twice and navigate to another task
       */
      return setInitialReviewStatus(reviewStatusValue);
    }

    if (reviewStatusValue !== initialReviewStatus) {
      setInitialReviewStatus(reviewStatusValue);
      /**
       * If the review status was changed then
       * the taskId will be changed and the accordions are updated
       */
      const updatedReviewStatusFirstIncompleteTaskId = getFirstIncompleteTask(tasks, reviewStatusValue)?.identifier;

      if (updatedReviewStatusFirstIncompleteTaskId !== taskId) {
        if (tasks?.length && updatedReviewStatusFirstIncompleteTaskId) {
          return navigate(`/tracks/${trackId}/tasks/${updatedReviewStatusFirstIncompleteTaskId}`);
        }
        /**
         * If we don't have any tasks with updatedReviewStatus and
         * the task was not finally approved
         * we could open accordion with updatedReviewStatus
         */
        if (!updatedReviewStatusFirstIncompleteTaskId && !isFinalApproved) {
          updateExpandedAccordions(reviewStatusValue);
        }
      }
    }
  }, [
    reviewStatusValue,
    initialReviewStatus,
    tasks,
    taskId,
    isFinalApproved,
    navigate,
    trackId,
    updateExpandedAccordions,
  ]);

  useEffect(() => {
    const taskReviewStatus = tasks.find(({ identifier }) => identifier === taskId)?.reviewStatus;
    /**
     * to always open the correct accordion with the task
     * on updating page, opening task from table
     */
    if (taskReviewStatus) {
      setExpandedAccordions(expandedAccordions => {
        return [...expandedAccordions, taskReviewStatus];
      });
    }
  }, [taskId, tasks]);

  const stripOpsCopyFromReviewStatus = status => {
    const opStatusPrefix = 'Ops - ';
    return status?.replace(opStatusPrefix, '');
  };

  const handleClickReviewStatus = e => {
    const selectedReviewStatus = e.target?.id || e.target?.parentNode?.id;

    if (selectedReviewStatus && selectedReviewStatus !== reviewStatusValue) {
      onChangeReviewStatus(selectedReviewStatus);
      setIsMenuOpen(false);
    }
  };

  const handleGroupItemClick = useCallback(
    (task, isActive) => {
      if (isActive || !task) return;

      navigate(`/tracks/${trackId}/tasks/${task.identifier}`);
    },
    [trackId, navigate],
  );

  useEffect(() => {
    const currentTaskIdx = tasks?.findIndex(({ identifier }) => identifier === taskId);

    if (currentTaskIdx === -1) {
      return;
    }

    const currentTaskReviewStatus = tasks[currentTaskIdx].reviewStatus;

    const handleKeyDown = e => {
      switch (e.key) {
        case 'ArrowDown':
          handleGroupItemClick(tasks[currentTaskIdx + 1], false);
          setIsMenuOpen(false);
          break;
        case 'ArrowUp':
          handleGroupItemClick(tasks[currentTaskIdx - 1], false);
          setIsMenuOpen(false);
          break;
        case 'ArrowRight':
          if (expandedAccordions.includes(currentTaskReviewStatus)) {
            setExpandedAccordions(expandedAccordions.filter(status => status !== currentTaskReviewStatus));
          }
          break;
        case 'ArrowLeft':
          if (!expandedAccordions.includes(currentTaskReviewStatus)) {
            setExpandedAccordions([...expandedAccordions, currentTaskReviewStatus]);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [expandedAccordions, setExpandedAccordions, tasks, handleGroupItemClick, taskId]);

  const shouldDisplayReviewStatus = statusValue => {
    const invalidStatusValues = [MORE_INFO_NEEDED, NO_PASS, FINAL_APPROVED];
    return !invalidStatusValues.includes(statusValue);
  };

  return (
    <div className="TaskList">
      {loading && (
        <div className="TaskListLoadingWrapper">
          <Loader type="dot-pulse" size="large" overlay />
        </div>
      )}
      {errorText && <FormError className="TaskListError" standalone error={errorText} />}

      <div className="TaskListTasks">
        <div className="TaskListDropdownContainer">
          <div className="TaskListDropdown">
            <Tooltip
              action={isReviewStatusDisabled && !isFinalApproved ? undefined : 'none'}
              className="TaskListTooltip"
              content={`Cannot change review status. ${canChangeReviewStatusObj?.canSelectReasons?.[0]}`}
            >
              <MenuDropdown
                isMenuOpen={isMenuOpen}
                onMenuChange={setIsMenuOpen}
                trigger={
                  isFinalApproved
                    ? reviewStatusLabel
                    : stripOpsCopyFromReviewStatus(reviewStatusesMap[reviewStatusValue])
                }
                openClassName="isOpen"
                isDisabled={isReviewStatusDisabled}
              >
                {reviewStatusChoices
                  .filter(({ value }) => shouldDisplayReviewStatus(value))
                  .map(({ label, value }, index) => {
                    const isActive = value === reviewStatusValue;
                    const isBlocked = canChangeReviewStatusObj?.blockedStatuses.includes(value);
                    const isDisabled = !isActive && (isBlocked || value === FINAL_APPROVED);
                    return (
                      <Tooltip
                        key={index}
                        action={isBlocked ? undefined : 'none'}
                        className="TaskListTooltip"
                        content={`Cannot change to ${label}. ${canChangeReviewStatusObj?.blockedStatusesReasons?.[0]}`}
                      >
                        <div
                          className={cx('TaskListDropdownItem', { isActive, isDisabled })}
                          id={value}
                          onClick={handleClickReviewStatus}
                          key={value}
                        >
                          <span className="TaskListDropdownItemLabel">{stripOpsCopyFromReviewStatus(label)}</span>
                          {isActive && <Icon name="icon-check-mdc" className="TaskListDropdownItemIcon" />}
                        </div>
                      </Tooltip>
                    );
                  })}
              </MenuDropdown>
            </Tooltip>
          </div>
          {applicationUnlockStatus && <ApplicationChanges trackId={trackId} status={applicationUnlockStatus} />}
        </div>

        <div className="TaskListAccordions">
          {Object.keys(tasksMap).map((status, index) => {
            const isActive = reviewStatusValue === status;
            const isOpen = expandedAccordions.includes(status) || isTrackDisabled;
            // if we don't remount <Accordion /> when we have different items to show, it will crop the list
            const accordionKey = Array.prototype.concat(
              tasksMap[status].map(task => task.identifier),
              isActive ? upcomingTasks.map(upcomingTask => upcomingTask.identifier) : [],
            );

            return (
              <div className="TaskListAccordion" key={index}>
                <Accordion
                  isOpen={isOpen}
                  onChange={() =>
                    setExpandedAccordions(
                      expandedAccordions.includes(status)
                        ? expandedAccordions.filter(accordion => accordion !== status)
                        : [...expandedAccordions, status],
                    )
                  }
                  theme="with-arrow"
                  header={stripOpsCopyFromReviewStatus(reviewStatusesMap[status])}
                  key={accordionKey}
                >
                  <TransitionGroup className="TaskListAccordion">
                    {tasksMap[status].length > 0 &&
                      tasksMap[status].map(task => {
                        const { identifier, taskStatus, name } = task;
                        const isActive = identifier === taskId;
                        const itemRef = createRef(null);
                        return (
                          <CSSTransition nodeRef={itemRef} key={identifier} timeout={500} classNames="TaskGroupItem">
                            <TaskGroupItem
                              ref={itemRef}
                              key={identifier}
                              isActive={isActive}
                              header={name}
                              status={taskStatus}
                              onChange={() => handleGroupItemClick(task, isActive)}
                            />
                          </CSSTransition>
                        );
                      })}
                    {upcomingTasks.length > 0 &&
                      isActive &&
                      upcomingTasks.map(upcomingTask => {
                        const { identifier, name } = upcomingTask;
                        const itemRef = createRef(null);
                        return (
                          <CSSTransition nodeRef={itemRef} key={identifier} timeout={500} classNames="TaskGroupItem">
                            <TaskGroupItem
                              ref={itemRef}
                              key={identifier}
                              disabled={true}
                              isActive={false}
                              header={name}
                              status={TASK_STATUSES.PENDING_TO_DO}
                            />
                          </CSSTransition>
                        );
                      })}
                  </TransitionGroup>
                </Accordion>

                {isActive && isOpen && isInApplicationSpecialistGroup && (
                  <Button
                    theme="link"
                    icon="icon-plus"
                    className={cx('TaskListAccordionButton', { isActive })}
                    href={createTasksUrl}
                  >
                    Add new tasks
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <Button theme="secondary" size="small" className="TaskListButton" href={`/tracks/${trackId}/todos`}>
        View Homeowner To-dos
      </Button>
    </div>
  );
};

TaskList.fragments = {
  TaskListData: gql`
    fragment TaskListData on TrackNode {
      reviewStatus {
        label
        value
      }
      reviewStatusChoices {
        label
        value
      }
      canChangeReviewStatus {
        canSelect
        canSelectReasons
        blockedStatuses
        blockedStatusesReasons
      }
      internalTasks {
        id
        identifier
        taskStatus
        taskDefinitionKey
        name
        reviewStatus
        isMandatory
        resolutionLabel
        isManual
        createdAtStage
        createdBy {
          email
        }
      }
    }
  `,
};

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      taskStatus: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      reviewStatus: PropTypes.string.isRequired,
    }),
  ).isRequired,
  upcomingTasks: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  reviewStatus: PropTypes.object,
  reviewStatusChoices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  canChangeReviewStatusObj: PropTypes.shape({
    canSelect: PropTypes.bool,
    canSelectReasons: PropTypes.arrayOf(PropTypes.string),
    blockedStatuses: PropTypes.arrayOf(PropTypes.string),
    blockedStatusesReasons: PropTypes.arrayOf(PropTypes.string),
  }),
  onChangeReviewStatus: PropTypes.func.isRequired,
  error: PropTypes.object,
  hasPendingProofOfReplacementCost: PropTypes.bool,
};

TaskList.defaultProps = {
  reviewStatusChoices: [],
};

export default TaskList;
