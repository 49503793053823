import { gql } from '@apollo/client';

// Use this to ensure we consistently get all the data the page needs for orders to function
const REGGORA_ORDER_DETAILS = gql`
  fragment RequiredOrderDetails on AppraisalOrderNode {
    identifier
    orderId
    createdAt
    acceptedVendorId
    acceptedVendorName
    appraisalSubmissionDatetime
    appraisalType
    inspectionDatetime
    isPrimary
    loanNumber
    notes
    orderCreatedDatetime
    orderStatus
    appraisalCost
    totalFee
    product
    propertyCondition
    underwriterApproved
    appraisalOfRecord
    createdBy {
      identifier
      fullNameShort
      user {
        identifier
        friendlyId
        firstName
        lastName
      }
    }
    document {
      ... on Document {
        identifier
        filename
      }
    }
  }
`;

export const GET_REGGORA_ORDERS = gql`
  query OrderDetails($trackId: String!) {
    orders(trackId: $trackId) {
      ...RequiredOrderDetails
    }
  }
  ${REGGORA_ORDER_DETAILS}
`;

export const GET_REGGORA_ORDER_BY_ID = gql`
  query getReggoraOrderById($orderId: String!, $trackId: IdentifierType!) {
    order(orderId: $orderId, trackId: $trackId) {
      ...RequiredOrderDetails
    }
  }
  ${REGGORA_ORDER_DETAILS}
`;

export const REFRESH_REGGORA_ORDERS = gql`
  mutation reggoraRefreshOrders($orderIds: [String]!, $trackId: IdentifierType!) {
    reggoraRefreshOrders(orderIds: $orderIds, trackId: $trackId) {
      orders {
        ...RequiredOrderDetails
      }
    }
  }
  ${REGGORA_ORDER_DETAILS}
`;

export const CHANGE_REGGORA_ORDER_APPROVAL = gql`
  mutation reggoraOrderApprovalChange($orderId: String!, $trackId: IdentifierType!, $underwriterApproved: Boolean!) {
    reggoraOrderApprovalChange(orderId: $orderId, trackId: $trackId, underwriterApproved: $underwriterApproved) {
      order {
        ...RequiredOrderDetails
      }
    }
  }
  ${REGGORA_ORDER_DETAILS}
`;

export const CHANGE_REGGORA_ORDER_PROPERTY_CONDITION = gql`
  mutation updatePropertyCondition($orderId: String!, $trackId: IdentifierType!, $propertyCondition: String!) {
    reggoraPropertyConditionChange(orderId: $orderId, trackId: $trackId, propertyCondition: $propertyCondition) {
      order {
        ...RequiredOrderDetails
      }
    }
  }
  ${REGGORA_ORDER_DETAILS}
`;

export const ADD_REGGORA_ORDER_NOTES = gql`
  mutation reggoraOrderNotes($orderId: String!, $trackId: IdentifierType!, $notes: String!) {
    reggoraOrderNotes(orderId: $orderId, trackId: $trackId, notes: $notes) {
      order {
        ...RequiredOrderDetails
      }
    }
  }
  ${REGGORA_ORDER_DETAILS}
`;

export const GET_REGGORA_PRODUCTS = gql`
  query reggoraProducts {
    reggoraProducts {
      products {
        label
        value
      }
    }
  }
`;

export const CREATE_NEW_REGGORA_ORDER = gql`
  mutation reggoraNewOrder(
    $trackId: IdentifierType!
    $products: [String]!
    $dueDate: Date!
    $isRush: Boolean!
    $contactFirstName: String!
    $contactLastName: String!
    $contactEmail: String!
    $contactPhoneNumber: String!
    $contactInfoType: ReggoraContactRolesEnum!
  ) {
    reggoraCreateNewOrder(
      trackId: $trackId
      products: $products
      dueDate: $dueDate
      isRush: $isRush
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactEmail: $contactEmail
      contactPhoneNumber: $contactPhoneNumber
      contactInfoType: $contactInfoType
    ) {
      response
    }
  }
`;

export const CREATE_FOLLOW_UP_REGGORA_ORDER = gql`
  mutation reggoraFollowUpOrder(
    $dueDate: Date!
    $isRush: Boolean!
    $originatingOrderId: IdentifierType!
    $trackId: IdentifierType!
    $products: [String]!
  ) {
    reggoraSendFollowUpOrder(
      dueDate: $dueDate
      isRush: $isRush
      originatingOrderId: $originatingOrderId
      trackId: $trackId
      products: $products
    ) {
      response
    }
  }
`;

// TrackForReggoraOrderNode type tracks this query
export const GET_TRACK_DETAILS_FOR_REGGORA_ORDER = gql`
  query trackDetailsForReggoraOrder($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      friendlyId
      home {
        identifier
        propertyTypeLabel
        address {
          identifier
          street
          unit
          city
          state
          zipCode
        }
      }
      phoneNumber
      applicants {
        identifier
        isPrimaryApplicant
        firstName
        lastName
        phoneNumber
        person {
          identifier
          email
        }
      }
      appraisalType {
        label
        value
      }
    }
  }
`;

export const REQUEST_REGGORA_ORDER_REVISION = gql`
  mutation reggoraOrderRevision(
    $orderId: IdentifierType!
    $trackId: IdentifierType!
    $title: String!
    $revisionReason: String!
  ) {
    reggoraRevisionRequest(orderId: $orderId, trackId: $trackId, title: $title, revisionReason: $revisionReason) {
      response {
        orderId
        acceptedVendorId
        acceptedVendorName
        orderStatus
      }
    }
  }
`;

export const SET_REGGORA_ORDER_AS_APPRAISAL_OF_RECORD = gql`
  mutation reggoraSetAppraisalOfRecord($orderId: IdentifierType!, $trackId: IdentifierType!) {
    reggoraSetAppraisalOfRecord(orderId: $orderId, trackId: $trackId) {
      order {
        orderId
      }
    }
  }
`;

// Deprecated and can be removed when we remove the `use_appraisal_review_task` flag
export const CANCEL_REGGORA_ORDER = gql`
  mutation reggoraCancelOrder($orderId: IdentifierType!, $trackId: IdentifierType!) {
    reggoraCancelOrder(orderId: $orderId, trackId: $trackId) {
      order {
        orderId
      }
    }
  }
`;

export const CANCEL_APPRAISAL_REVIEW_PROCESS = gql`
  mutation reggoraCancelAppraisalReviewProcess($orderId: IdentifierType!, $trackId: IdentifierType!) {
    reggoraCancelAppraisalReviewProcess(orderId: $orderId, trackId: $trackId) {
      order {
        orderId
      }
    }
  }
`;

export const GET_PHYSICAL_APPRAISAL_DATA = gql`
  query PhysicalAppraisalData($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      homeValuationReviewProviderOptions {
        label
        value
      }
    }
    relevantAppraisalDocuments(trackId: $trackId) {
      ... on DocumentInterface {
        filename
        identifier
      }
    }
  }
`;

export const GET_TRADITIONAL_APPRAISAL_XML_DATA = gql`
  query getTraditionalAppraisalXmlData($orderId: IdentifierType!) {
    traditionalAppraisalXmlData(orderId: $orderId) {
      value
      asOfDate
      appraiserName
      certificationNumber
      scheduledDate
    }
  }
`;

export const ORDER_TRADITIONAL_APPRAISAL = gql`
  mutation createPhysicalAppraisalHomeValuation(
    $appraiserName: String!
    $asOfDate: Date!
    $certificationNumber: String!
    $documentFileId: IdentifierType!
    $kindNumber: String!
    $provider: String!
    $providerOther: String
    $scheduledDate: Date!
    $trackId: IdentifierType!
    $value: Int!
  ) {
    createPhysicalAppraisalHomeValuation(
      appraiserName: $appraiserName
      asOfDate: $asOfDate
      certificationNumber: $certificationNumber
      documentFileId: $documentFileId
      kindNumber: $kindNumber
      provider: $provider
      providerOther: $providerOther
      scheduledDate: $scheduledDate
      trackId: $trackId
      value: $value
    ) {
      response {
        identifier
        kind
        kindOther
        kindNumber
        provider
        providerOther
        value
        appraiserName
        certificationNumber
        scheduledDate
        asOfDate
      }
    }
  }
`;
