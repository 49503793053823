import { gql } from '@apollo/client';

export const APPROVE_TRACK = gql`
  mutation ApproveTrack($trackId: IdentifierType!) {
    approveTrack(trackId: $trackId) {
      ok
      errors
      track {
        identifier
        reviewStatus {
          label
          value
        }
      }
    }
  }
`;
