import { gql } from '@apollo/client';

export const GET_UW_CALCULATOR_MAX_INVESTMENT_DATA = gql`
  mutation UwCalculateInvestmentDetails(
    $highestSingleYearDelinquentTaxes: DollarsAndCents!
    $rb6Criteria: Int!
    $trackId: IdentifierType!
  ) {
    calculateUwInvestment(
      highestSingleYearDelinquentTaxes: $highestSingleYearDelinquentTaxes
      rb6Criteria: $rb6Criteria
      trackId: $trackId
    ) {
      eligibilityStatus
      increaseInPaydown
      investmentAmount
      hometapUpshare
      assumedTotalPaydown
      toHomeownerBeforeFees
      lossThreshold
      totalEndingEquity
      hometapDownshare
      homeownerEndingEquity
      withholdingTotal
      maxCombinedLoanToValue
      uwFailedEligibilityCriteria {
        code
        details
      }
    }
  }
`;

export const GET_SALES_CALCULATOR_MAX_INVESTMENT_DATA = gql`
  mutation SalesCalculateInvestmentDetails(
    $trackId: IdentifierType!
    $appraisedHomeValue: DollarsAndCents
    $totalDebtOnHome: DollarsAndCents
    $feePaydown: DollarsAndCents
    $principalPaydown: DollarsAndCents
  ) {
    calculateSalesInvestment(
      trackId: $trackId
      appraisedHomeValue: $appraisedHomeValue
      totalDebtOnHome: $totalDebtOnHome
      feePaydown: $feePaydown
      principalPaydown: $principalPaydown
    ) {
      eligibilityStatus
      increaseInPaydown
      investmentAmount
      assumedTotalPaydown
      toHomeownerBeforeFees
      totalEndingEquity
      homeownerEndingEquity
      uwFailedEligibilityCriteria {
        code
        details
      }
    }
  }
`;

export const GET_GUIDE_CALCULATOR_MAX_INVESTMENT_DATA = gql`
  mutation SalesCalculateInvestmentDetails(
    $appraisedHomeValue: DollarsAndCents
    $totalDebtOnHome: DollarsAndCents
    $feePaydown: DollarsAndCents
    $principalPaydown: DollarsAndCents
  ) {
    calculateSalesInvestment(
      appraisedHomeValue: $appraisedHomeValue
      totalDebtOnHome: $totalDebtOnHome
      feePaydown: $feePaydown
      principalPaydown: $principalPaydown
    ) {
      eligibilityStatus
      increaseInPaydown
      investmentAmount
      assumedTotalPaydown
      toHomeownerBeforeFees
      totalEndingEquity
      homeownerEndingEquity
      uwFailedEligibilityCriteria {
        code
        details
      }
    }
  }
`;

export const SAVE_SYNC_UW_CALCULATOR_MAX_INVESTMENT_DATA = gql`
  mutation UwCalculatorSaveSyncMaxInvestment(
    $investmentAmount: DollarsAndCents!
    $highestSingleYearDelinquentTaxes: DollarsAndCents!
    $rb6Criteria: Int!
    $trackId: IdentifierType!
  ) {
    uwSaveSyncMaxInvestment(
      trackId: $trackId
      highestSingleYearDelinquentTaxes: $highestSingleYearDelinquentTaxes
      rb6Criteria: $rb6Criteria
      investmentAmount: $investmentAmount
    ) {
      ok
      investmentAmount
    }
  }
`;
