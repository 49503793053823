import { useMemo } from 'react';
import { useSectionFormById } from '../../../hooks/useSpecificContentQuery';
import { useForm } from '@hometap/htco-components';

export const useInitialLienForm = (lien, initialFormDataFromLienNode) => {
  const {
    initialFormData: initialFormDataFromSectionMap,
    prevFormData,
    showTriggeredPublishErrors,
  } = useSectionFormById(lien.identifier);
  const initialFormData = useMemo(
    () => initialFormDataFromSectionMap ?? initialFormDataFromLienNode(lien),
    [initialFormDataFromSectionMap, lien, initialFormDataFromLienNode],
  );
  const policyFormData = prevFormData || initialFormData;

  const {
    registerField,
    formData,
    updateFormData,
    isFormValid,
    errors,
    setErrors,
    showSubmitErrors,
    handleFieldChange,
  } = useForm(policyFormData, {
    showInitialErrors: !lien.newLien || !!showTriggeredPublishErrors,
    shouldUpdateDefaultValues: true,
    shouldUpdateInitialErrors: true,
  });

  return {
    registerField,
    formData,
    updateFormData,
    isFormValid,
    errors,
    initialFormData,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
    handleFieldChange,
  };
};
