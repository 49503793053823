import React from 'react';
import cx from 'classnames';

type SliderFormFieldProps = {
  label: string;
  field: React.ReactNode;
  className?: string;
};

const SliderFormField = ({ label, field, className }: SliderFormFieldProps) => {
  return (
    <div className={cx('flex flex-col gap-[4px]', className)}>
      <strong className="text-neutral-dark-100">{label}</strong>
      {field}
    </div>
  );
};

export default SliderFormField;
