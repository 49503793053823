import { useEffect, useState } from 'react';
import { currencyWithCents } from '../../../../../../utils/numbers';
import { useSectionFormById } from '../../../hooks/useSpecificContentQuery';
import { getIsNewId, getLienSectionsData, getSortedSections } from '../utils/liens';

export const useLienSections = ({
  sections,
  lienId,
  paydownId,
  liensKindOptions,
  lienKind,
  setLienPositionError,
  lienKindsPrincipalAmountBalance,
}) => {
  const [lienSectionsData, setLienSectionsData] = useState();
  const { formData, isValidForm } = useSectionFormById(lienId);
  const { formData: paydownFormData } = useSectionFormById(paydownId);

  useEffect(() => {
    if (sections) {
      setLienSectionsData(getLienSectionsData(sections, lienKindsPrincipalAmountBalance));
    }
  }, [sections, lienKindsPrincipalAmountBalance]);

  // The Total Lien Balance  on the new Mortgage & Lien Section does real-time update
  useEffect(() => {
    const lienStub =
      formData?.identifier && formData.identifier === lienId
        ? {
            currentBalance: formData.currentBalance,
            maxLineAmount: formData.maxLineAmount,
            onPropertyReport: formData.onPropertyReport,
            originalAmount: formData.originalAmount,
            identifier: lienId,
          }
        : undefined;

    if (!lienStub) return;
    setLienSectionsData(({ sections }) => {
      let updatedSections;
      if (sections.find(sec => sec.identifier === lienStub.identifier)) {
        updatedSections = sections.map(section => {
          if (section.identifier === lienStub.identifier) {
            // Liens that are in lienKindsPrincipalAmountBalance need to be handled differently from other types of liens.
            // For newLiens we don't set kind immediately until we select it, but we should identify it before calculating the Balance
            const newLienKind =
              section.newLien && !section.kind && formData?.kind ? formData.kind.toUpperCase() : section.kind;
            return {
              ...section,
              ...lienStub,
              kind: newLienKind,
            };
          }
          return section;
        });
      } else {
        updatedSections = [...sections, lienStub];
      }
      return getLienSectionsData(updatedSections, lienKindsPrincipalAmountBalance);
    });
  }, [
    lienId,
    formData?.currentBalance,
    formData?.maxLineAmount,
    formData?.onPropertyReport,
    formData?.originalAmount,
    formData?.identifier,
    formData?.kind,
    isValidForm,
    lienKindsPrincipalAmountBalance,
  ]);

  // The Position on the new Mortgage & Lien Section does real-time update
  useEffect(() => {
    const type = liensKindOptions.find(valLabelPair => valLabelPair.value === lienKind?.toLowerCase());
    const subSectionLabel = getIsNewId(lienId) ? 'New lien' : type?.label;
    const lienPosition =
      formData?.identifier && formData.identifier === lienId
        ? {
            position: formData.position,
            subSectionLabel:
              formData?.position && formData.onPropertyReport === 'true'
                ? `(${formData?.position}) ${subSectionLabel}`
                : subSectionLabel,
            identifier: lienId,
            selectorId: lienId,
            onPropertyReport: formData.onPropertyReport,
          }
        : undefined;

    if (!lienPosition) return;

    setLienSectionsData(sectionsData => {
      if (lienPosition.position === 0) {
        setLienPositionError(prevState => {
          const newState = { ...prevState };
          newState[lienId] = `Lien Position cannot be 0`;
          return newState;
        });
        return sectionsData;
      }
      if (
        sectionsData.sections.some(sec => sec.position === lienPosition.position && sec.identifier !== lienId) &&
        lienPosition.position > 0
      ) {
        setLienPositionError(prevState => {
          const newState = { ...prevState };
          newState[lienId] = `A Lien Position with position ${lienPosition.position} already exists`;
          return newState;
        });
        return sectionsData;
      }
      setLienPositionError(prevState => {
        const newState = { ...prevState };
        delete newState[lienId];
        return newState;
      });

      let updatedSections;
      if (sectionsData.sections.find(sec => sec.identifier === lienPosition.identifier)) {
        updatedSections = sectionsData.sections.map(section => {
          if (section.identifier === lienPosition.identifier) {
            return {
              ...section,
              ...lienPosition,
            };
          }
          return section;
        });
      } else {
        updatedSections = [...sectionsData.sections, lienPosition];
      }
      const sorted = updatedSections?.sort(getSortedSections);
      return { ...sectionsData, sections: sorted };
    });
  }, [
    formData?.position,
    formData?.onPropertyReport,
    formData?.identifier,
    lienId,
    lienKind,
    liensKindOptions,
    setLienPositionError,
  ]);

  // The Total Paydown balance and the htLienPosition  on the new Lien Section does real-time update
  useEffect(() => {
    const paydownStub =
      paydownFormData?.identifier && paydownFormData.identifier === paydownId
        ? {
            principalPaydownAmount: paydownFormData?.principalPaydownAmount,
            feePaydownAmount: paydownFormData?.feePaydownAmount,
            identifier: paydownId,
            isPayoff: paydownFormData?.isPayoff,
          }
        : undefined;

    if (!paydownStub) return;
    setLienSectionsData(({ sections }) => {
      let updatedSections;
      if (sections.find(sec => sec.paydown?.identifier === paydownStub.identifier)) {
        updatedSections = sections.map(section => {
          if (section.paydown?.identifier === paydownStub.identifier) {
            return {
              ...section,
              paydown: {
                ...section.paydown,
                ...paydownStub,
              },
            };
          }
          return section;
        });
      } else {
        updatedSections = sections;
      }
      return getLienSectionsData(updatedSections, lienKindsPrincipalAmountBalance);
    });
  }, [
    paydownId,
    paydownFormData?.principalPaydownAmount,
    paydownFormData?.feePaydownAmount,
    paydownFormData?.identifier,
    paydownFormData?.isPayoff,
    lienKindsPrincipalAmountBalance,
  ]);

  const { principalPaydown = 0, feePaydown = 0, htLienPosition } = lienSectionsData || {};

  return {
    lienSections: lienSectionsData?.sections || [],
    totalBalance: currencyWithCents(lienSectionsData?.totalBalance || 0),
    principalPaydown: currencyWithCents(principalPaydown),
    feePaydown: currencyWithCents(feePaydown),
    totalPaydown: currencyWithCents(principalPaydown + feePaydown),
    htLienPosition,
  };
};
