import { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Loader, useEscape, useForm, useOutsideClick } from '@hometap/htco-components';
import {
  GET_REGGORA_PRODUCTS,
  CREATE_NEW_REGGORA_ORDER,
  CREATE_FOLLOW_UP_REGGORA_ORDER,
} from '../../../ApplicationReview/sections/HomeValuationsController/reggoraRequests';
import useNewAppraisalOrder from '../../hooks/useNewAppraisalOrder';
import { showNotification } from 'utils/toasts';
import SliderFormContainer from './common/SliderFormContainer';
import SliderFormTitle from './common/SliderFormTitle';
import { Divider } from '@mui/material';
import SliderFormFooter from './common/SliderFormFooter';
import AppraisalOrderForm from './forms/AppraisalOrderForm';
import { calculateDueDate } from './utils/utils';
import './common/SliderForm.scss';

/**
 * Slider form for ordering a new appraisal or a follow-up appraisal.
 * @param {Object} props - Component properties.
 * @param {?string} props.orderId - The Reggora order ID.
 * @param {boolean} props.isOpen - Whether or not the slider is open.
 * @param {Function} props.setIsOpen - Function to set whether or not the slider is open.
 * @param {string} props.trackId - The track ID.
 * @param {?Function} props.refetch - Function to refetch the data.
 * @param {?boolean} props.hasOverride - Whether or not to override the loading state from useNewAppraisalOrder
 * @param {?boolean} props.overrideLoading - Whether or not to override the loading state from useNewAppraisalOrder
 * @param {?Object} props.overridePrefilledOrderValues - The prefilled order values from useNewAppraisalOrder
 * @returns {JSX.Element} The slider form to order new appraisals or follow-up orders.
 */
const AppraisalOrderSlider = ({
  orderId,
  isOpen = false,
  setIsOpen = () => {},
  trackId,
  refetch = () => {},
  hasOverride = false,
}) => {
  const [errorBlockMessage, setErrorBlockMessage] = useState();

  const { data: reggoraProductData, loading: isProductsLoading } = useQuery(GET_REGGORA_PRODUCTS);
  const reggoraProductOptions = reggoraProductData?.reggoraProducts?.products || [];

  const [requestNewReggoraOrder, { loading: requestNewReggoraOrderLoading }] = useMutation(CREATE_NEW_REGGORA_ORDER);
  const [requestFollowUpOrder, { loading: followUpOrderLoading }] = useMutation(CREATE_FOLLOW_UP_REGGORA_ORDER);
  const loading = requestNewReggoraOrderLoading || followUpOrderLoading;

  // information about the track, used to prefill the form
  const { prefilledOrderValues, trackDetailsForReggoraOrderDataLoading: formPrefillDetailsLoading } =
    useNewAppraisalOrder(trackId);

  const {
    registerField,
    updateFormData,
    isFormValid,
    formData,
    errors: formErrors,
    setErrors,
  } = useForm({
    products: null,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    rush: false,
  });

  const isFormLoading = formPrefillDetailsLoading || isProductsLoading;

  const handleCloseSlider = () => {
    updateFormData({
      products: null,
      firstName: prefilledOrderValues?.primaryApplicant?.firstName || '',
      lastName: prefilledOrderValues?.primaryApplicant?.lastName || '',
      emailAddress: prefilledOrderValues?.primaryApplicant?.person?.email || '',
      phoneNumber: prefilledOrderValues?.primaryApplicant?.phoneNumber || '',
      rush: false,
    });

    setIsOpen(false);
    setErrors('products', null, false);
    setErrorBlockMessage(null);
  };

  useEscape(true, () => handleCloseSlider());

  // Needed in order to pre-populate form data correctly
  useEffect(() => {
    updateFormData({
      firstName: prefilledOrderValues?.primaryApplicant?.firstName || '',
      lastName: prefilledOrderValues?.primaryApplicant?.lastName || '',
      emailAddress: prefilledOrderValues?.primaryApplicant?.person?.email || '',
      phoneNumber: prefilledOrderValues?.primaryApplicant?.phoneNumber || '',
    });
  }, [prefilledOrderValues, updateFormData]);

  const handleFollowUpOrder = async () => {
    const queryVariables = {
      dueDate: calculateDueDate(formData?.rush),
      isRush: formData?.rush,
      originatingOrderId: orderId,
      trackId: trackId,
      products: formData?.products,
    };

    try {
      await requestFollowUpOrder({ variables: queryVariables });
      showNotification({
        type: 'success',
        title: 'Follow Up Appraisal Order Successfully Placed',
        description: `'Follow Up Appraisal for ${prefilledOrderValues.friendlyId} has been requested.`,
      });
      await refetch();
      handleCloseSlider();
    } catch (error) {
      setErrorBlockMessage(
        `The follow-up appraisal order could not be placed due to "${error?.message || 'an unknown error'}".`,
      );
    }
  };

  const handleNewOrder = async () => {
    const queryVariables = {
      trackId: trackId,
      products: [formData?.products],
      dueDate: calculateDueDate(formData?.rush),
      isRush: formData?.rush,
      contactFirstName: formData?.firstName,
      contactLastName: formData?.lastName,
      contactEmail: formData?.emailAddress,
      contactPhoneNumber: formData?.phoneNumber,
      contactInfoType: 'BORROWER', // hardcoded for now
    };
    try {
      await requestNewReggoraOrder({ variables: queryVariables });
      await refetch();
      handleCloseSlider();
      showNotification({
        type: 'success',
        title: 'New Appraisal Order Successfully Placed',
        description: `Appraisal for ${prefilledOrderValues.friendlyId} has been requested.`,
      });
    } catch (error) {
      setErrorBlockMessage(
        `The appraisal order could not be placed due to "${
          error?.message || 'an unknown error'
        }" or an order already exists.`,
      );
    }
  };

  // The custom onBlur and this useEffect are both needed to provide correct behavior
  // as the onBlur function gets the old value, but you need to check on user click out
  // or the form loads with an error state
  useEffect(() => {
    if (formData?.products && formErrors?.products?.show) {
      setErrors('products', null, false);
    }
  }, [formData?.products, formErrors?.products?.show, setErrors]);

  const customProductOnBlur = () => {
    if (formErrors?.products) {
      setErrors('products', formErrors?.products?.message, true);
    }
  };

  /**
   * Allows the slider to be closed when clicking outside of the slider.
   * @param {MouseEvent} event - A click event.
   */
  const handleBackdropClick = event => {
    const cssClass = event.target.className;
    if (
      cssClass.includes('SliderFormSelect') ||
      cssClass.includes('htco-MenuDropdownItem') ||
      cssClass.includes('AppraisalsNewAppraisalButton') ||
      cssClass.includes('SliderFormSubmit')
    ) {
      return;
    }

    handleCloseSlider();
  };
  const ref = useRef(null);
  useOutsideClick(ref.current, handleBackdropClick);

  return (
    <SliderFormContainer ref={ref} isVisible={isOpen}>
      <SliderFormTitle
        closeDisabled={loading}
        title={orderId ? 'Follow-up order' : 'New appraisal order'}
        onClose={handleCloseSlider}
      />

      <Divider />

      {isFormLoading ? (
        <div className="h-full">
          <Loader
            size="large"
            theme="primary"
            type="spinner"
            className="flex h-full w-full flex-col items-center justify-center"
          />
        </div>
      ) : (
        <>
          <AppraisalOrderForm
            loading={loading}
            isFollowUp={!!orderId}
            errorMessage={errorBlockMessage}
            prefilledOrderValues={prefilledOrderValues}
            productOptions={reggoraProductOptions}
            formErrors={formErrors}
            registerField={registerField}
            productOnBlur={customProductOnBlur}
            formData={formData}
            isInSlider={true}
          />
          <Divider />
          <SliderFormFooter className={'h-auto justify-between'}>
            <Checkbox
              disabled={requestNewReggoraOrderLoading}
              label={<strong className="text-sm">Rush order</strong>}
              checked={formData?.rush}
              onChange={value => updateFormData({ rush: value })}
            />

            <div className="flex items-center">
              <Button onClick={handleCloseSlider} theme="secondary" size={'small'}>
                Cancel
              </Button>
              {loading ? (
                <div className="ml-[16px] flex h-[40px] w-[155px] items-center justify-center rounded-lg bg-neutral-dark-30">
                  <Loader type="dot-pulse" theme="inverse" />
                </div>
              ) : (
                <Button
                  className="SliderFormSubmit"
                  size={'small'}
                  onClick={() => {
                    if (orderId) {
                      handleFollowUpOrder();
                    } else {
                      handleNewOrder();
                    }
                  }}
                  disabled={!isFormValid || loading || !formData?.products}
                >
                  Order appraisal
                </Button>
              )}
            </div>
          </SliderFormFooter>
        </>
      )}
    </SliderFormContainer>
  );
};

export default AppraisalOrderSlider;
