import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon, useOutsideClick, useAsync } from '@hometap/htco-components';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import ACTION_TOOL_TIP_OPTIONS from 'data/constants/actionTooltipOptions';
import sendMagicLink from 'data/sendMagicLink';
import { canSendMagicLink } from 'userRoles';
import useCurrentUser from 'hooks/useCurrentUser';
import './ActionsTooltip.scss';

const COPY_ICON = {
  copy: {
    iconName: 'icon-copy',
    text: 'Copy',
  },
  check: {
    iconName: 'icon-check-mdc',
    text: 'Copied',
  },
};

const emailContents = (
  <>
    <Icon name="icon-email" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">Email</span>
  </>
);
const phoneContents = (
  <>
    <Icon name="icon-phone" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">Call</span>
  </>
);
const getLinkContents = name => (
  <>
    <Icon name="icon-link" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">{name || 'Visit URL'}</span>
  </>
);
const editContents = (
  <>
    <Icon name="icon-edit" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">Edit</span>
  </>
);

const EmailElement = ({ emailAddress, onClose }) => {
  const emailLink = `mailto:${emailAddress}`;
  const handleClick = () => {
    onClose();
    window.location.href = emailLink;
  };
  return (
    <div className="ActionsTooltipButtonContainer ActionsTooltipEditButtonContainer" onClick={handleClick}>
      {emailContents}
    </div>
  );
};

const PhoneElement = ({ phoneNumber, onClose }) => {
  const phoneLink = `tel:${phoneNumber}`;
  const handleClick = () => {
    onClose();
    window.location.href = phoneLink;
  };
  return (
    <div className="ActionsTooltipButtonContainer ActionsTooltipEditButtonContainer" onClick={handleClick}>
      {phoneContents}
    </div>
  );
};

const LinkElement = ({ toLink, name }) => {
  const isExternalLink = toLink.startsWith('http');
  let linkElement;
  const linkClassNames = 'ActionsTooltipButtonContainer ActionsTooltipLinkButtonContainer';
  const linkContents = getLinkContents(name);

  if (isExternalLink) {
    linkElement = (
      <a className={linkClassNames} href={toLink} target="_blank" rel="'noopener noreferrer">
        {linkContents}
      </a>
    );
  } else {
    linkElement = (
      <Link className={linkClassNames} to={toLink}>
        {linkContents}
      </Link>
    );
  }
  return linkElement;
};

const EditElement = ({ onEdit, onClose, value }) => {
  const isEditLink = typeof onEdit === 'string';
  const isEditAction = typeof onEdit === 'function';
  const isExternalEditLink = onEdit.startsWith('http');

  const handleEditClick = () => {
    isEditAction && onEdit(value);
    onClose();
  };

  let editElement;
  const editClassNames = 'ActionsTooltipButtonContainer ActionsTooltipEditButtonContainer';
  if (isExternalEditLink) {
    editElement = (
      <a className={editClassNames} href={onEdit} target="_blank" rel="'noopener noreferrer">
        {editContents}
      </a>
    );
  } else if (isEditLink) {
    editElement = (
      <Link className={editClassNames} to={onEdit}>
        {editContents}
      </Link>
    );
  } else {
    editElement = (
      <div className={editClassNames} onClick={handleEditClick}>
        {editContents}
      </div>
    );
  }
  return editElement;
};

const SendMagicLinkElement = ({ onClick, state }) => {
  const iconName = {
    idle: 'icon-sparkles',
    loading: 'icon-sparkles',
    error: 'icon-error',
    success: 'icon-check-mdc',
  };
  const iconText = {
    idle: 'Send magic link',
    loading: 'Sending...',
    error: 'Error',
    success: 'Sent',
  };
  return (
    <div
      className="ActionsTooltipButtonContainer ActionsTooltipMagicLinkButtonContainer"
      onClick={() => (state === 'idle' ? onClick() : false)}
    >
      <Icon name={iconName[state]} size="sm" className="ActionTooltipIconColor" />
      <span className="ActionsTooltipButtonText">{iconText[state]}</span>
    </div>
  );
};

const getEnabledAction = (actions, actionType) => actions?.find(action => action.actionType === actionType);

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const ActionsTooltip = ({
  className,
  value,
  actions = [{ actionType: ACTION_TOOL_TIP_OPTIONS.copy }],
  transitionTime = 600,
  children,
}) => {
  const ref = useRef();
  const [copyIcon, setCopyIcon] = useState(COPY_ICON.copy);
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const [magicLinkState, setMagicLinkState] = useState('idle');
  const { user } = useCurrentUser();

  const {
    loading: sendMagicLinkLoading,
    error: sendMagicLinkError,
    execute: executeSendMagicLink,
    results: sendMagicLinkResults,
  } = useAsync(sendMagicLink);

  useEffect(() => {
    const magicLinkStateMap = {
      error: sendMagicLinkError, // check for an error first
      loading: sendMagicLinkLoading, // if there are no errors, show a loading indicator
      success: sendMagicLinkResults, // check for success
    };

    const newMagicLinkState = Object.keys(magicLinkStateMap).find(state => magicLinkStateMap[state]) || 'idle';
    setMagicLinkState(newMagicLinkState);
  }, [sendMagicLinkLoading, sendMagicLinkError, sendMagicLinkResults]);

  const hasActions = !!actions.length;
  const handleClose = () => setShouldShowTooltip(false);
  const showToolTip = () => hasActions && !shouldShowTooltip && setShouldShowTooltip(true);

  useOutsideClick(ref.current, handleClose);

  const email = getEnabledAction(actions, ACTION_TOOL_TIP_OPTIONS.email);
  const phone = getEnabledAction(actions, ACTION_TOOL_TIP_OPTIONS.phone);
  const link = getEnabledAction(actions, ACTION_TOOL_TIP_OPTIONS.link);
  const copy = getEnabledAction(actions, ACTION_TOOL_TIP_OPTIONS.copy);
  const edit = getEnabledAction(actions, ACTION_TOOL_TIP_OPTIONS.edit);
  const magicLink = getEnabledAction(actions, ACTION_TOOL_TIP_OPTIONS.magicLink);

  const isEmailEnabled = !!email;
  const isPhoneEnabled = !!phone;
  const isLinkEnabled = !!link;
  const isCopyEnabled = !!copy;
  const isEditEnabled = !!edit && edit?.onEdit;
  const isMagicLinkEnabled = !!magicLink && canSendMagicLink(user);

  function handleDataCopy() {
    if (copy.value || value) {
      navigator.clipboard.writeText(copy.value || value);
      setCopyIcon(COPY_ICON.check);
      setTimeout(() => {
        handleClose();
      }, transitionTime);
    }
  }

  async function handleSendMagicLink() {
    if (magicLink.value || value) {
      await executeSendMagicLink({
        email: magicLink.value || value,
        trackId: magicLink.trackId,
        isCoapplicant: !!magicLink.isCoapplicant,
      });
      setTimeout(() => {
        handleClose();
      }, transitionTime);
    }
  }

  const defaultStyle = {
    transition: `opacity ${transitionTime}ms ease-out`,
    opacity: 0,
  };

  return (
    <div ref={ref} className={cx('ActionTooltip', className, { hasActions })} onClick={showToolTip}>
      <Transition
        unmountOnExit
        nodeRef={ref}
        in={shouldShowTooltip}
        timeout={transitionTime}
        onExited={() => {
          setCopyIcon(COPY_ICON.copy);
          setMagicLinkState('idle');
        }}
      >
        {state => {
          return (
            <div className="ActionsTooltipContainer" style={{ ...defaultStyle, ...transitionStyles[state] }}>
              <div className="ActionsTooltipButtonsContainer">
                {isEmailEnabled && <EmailElement onClose={handleClose} emailAddress={email.emailAddress} />}
                {isPhoneEnabled && <PhoneElement onClose={handleClose} phoneNumber={phone.phoneNumber} />}
                {isLinkEnabled && <LinkElement onClose={handleClose} toLink={link.toLink} name={link.name} />}
                {isMagicLinkEnabled && <SendMagicLinkElement onClick={handleSendMagicLink} state={magicLinkState} />}
                {isCopyEnabled && (
                  <div
                    className="ActionsTooltipButtonContainer ActionsTooltipCopyButtonContainer"
                    onClick={handleDataCopy}
                  >
                    <Icon name={copyIcon.iconName} size="sm" className="ActionTooltipIconColor" />
                    <span className="ActionsTooltipButtonText">{copyIcon.text}</span>
                  </div>
                )}
                {isEditEnabled && <EditElement onClose={handleClose} onEdit={edit.onEdit} value={value} />}
              </div>
            </div>
          );
        }}
      </Transition>
      <span>{children}</span>
    </div>
  );
};

ActionsTooltip.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      onEdit: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      value: PropTypes.string,
    }),
  ),
  value: PropTypes.node,
};

export default ActionsTooltip;
