import React, { useCallback, useEffect } from 'react';
import SelectionYesNoUnknown from './SelectionYesNoUnknown';
import { getIsNewId } from '../utils/liens';
import { getLienPropertyReportFields } from '../utils/liens';
import { OnPropertyReportFields } from './OnPropertyReportFields';

const LienPropertyReportFields = ({
  formData,
  initialFormData,
  lienPositionError,
  disabled,
  registerField,
  lienReportFieldsTouched,
  setLienReportFieldsTouched,
  label,
  showTriggeredPublishErrors,
  lienKind,
}) => {
  const { onPropertyReport: initialOnPropertyReport } = initialFormData || {};
  const { onPropertyReport, identifier: lienId, newLien } = formData || {};
  const isOnPropertyReport = onPropertyReport === 'true';

  const getRegisterProps = (fieldName, lienId) => {
    const { ref, onBlur: defaultOnBlur, error: defaultError, ...rest } = registerField(fieldName);

    // Enhanced onBlur that includes custom logic plus default behavior
    const onBlur = () => {
      handleTouch(fieldName, lienId);
      defaultOnBlur();
    };
    let error =
      lienReportFieldsTouched[lienId]?.[fieldName] ||
      (!newLien && initialOnPropertyReport === 'true') ||
      showTriggeredPublishErrors
        ? defaultError
        : '';
    if (fieldName === 'position' && lienPositionError[lienId]) {
      error = lienPositionError[lienId];
    }

    // We need to enforce 'required' to ensure that the form cannot be saved without filling out the mandatory fields.
    // 'required' is set to true when 'onPropertyReport' in formData is 'true'.
    return { ref, onBlur, error, required: isOnPropertyReport, ...rest };
  };

  const handleTouch = useCallback(
    (fieldName, lienId) => {
      setLienReportFieldsTouched(prevState => ({
        ...prevState,
        [lienId]: {
          ...prevState[lienId],
          [fieldName]: true,
        },
      }));
    },
    [setLienReportFieldsTouched],
  );

  useEffect(() => {
    if (!getIsNewId(lienId)) {
      getLienPropertyReportFields(lienKind).forEach(field => {
        handleTouch(field, lienId);
      });
    }
  }, [lienId, handleTouch, lienKind]);

  useEffect(() => {
    if (['true', 'false'].includes(onPropertyReport)) {
      setLienReportFieldsTouched(prevState => ({
        ...prevState,
        [lienId]: {
          ...prevState[lienId],
          active: isOnPropertyReport,
        },
      }));
    }
  }, [isOnPropertyReport, onPropertyReport, lienId, setLienReportFieldsTouched]);

  return (
    <>
      <div className="FormRow">
        <div className="FormItem">
          <SelectionYesNoUnknown
            label={label}
            showRequiredAsterisk={false}
            disabled={disabled}
            isWithMarginBottom={false}
            {...registerField('onPropertyReport')}
          />
        </div>
      </div>
      {isOnPropertyReport && (
        <OnPropertyReportFields
          disabled={disabled}
          lienReportFieldsTouched={lienReportFieldsTouched}
          lienKind={lienKind}
          registerField={registerField}
          getRegisterProps={getRegisterProps}
          showTriggeredPublishErrors={showTriggeredPublishErrors}
          formData={formData}
        />
      )}
    </>
  );
};

export default LienPropertyReportFields;
