export const LIEN_TYPE = {
  Mortgage: 'mortgage',
  Heloc: 'heloc',
  HomeEquityInvestment: 'home_equity_investment',
  HomeEquityLoan: 'home_equity_loan',
  PrivateMortgage: 'private_mortgage',
  Judgement: 'judgement',
  Tax: 'tax_lien',
  PartialClaim: 'partial_claim',
  Other: 'other',
  Ucc: 'ucc',
};

export enum PropertyReportFieldType {
  Position = 'position',
  OriginalAmount = 'originalAmount',
  LienDate = 'lienDate',
  EndOfTermDate = 'endOfTermDate',
  Rate = 'rate',
  RateType = 'rateType',
  Holder = 'holder',
  Lender = 'lender',
}

export const LIEN_PROPERTY_REPORT_FIELDS = [
  PropertyReportFieldType.Position,
  PropertyReportFieldType.OriginalAmount,
  PropertyReportFieldType.LienDate,
  PropertyReportFieldType.EndOfTermDate,
  PropertyReportFieldType.Rate,
  PropertyReportFieldType.RateType,
];

const LIEN_PROPERTY_REPORT_RENDER_FIELDS = [
  [PropertyReportFieldType.Position, PropertyReportFieldType.OriginalAmount],
  [PropertyReportFieldType.LienDate, PropertyReportFieldType.EndOfTermDate],
  [PropertyReportFieldType.Rate, PropertyReportFieldType.RateType],
];

export const KIND_LITE_LIEN_PROPERTY_REPORT_FIELDS = [
  {
    kinds: [LIEN_TYPE.Judgement, LIEN_TYPE.Tax],
    fields: [
      PropertyReportFieldType.Position,
      PropertyReportFieldType.OriginalAmount,
      PropertyReportFieldType.LienDate,
      PropertyReportFieldType.Holder,
    ],
    rows: [
      [PropertyReportFieldType.Position, PropertyReportFieldType.OriginalAmount],
      [PropertyReportFieldType.LienDate, PropertyReportFieldType.Holder],
    ],
    settings: {
      [LIEN_TYPE.Judgement]: {
        [PropertyReportFieldType.Holder]: { label: 'Collection agency' },
      },
      [LIEN_TYPE.Tax]: {
        [PropertyReportFieldType.Holder]: { label: 'Taxing authority' },
      },
    },
  },
  {
    kinds: [LIEN_TYPE.HomeEquityInvestment],
    fields: [
      PropertyReportFieldType.Position,
      PropertyReportFieldType.OriginalAmount,
      PropertyReportFieldType.LienDate,
      PropertyReportFieldType.EndOfTermDate,
      PropertyReportFieldType.Holder,
    ],
    rows: [
      [PropertyReportFieldType.Position, PropertyReportFieldType.OriginalAmount],
      [PropertyReportFieldType.LienDate, PropertyReportFieldType.EndOfTermDate],
      [PropertyReportFieldType.Holder],
    ],
    settings: {
      [LIEN_TYPE.HomeEquityInvestment]: {
        [PropertyReportFieldType.Holder]: { label: 'HEI company/servicer' },
      },
    },
  },
  {
    kinds: [LIEN_TYPE.PartialClaim],
    fields: [
      PropertyReportFieldType.Position,
      PropertyReportFieldType.LienDate,
      PropertyReportFieldType.OriginalAmount,
      PropertyReportFieldType.EndOfTermDate,
      PropertyReportFieldType.Lender,
    ],
    rows: [
      [PropertyReportFieldType.Position, PropertyReportFieldType.OriginalAmount],
      [PropertyReportFieldType.LienDate, PropertyReportFieldType.EndOfTermDate],
      [PropertyReportFieldType.Lender],
    ],
  },
  {
    kinds: [LIEN_TYPE.Mortgage, LIEN_TYPE.Heloc, LIEN_TYPE.HomeEquityLoan, LIEN_TYPE.PrivateMortgage, LIEN_TYPE.Other],
    fields: LIEN_PROPERTY_REPORT_FIELDS,
    rows: LIEN_PROPERTY_REPORT_RENDER_FIELDS,
  },
  {
    kinds: [LIEN_TYPE.Ucc],
    fields: [PropertyReportFieldType.Position, PropertyReportFieldType.LienDate],
    rows: [[PropertyReportFieldType.Position, PropertyReportFieldType.LienDate]],
  },
];

export const KIND_LIEN_PROPERTY_REPORT_FIELDS = [
  {
    kinds: [
      LIEN_TYPE.Mortgage,
      LIEN_TYPE.Heloc,
      LIEN_TYPE.HomeEquityLoan,
      LIEN_TYPE.PrivateMortgage,
      LIEN_TYPE.Other,
      LIEN_TYPE.Tax,
      LIEN_TYPE.HomeEquityInvestment,
      LIEN_TYPE.PartialClaim,
      LIEN_TYPE.Judgement,
    ],
    fields: LIEN_PROPERTY_REPORT_FIELDS,
    rows: LIEN_PROPERTY_REPORT_RENDER_FIELDS,
  },
  {
    kinds: [LIEN_TYPE.Ucc],
    fields: [PropertyReportFieldType.Position, PropertyReportFieldType.LienDate],
    rows: [[PropertyReportFieldType.Position, PropertyReportFieldType.LienDate]],
  },
];

export const MAX_LENDER_OR_SERVICER_STRING_LENGTH = 100;
export const MAX_ACCOUNT_NUMBER = 100;
export const MAX_MORTGAGE_ACCOUNT_NUMBER = 100;
export const MAX_PAYDOWN_NAME = 60;

export const PAYOFF_ONLY_LIEN_KINDS = [
  LIEN_TYPE.Ucc,
  LIEN_TYPE.Judgement,
  LIEN_TYPE.HomeEquityInvestment,
  LIEN_TYPE.Tax,
  LIEN_TYPE.PartialClaim,
];
