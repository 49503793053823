import { MuiSelect } from '@hometap/htco-components';
import { getSelectionYesNoInitialValue } from 'apps/track-details/utils/taskForms';
import {
  useSectionFormById,
  useUpdateSectionForm,
} from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import React, { useEffect, useMemo, useState } from 'react';
import { MortgageReviewForm } from './MortgageReviewForm';
import { HelocReviewForm } from './HelocReviewForm';
import { HomeEquityLoanReviewForm } from './HomeEquityLoanReviewForm';
import { HomeEquityInvestmentReviewForm } from './HomeEquityInvestmentReviewForm';
import { PrivateMortgageReviewForm } from './PrivateMortgageReviewForm';
import { OtherLienReviewForm } from './OtherLienReviewForm';
import { TaxLienReviewForm } from './TaxLienReviewForm';
import { UCCReviewForm } from './UCCReviewForm';
import FormWrapperWithDeleteConfirmModal from 'components/FormWrapperWithDeleteConfirmModal/FormWrapperWithDeleteConfirmModal';
import { getSelectionYesNoUnknownInitialValue } from './SelectionYesNoUnknown';
import { JudgementLienReviewForm } from './JudgementLienReviewForm';
import { PartialClaimReviewForm } from './PartialClaimReviewForm';

const initialFormDataFromLienNode = lien => ({
  ...lien,
  kind: lien?.kind?.toLowerCase(),
  mortgageInvestorKind: lien?.mortgageInvestorKind?.toLowerCase(),
  rateType: lien?.rateType?.toLowerCase() ?? undefined,
  onPropertyReport: getSelectionYesNoUnknownInitialValue(lien?.onPropertyReport),
  isForbearance: getSelectionYesNoInitialValue(lien?.isForbearance),
  isMoreThanOneMonthPastDue: getSelectionYesNoInitialValue(lien?.isMoreThanOneMonthPastDue),
});

// Add SPA lien review forms to this dict
const LIEN_FORM_HEADERS = {
  mortgage: MortgageReviewForm,
  heloc: HelocReviewForm,
  home_equity_loan: HomeEquityLoanReviewForm,
  home_equity_investment: HomeEquityInvestmentReviewForm,
  private_mortgage: PrivateMortgageReviewForm,
  judgement: JudgementLienReviewForm,
  tax_lien: TaxLienReviewForm,
  partial_claim: PartialClaimReviewForm,
  other: OtherLienReviewForm,
  ucc: UCCReviewForm,
};

export const LiensAndPaydownsForm = ({
  disabled,
  lien,
  getAvailableDocOptions,
  liensKindOptions,
  liensMortgageInvestorKindOptions,
  liensRateTypeOptions,
  trackId,
  prevSectionId,
  lienPositionError,
  setLienPositionError,
  addNewPaydown,
  deletePaydown,
  setIsLienKindChanged,
  lienReportFieldsTouched,
  setLienReportFieldsTouched,
  deleteLien,
  homeValue,
}) => {
  const { updateNewLienKind, initialKind, showTriggeredTypeError } = useLiensAndPaydownsForm(lien, prevSectionId);
  const [currentLienId, setCurrentLienId] = useState(lien.identifier);
  const [lienKind, setLienKind] = useState('');

  if (currentLienId !== lien.identifier) {
    setCurrentLienId(lien.identifier);
    setLienKind(lien.kind.toLowerCase());
  }

  if (!lienKind && initialKind) {
    setLienKind(initialKind);
  }

  const handleKindChange = lienKindChoice => {
    updateNewLienKind(lienKindChoice);
    setLienKind(lienKindChoice);
    setIsLienKindChanged(true);
  };

  const LienFormHeader = LIEN_FORM_HEADERS[lienKind.toLowerCase()];

  return (
    <FormWrapperWithDeleteConfirmModal
      title="Lien form"
      header="Delete lien?"
      type="Lien"
      isDisabled={disabled}
      onDelete={deleteLien}
    >
      <div className="FormRow">
        <div className="FormItem">
          <MuiSelect
            label="Lien type"
            options={liensKindOptions}
            disabled={disabled || !lien.newLien}
            theme="outlined"
            width="100%"
            value={lienKind}
            onChange={handleKindChange}
            error={!lienKind && showTriggeredTypeError ? 'Select a lien type.' : ''}
          />
        </div>
      </div>
      {LienFormHeader !== undefined && (
        <LienFormHeader
          disabled={disabled}
          lien={lien}
          availableDocOptions={getAvailableDocOptions(lien, lienKind)}
          liensKindOptions={liensKindOptions}
          lienKind={lienKind}
          liensMortgageInvestorKindOptions={liensMortgageInvestorKindOptions}
          liensRateTypeOptions={liensRateTypeOptions}
          trackId={trackId}
          lienPositionError={lienPositionError}
          setLienPositionError={setLienPositionError}
          addNewPaydown={addNewPaydown}
          deletePaydown={deletePaydown}
          setLienReportFieldsTouched={setLienReportFieldsTouched}
          lienReportFieldsTouched={lienReportFieldsTouched}
          homeValue={homeValue}
        />
      )}
    </FormWrapperWithDeleteConfirmModal>
  );
};

function useLiensAndPaydownsForm(lien, prevSectionId) {
  const lienId = lien?.identifier;
  const { initialFormData: initialFormDataFromSectionMap, showTriggeredTypeError } = useSectionFormById(
    lien.identifier,
  );
  const { saveFormOnChangeSection, updateSectionFormById } = useUpdateSectionForm();
  const { formData: prevFormDataToSave } = useSectionFormById(prevSectionId);
  const initialFormData = useMemo(
    () => initialFormDataFromSectionMap ?? initialFormDataFromLienNode(lien),
    [initialFormDataFromSectionMap, lien],
  );
  const { kind: initialKind } = initialFormData;

  const updateNewLienKind = lienKindChoice => {
    if (lien.newLien) {
      updateSectionFormById(lien.identifier, {
        initialFormData: { ...initialFormData, kind: lienKindChoice },
      });
    }
  };

  useEffect(() => {
    if (prevSectionId && prevSectionId !== lienId && prevSectionId === prevFormDataToSave?.identifier) {
      saveFormOnChangeSection(prevSectionId);
    }
  }, [prevSectionId, lienId, prevFormDataToSave?.identifier, saveFormOnChangeSection]);

  return {
    updateNewLienKind,
    initialKind,
    showTriggeredTypeError,
  };
}
