import React from 'react';
import { forwardRef } from 'react';
import cx from 'classnames';
import { SlideSide } from '@hometap/htco-components';

type SliderFormContainerProps = {
  isVisible: boolean;
  className?: string;
  children: React.ReactNode;
};

const SliderFormContainer = forwardRef<HTMLDivElement, SliderFormContainerProps>(
  ({ isVisible, className, children }, ref) => {
    return (
      <SlideSide
        className={cx('flex flex-col flex-nowrap justify-between', className)}
        maxWidth={596}
        visible={isVisible}
        isOverlay
        displayBackdrop
        direction="right"
      >
        <div ref={ref} className="flex h-full flex-col justify-center">
          {children}
        </div>
      </SlideSide>
    );
  },
);

export default SliderFormContainer;
