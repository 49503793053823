import { CurrencyInput, DatePicker, MuiTextarea, MuiTextInput } from '@hometap/htco-components';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import { useUpdateSectionForm } from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import React, { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  getRateTypeInitialValue,
  getSelectionYesNoInitialValue,
  OTHER_LIEN_KINDS,
  RATE_TYPE_UNKNOWN,
} from 'apps/track-details/utils/taskForms';
import { CREATE_OTHER_LIEN, UPDATE_OTHER_LIEN } from 'apps/track-details/tasks/data/mutations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import {
  validateDateNotBeforeDate,
  validateDateNotInTheFuture,
  validateDateNotMoreThanNDaysInPast,
  validateMaxStringLength,
} from 'utils/validators';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import { useLienForm } from '../hooks/useLienForm';
import { useInitialLienForm } from '../hooks/useInitialLienForm';
import { PaydownWrapper } from './PaydownWrapper/PaydownWrapper';
import LienPropertyReportFields from './LienPropertyReportFields';
import { getIsRequiredPayoffMissing } from '../LiensAndPaydownsController';
import { helperText } from 'utils/dates';
import useConfigurations from '../../../../../../hooks/useConfigurations';
import { useValidationLienBalance } from '../hooks/useValidationLienBalance';
import { getSelectionYesNoUnknownInitialValue, getSelectionYesNoUnknownSelectedValue } from './SelectionYesNoUnknown';
import {
  getErrorNotificationDescription,
  getLienPropertyReportFields,
  getSuccessNotificationDescription,
} from '../utils/liens';
import { MAX_LENDER_OR_SERVICER_STRING_LENGTH } from './data/constants';

const initialFormDataFromLienNode = lien => ({
  ...lien,
  kind: lien?.kind?.toLowerCase(),
  mortgageInvestorKind: lien?.mortgageInvestorKind?.toLowerCase(),
  rateType: getRateTypeInitialValue(lien?.rateType, OTHER_LIEN_KINDS),
  onPropertyReport: getSelectionYesNoUnknownInitialValue(lien?.onPropertyReport),
  isForbearance: getSelectionYesNoInitialValue(lien?.isForbearance),
  isMoreThanOneMonthPastDue: getSelectionYesNoInitialValue(lien?.isMoreThanOneMonthPastDue),
  wasProfessionallyServiced: getSelectionYesNoInitialValue(lien?.wasProfessionallyServiced),
});

export const OtherLienReviewForm = ({
  disabled,
  lien,
  liensKindOptions,
  lienKind,
  trackId,
  lienPositionError,
  addNewPaydown,
  deletePaydown,
  setLienReportFieldsTouched,
  lienReportFieldsTouched,
  homeValue,
}) => {
  const lienType = liensKindOptions.find(valLabelPair => valLabelPair.value === lienKind.toLowerCase());
  const { lienKindsRequiringPayoff } = useConfigurations();
  const { updateSectionFormById } = useUpdateSectionForm();
  const {
    mutateOtherLien,
    initialFormData,
    registerField,
    formData = {},
    updateFormData,
    isFormValid,
    paydownId,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  } = useOtherLienForm({ lien, lienType, lienKind, trackId, lienReportFieldsTouched, lienPositionError });

  const { currentBalance, isMoreThanOneMonthPastDue, escrowAmount, interestAmount, principalAmount, onPropertyReport } =
    formData;

  const validationLienBalance = useValidationLienBalance({
    currentBalance,
    escrowAmount,
    interestAmount,
    principalAmount,
    setErrors,
  });

  const lienId = lien?.identifier;

  const isPayoffRequired = getIsRequiredPayoffMissing(
    lienKindsRequiringPayoff,
    { ...lien, formData: { kind: lienType.value } },
    false,
  );

  useEffect(() => {
    updateSectionFormById(lienId, {
      complete: mutateOtherLien,
      initialFormData,
      formData,
      updateFormData,
      isValidForm: isFormValid,
      paydownId,
      showSubmitErrors,
    });
  }, [
    mutateOtherLien,
    updateSectionFormById,
    lienId,
    initialFormData,
    formData,
    updateFormData,
    isFormValid,
    paydownId,
    showSubmitErrors,
  ]);

  return (
    <>
      <LienPropertyReportFields
        label="Is this lien on the property report?"
        initialFormData={initialFormData}
        formData={formData}
        lienPositionError={lienPositionError}
        disabled={disabled}
        setLienReportFieldsTouched={setLienReportFieldsTouched}
        lienReportFieldsTouched={lienReportFieldsTouched}
        registerField={registerField}
        showTriggeredPublishErrors={showTriggeredPublishErrors}
        lienKind={lienKind}
      />
      <div className="FormRow">
        <div className="FormItem">
          <MuiTextInput
            label="Lender (optional)"
            theme="outlined"
            width="100%"
            disabled={disabled}
            showRequiredAsterisk={false}
            {...registerField('lender')}
            validator={value => validateMaxStringLength(value, MAX_LENDER_OR_SERVICER_STRING_LENGTH)}
          />
        </div>
      </div>
      <div className="FormRow">
        <div className="FormItem">
          <MuiTextInput
            label="Servicer (optional)"
            theme="outlined"
            width="100%"
            disabled={disabled}
            showRequiredAsterisk={false}
            {...registerField('holder')}
            validator={value => validateMaxStringLength(value, MAX_LENDER_OR_SERVICER_STRING_LENGTH)}
          />
        </div>
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Current principal balance"
            padFractionalZeros={true}
            disabled={disabled}
            required={true}
            showRequiredAsterisk={false}
            validator={value =>
              validationLienBalance.currentBalance({
                currentBalance: value,
                principalAmount,
                escrowAmount,
                interestAmount,
              })
            }
            {...registerField('currentBalance')}
          />
        </div>
        <div className="FormItem">
          <DatePicker
            label="As of date (optional)"
            theme="outlined"
            disabled={disabled}
            className="FullWidthDatePicker"
            wrapperClassName="FullWidthDatePicker"
            showRequiredAsterisk={false}
            {...registerField('asOfDate')}
            showErrorIcon={true}
            helperText={helperText}
            showWarningIcon={true}
            warning={validateDateNotMoreThanNDaysInPast(formData.asOfDate)}
          />
        </div>
      </div>
      <div className="FormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Monthly principal amt. (optional)"
            padFractionalZeros={true}
            disabled={disabled}
            showRequiredAsterisk={false}
            validator={value => validationLienBalance.principalAmount({ principalAmount: value, currentBalance })}
            {...registerField('principalAmount')}
          />
        </div>
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Monthly interest amt. (optional)"
            padFractionalZeros={true}
            disabled={disabled}
            showRequiredAsterisk={false}
            validator={value => validationLienBalance.interestAmount({ interestAmount: value, currentBalance })}
            {...registerField('interestAmount')}
          />
        </div>
        <div className="FormItem">
          <CurrencyInput
            label="Escrow amt. (optional)"
            disabled={disabled}
            showRequiredAsterisk={false}
            padFractionalZeros={true}
            validator={value => validationLienBalance.escrowAmount({ escrowAmount: value, currentBalance })}
            {...registerField('escrowAmount')}
          />
        </div>
      </div>
      <div className="FormRow">
        <SelectionYesNoSet
          label="Is the account more than one month past due? (optional)"
          isWithMarginBottom={false}
          disabled={disabled}
          {...registerField('isMoreThanOneMonthPastDue')}
        />
      </div>
      <div className={'TwoItemFormRow'}>
        <div className="FormItem">
          <CurrencyInput
            label="Past due amount (optional)"
            disabled={disabled}
            padFractionalZeros={true}
            {...registerField('pastDueAmount')}
          />
        </div>
        <div className="FormItem">
          <CurrencyInput
            label="Past due fees (optional)"
            disabled={disabled}
            padFractionalZeros={true}
            {...registerField('pastDueFees')}
          />
        </div>
      </div>
      {isMoreThanOneMonthPastDue === 'true' && (
        <div className="FormRow">
          <DetailInformationBlock
            type="alert"
            text="Doesn't qualify based on being more than one month past due. Please coordinate with your IM and determine if they've made any payment or are in an active forbearance. "
          />
        </div>
      )}
      <div className="FormRow">
        <MuiTextarea
          label="Notes (optional)"
          theme="outlined"
          width="100%"
          disabled={disabled}
          {...registerField('notes')}
        />
      </div>
      <PaydownWrapper
        paydown={lien?.paydown}
        trackId={trackId}
        disabled={disabled}
        addNewPaydown={addNewPaydown}
        deletePaydown={deletePaydown}
        lienId={lien?.identifier}
        lienKind={lienKind}
        isRequired={isPayoffRequired}
        homeValue={homeValue}
        onPropertyReport={onPropertyReport}
        updateLienFormData={updateFormData}
      />
    </>
  );
};

function useOtherLienForm({ lien, lienType, lienKind, trackId, lienReportFieldsTouched, lienPositionError }) {
  const client = useApolloClient();

  const {
    registerField,
    formData,
    updateFormData,
    isFormValid,
    initialFormData,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  } = useInitialLienForm(lien, initialFormDataFromLienNode);
  useLienForm(lien);

  const validateForm = lienReportFieldsTouched => {
    const { onPropertyReport, lienDate, endOfTermDate } = formData;
    if (onPropertyReport === 'true') {
      const PROPERTY_REPORT_DEPENDENT_FIELDS = getLienPropertyReportFields(lienKind);
      if (
        PROPERTY_REPORT_DEPENDENT_FIELDS.every(
          field =>
            (!formData[field] || (field === 'rateType' && formData[field] === RATE_TYPE_UNKNOWN)) &&
            !showTriggeredPublishErrors &&
            !lienReportFieldsTouched[lien.identifier]?.[field],
        )
      ) {
        return true;
      }
      if (lienPositionError[lien.identifier]) {
        return false;
      }
      if (validateDateNotInTheFuture(lienDate)) {
        return false;
      }
      if (validateDateNotBeforeDate(endOfTermDate, lienDate, 'Term date', 'Date recorded')) {
        return false;
      }
    }

    return isFormValid;
  };

  const mutateOtherLien = useCallback(
    async ({ formData = {} }) => {
      const {
        newLien,
        asOfDate,
        currentBalance,
        endOfTermDate,
        escrowAmount,
        isForbearance,
        holder,
        lender,
        interestAmount,
        isMoreThanOneMonthPastDue,
        wasProfessionallyServiced,
        lienDate,
        notes,
        onPropertyReport,
        originalAmount,
        pastDueAmount,
        pastDueFees,
        position,
        principalAmount,
        rate,
        rateType,
      } = formData;
      const variables = {
        currentBalance: parseFloat(currentBalance).toFixed(2),
        escrowAmount: escrowAmount ? parseFloat(escrowAmount).toFixed(2) : undefined,
        onPropertyReport: getSelectionYesNoUnknownSelectedValue(onPropertyReport),
        hasForbearance: isForbearance === 'true',
        isMoreThanOneMonthPastDue: isMoreThanOneMonthPastDue === 'true',
        wasProfessionallyServiced: wasProfessionallyServiced === 'true',
        holder,
        lender,
        interestAmount: interestAmount ? parseFloat(interestAmount).toFixed(2) : undefined,
        notes,
        pastDueAmount: pastDueAmount ? parseFloat(pastDueAmount).toFixed(2) : undefined,
        pastDueFees: pastDueFees ? parseFloat(pastDueFees).toFixed(2) : undefined,
        principalAmount: principalAmount ? parseFloat(principalAmount).toFixed(2) : undefined,
        trackId,
      };
      variables.lienKind = lienType?.value.toUpperCase();
      variables.lienId = lien.identifier;

      if (asOfDate) {
        variables.asOfDate = asOfDate;
      }
      if (onPropertyReport === 'true') {
        variables.position = position;
        variables.originalAmount = parseFloat(originalAmount).toFixed(2);
        variables.lienDate = lienDate;
        variables.endOfTermDate = endOfTermDate;
        variables.rate = parseFloat(rate).toFixed(3);
        variables.rateType = rateType?.toUpperCase();
      }

      const MUTATION = newLien ? CREATE_OTHER_LIEN : UPDATE_OTHER_LIEN;
      const lienLabel = lienType?.label;
      try {
        const { data } = await client.mutate({
          mutation: MUTATION,
          variables,
        });
        const [mutationData] = Object.values(data);
        const lienIdentifier = Object.values(mutationData)[0]['identifier'];
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Success',
          description: getSuccessNotificationDescription(newLien, lienLabel),
        });
        return { newLienIdentifier: newLien && lienIdentifier ? lienIdentifier : undefined };
      } catch (error) {
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save changes',
          description: error.networkError?.result?.errors[0]?.message
            ? error.networkError.result.errors[0].message
            : getErrorNotificationDescription(newLien, lienLabel),
        });
      }
    },
    [client, lien, lienType, trackId],
  );

  return {
    mutateOtherLien,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid: validateForm(lienReportFieldsTouched),
    paydownId: lien?.paydown?.identifier,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  };
}
