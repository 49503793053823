import { BeginningHomeValuation } from './types/types';

export const getValueOrDefault = (value: any, defaultValue: string = '-', formatter?: (value: any) => string) => {
  if (value != null) {
    return formatter ? formatter(value) : value;
  }
  return defaultValue;
};

export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);

  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(date);
}

export function isExpired(daysUntilExpired: number) {
  if (daysUntilExpired <= 0) {
    return 'Expired';
  }
  return 'Valid';
}

export function formatBeginningHomeValuationLabel(beginningHomeValuation: BeginningHomeValuation) {
  if (!beginningHomeValuation) {
    return '';
  }
  return `(${formatDate(beginningHomeValuation?.expirationDate)} - ${beginningHomeValuation.shortType} - ${isExpired(
    beginningHomeValuation.daysUntilExpires,
  )})`;
}
