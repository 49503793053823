import { SelectionSet, useForm, DetailBox } from '@hometap/htco-components';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { useEffect, useMemo } from 'react';
import { useUpdateSpecificTaskContent } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { useQuery } from '@apollo/client';
import useNewAppraisalOrder from 'apps/track-details/Appraisals/hooks/useNewAppraisalOrder';
import { GET_REGGORA_PRODUCTS } from 'apps/track-details/ApplicationReview/sections/HomeValuationsController/reggoraRequests';
import { reviewAppraisalTaskAction } from './constants';
import type { TaskNode } from '../../types/types';
import AppraisalOrderForm from 'apps/track-details/Appraisals/components/SliderForms/forms/AppraisalOrderForm';
import RevisionRequestForm from 'apps/track-details/Appraisals/components/SliderForms/forms/RevisionRequestForm';
import './ReviewAppraisal.scss';

const MUTABLE_DEFAULT_FORM_VALUES = {
  revisionTitle: '',
  revisionReason: '',
  selectedOption: null,
  rush: false,
  products: null,
};

const FETCHED_DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
};

const DEFAULT_FORM_DATA_VALUES = {
  ...MUTABLE_DEFAULT_FORM_VALUES,
  ...FETCHED_DEFAULT_VALUES,
};

const ReviewAppraisal = ({ task }: { task: TaskNode }) => {
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const isVirtual = task?.taskDefinitionKey?.toLowerCase()?.includes('virtual');

  const { registerField, setErrors, updateFormData, formErrors, formData } = useForm(DEFAULT_FORM_DATA_VALUES);

  const variables = task?.variables;
  const trackId = variables?.find(variable => variable.name === 'track_id')?.value?.replaceAll('"', '') || '';

  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(task.identifier);
  const { prefilledOrderValues, trackDetailsForReggoraOrderDataLoading: formPrefillDetailsLoading } =
    useNewAppraisalOrder(trackId);

  const { data: reggoraProductData, loading: isProductsLoading } = useQuery(GET_REGGORA_PRODUCTS, {
    skip: formData?.selectedOption !== reviewAppraisalTaskAction.FOLLOW_UP,
  });

  const reggoraProductOptions = reggoraProductData?.reggoraProducts?.products || [];

  const loading = Boolean(formPrefillDetailsLoading) || Boolean(isProductsLoading);

  // Needed in order to pre-populate form data correctly
  useEffect(() => {
    updateFormData({
      firstName: prefilledOrderValues?.primaryApplicant?.firstName || '',
      lastName: prefilledOrderValues?.primaryApplicant?.lastName || '',
      emailAddress: prefilledOrderValues?.primaryApplicant?.email || '',
      phoneNumber: prefilledOrderValues?.primaryApplicant?.phoneNumber || '',
    });
  }, [prefilledOrderValues, updateFormData]);

  const isFormValid = useMemo(() => {
    switch (formData?.selectedOption) {
      case reviewAppraisalTaskAction.ACCEPTED:
        return true;
      case reviewAppraisalTaskAction.REVISION:
        return formData?.revisionTitle && formData?.revisionTitle;
      case reviewAppraisalTaskAction.FOLLOW_UP:
        return formData?.products && formData?.products.length > 0;
      case reviewAppraisalTaskAction.DQ:
        return true;
      default:
        return false;
    }
  }, [formData?.products, formData?.revisionTitle, formData?.selectedOption]);

  useEffect(() => {
    updateSpecificTaskById(task.identifier, {
      formData,
      updateFormData,
      isValidForm: isFormValid,
      saving: loading,
    });
  }, [updateSpecificTaskById, formData, isFormValid, task.identifier, updateFormData, loading]);

  const appraisalActionOptions = [
    {
      label: 'Appraisal approved',
      value: reviewAppraisalTaskAction.ACCEPTED,
    },
    {
      label: 'Needs revision',
      value: reviewAppraisalTaskAction.REVISION,
    },
    ...(isVirtual
      ? [
          {
            label: 'Follow-up order',
            value: reviewAppraisalTaskAction.FOLLOW_UP,
          },
        ]
      : []),
    {
      label: 'DQ',
      value: reviewAppraisalTaskAction.DQ,
    },
  ];

  const callOutText = useMemo(() => {
    switch (formData?.selectedOption) {
      case reviewAppraisalTaskAction.ACCEPTED:
        return {
          text: 'The most recent AVM will be marked as the relevant home value when this task is marked as complete.',
        };
      case reviewAppraisalTaskAction.REVISION:
        return { text: 'Revision request will be sent when this task is marked as complete.' };
      case reviewAppraisalTaskAction.FOLLOW_UP:
        return { text: 'Follow-up order will be placed when this task is marked as complete.' };
      case reviewAppraisalTaskAction.DQ:
        return {
          text: 'Please coordinate with your IM. ',
          className: 'ErrorContainer',
          icon: 'alert',
        };
      default:
        return null;
    }
  }, [formData?.selectedOption]);

  const customProductOnBlur = () => {
    if (formErrors?.products) {
      setErrors('products', formErrors?.products?.message, true);
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <SelectionSet
        name="example"
        label={
          <span>
            Review the appraisal report to make sure all the necessary criteria are met.{' '}
            <a href="https://app.getguru.com/card/iExGGMxT/Ordering-Appraisals-and-Reviewing-Appraisal-Status-in-the-Hub-App-Specialist-Procedure">
              Reference this Guru card.
            </a>
          </span>
        }
        labelWidth="100%"
        align="left"
        stacked={false}
        multiple={false}
        disabled={isCompleted}
        options={appraisalActionOptions}
        className="SelectorElement"
        labelGrow={true}
        {...registerField('selectedOption')}
        // every time the selected option changes, reset the form data
        onChange={(selectedOption: string) => {
          updateFormData({
            ...MUTABLE_DEFAULT_FORM_VALUES,
            selectedOption,
          });
        }}
      />

      {callOutText && (
        <DetailBox
          text={callOutText?.text}
          theme={'bright'}
          icon={callOutText?.icon}
          className={`ReviewAppraisalCalloutContainer ${callOutText?.className}`}
        />
      )}

      {formData?.selectedOption === reviewAppraisalTaskAction.REVISION && (
        <>
          <span className="text-lg font-bold">Revision Request</span>
          <RevisionRequestForm
            registerField={registerField}
            theme="outlined"
            revisionNotesName="revisionTitle"
            revisionFieldName="revisionReason"
          />
        </>
      )}

      {formData?.selectedOption === reviewAppraisalTaskAction.FOLLOW_UP &&
        !formPrefillDetailsLoading &&
        prefilledOrderValues && (
          <AppraisalOrderForm
            loading={loading}
            isFollowUp={true}
            prefilledOrderValues={prefilledOrderValues}
            productOptions={reggoraProductOptions}
            formErrors={formErrors}
            registerField={registerField}
            productOnBlur={customProductOnBlur}
            formData={formData}
            updateFormData={updateFormData}
          />
        )}
    </div>
  );
};

export const REVIEW_APPRAISAL_DEMO_DATA = {
  selectedOption: reviewAppraisalTaskAction.ACCEPTED,
};

export default ReviewAppraisal;
