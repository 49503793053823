import { MuiTextarea, MuiTextInput } from '@hometap/htco-components';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import { useUpdateSectionForm } from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import React, { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { getSelectionYesNoInitialValue } from 'apps/track-details/utils/taskForms';
import { CREATE_UCC_LIEN, UPDATE_UCC_LIEN } from 'apps/track-details/tasks/data/mutations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import useConfigurations from 'hooks/useConfigurations';
import { useLienForm } from '../hooks/useLienForm';
import { useInitialLienForm } from '../hooks/useInitialLienForm';
import { PaydownWrapper } from './PaydownWrapper/PaydownWrapper';
import { getIsRequiredPayoffMissing } from '../LiensAndPaydownsController';
import LienPropertyReportFields from './LienPropertyReportFields';
import { getSelectionYesNoUnknownInitialValue, getSelectionYesNoUnknownSelectedValue } from './SelectionYesNoUnknown';
import {
  getErrorNotificationDescription,
  getLienPropertyReportFields,
  getSuccessNotificationDescription,
} from '../utils/liens';
import { LIEN_TYPE } from './data/constants';
import { validateDateNotInTheFuture } from 'utils/validators';

const getTooltipContent = <div>A fixture is something that can be removed from the property.</div>;

const initialFormDataFromLienNode = lien => ({
  ...lien,
  kind: lien?.kind?.toLowerCase(),
  onPropertyReport: getSelectionYesNoUnknownInitialValue(lien?.onPropertyReport),
  isFixture: getSelectionYesNoInitialValue(lien?.isFixture),
});

export const UCCReviewForm = ({
  disabled,
  lien,
  trackId,
  lienPositionError,
  addNewPaydown,
  deletePaydown,
  lienReportFieldsTouched,
  setLienReportFieldsTouched,
  homeValue,
  lienKind,
}) => {
  const { updateSectionFormById } = useUpdateSectionForm();
  const { lienKindsRequiringPayoff } = useConfigurations();

  const {
    mutateUCCLien,
    initialFormData,
    registerField,
    formData = {},
    updateFormData,
    isFormValid,
    paydownId,
    showSubmitErrors,
    showTriggeredPublishErrors,
  } = useUCCForm({
    lien,
    trackId,
    lienReportFieldsTouched,
    lienPositionError,
  });
  const { isFixture, onPropertyReport } = formData;
  const lienId = lien?.identifier;
  const isPayoffRequired = getIsRequiredPayoffMissing(
    lienKindsRequiringPayoff,
    { ...lien, formData: { kind: 'ucc', isFixture: isFixture || null } },
    false,
  );

  useEffect(() => {
    updateSectionFormById(lienId, {
      complete: mutateUCCLien,
      initialFormData,
      formData,
      updateFormData,
      isValidForm: isFormValid,
      paydownId,
      showSubmitErrors,
    });
  }, [
    mutateUCCLien,
    updateSectionFormById,
    lienId,
    initialFormData,
    formData,
    updateFormData,
    isFormValid,
    paydownId,
    showSubmitErrors,
  ]);

  return (
    <>
      <LienPropertyReportFields
        label="Is this UCC on the property report?"
        initialFormData={initialFormData}
        formData={formData}
        lienPositionError={lienPositionError}
        disabled={disabled}
        setLienReportFieldsTouched={setLienReportFieldsTouched}
        lienReportFieldsTouched={lienReportFieldsTouched}
        registerField={registerField}
        showTriggeredPublishErrors={showTriggeredPublishErrors}
        uccOnlyFields={true}
        lienKind={lienKind}
      />
      <div className="FormItem">
        <MuiTextInput
          label="Secured party (optional)"
          theme="outlined"
          width="100%"
          disabled={disabled}
          showRequiredAsterisk={false}
          {...registerField('holder')}
        />
      </div>
      <div className="FormRow">
        <div className="FormItem">
          <SelectionYesNoSet
            label="Is this a fixture? "
            required
            showRequiredAsterisk={false}
            isWithMarginBottom={false}
            disabled={disabled}
            {...registerField('isFixture')}
            tooltipContent={getTooltipContent}
          />
        </div>
      </div>
      <div className="FormRow">
        <MuiTextarea
          label="Notes (optional)"
          theme="outlined"
          width="100%"
          disabled={disabled}
          {...registerField('notes')}
        />
      </div>
      <PaydownWrapper
        paydown={lien?.paydown}
        trackId={trackId}
        disabled={disabled}
        addNewPaydown={addNewPaydown}
        deletePaydown={deletePaydown}
        lienId={lien?.identifier}
        isRequired={isPayoffRequired}
        homeValue={homeValue}
        lienKind={lienKind}
        onPropertyReport={onPropertyReport}
        updateLienFormData={updateFormData}
      />
    </>
  );
};

function useUCCForm({ lien, trackId, lienReportFieldsTouched, lienPositionError }) {
  const client = useApolloClient();

  const {
    registerField,
    formData,
    updateFormData,
    isFormValid,
    initialFormData,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  } = useInitialLienForm(lien, initialFormDataFromLienNode);
  useLienForm(lien);

  const validateForm = lienReportFieldsTouched => {
    const { onPropertyReport, lienDate } = formData;
    if (onPropertyReport === 'true') {
      const PROPERTY_REPORT_DEPENDENT_FIELDS = getLienPropertyReportFields(LIEN_TYPE.Ucc);
      if (
        PROPERTY_REPORT_DEPENDENT_FIELDS.every(
          field =>
            !formData[field] && !showTriggeredPublishErrors && !lienReportFieldsTouched[lien.identifier]?.[field],
        )
      ) {
        return true;
      }
      if (lienPositionError[lien.identifier]) {
        return false;
      }
      if (validateDateNotInTheFuture(lienDate)) {
        return false;
      }
    }
    return isFormValid;
  };

  const mutateUCCLien = useCallback(
    async ({ formData = {} }) => {
      const { newLien, holder, lienDate, isFixture, notes, onPropertyReport, position } = formData;
      const variables = {
        trackId,
        notes,
        holder,
        lienId: lien.identifier,
        onPropertyReport: getSelectionYesNoUnknownSelectedValue(onPropertyReport),
        isFixture: getSelectionYesNoUnknownSelectedValue(isFixture),
      };

      if (onPropertyReport === 'true') {
        variables.position = position;
        variables.lienDate = lienDate;
      }

      const MUTATION = newLien ? CREATE_UCC_LIEN : UPDATE_UCC_LIEN;

      try {
        const { data } = await client.mutate({
          mutation: MUTATION,
          variables,
        });
        const [mutationData] = Object.values(data);
        const lienIdentifier = Object.values(mutationData)[0]['identifier'];
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Success',
          description: getSuccessNotificationDescription(newLien, 'UCC'),
        });
        return { newLienIdentifier: newLien && lienIdentifier ? lienIdentifier : undefined };
      } catch (error) {
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save changes',
          description: error.networkError?.result?.errors[0]?.message
            ? error.networkError.result.errors[0].message
            : getErrorNotificationDescription(newLien, 'UCC'),
        });
      }
    },
    [trackId, lien.identifier, client],
  );

  return {
    mutateUCCLien,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid: validateForm(lienReportFieldsTouched),
    paydownId: lien?.paydown?.identifier,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  };
}
