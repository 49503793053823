import React from 'react';
import cx from 'classnames';

type SliderFormSectionProps = {
  heading?: string;
  className?: string;
  children: React.ReactNode;
};

const SliderFormSection = ({ heading, className, children }: SliderFormSectionProps) => {
  return (
    <div className={cx('flex w-full flex-col gap-[8px]', className)}>
      {heading && <span className="tracking-[1px] text-neutral-dark-50">{heading}</span>}
      {children}
    </div>
  );
};

export default SliderFormSection;
