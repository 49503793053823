import React, { useEffect } from 'react';
import { Button, Icon, Loader, MenuDropdown, MenuDropdownItem, FormError, useAsync } from '@hometap/htco-components';
import cx from 'classnames';
import { getDocumentVersions, makeVersionPrimary } from '../../documentRequests';
import { formatMonthDayYearTime } from 'utils/dates';
import { downloadFile, onClickItemCloseDropdownMenu } from '../../DocumentsUtils';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './PreviewDocumentSidebar.scss';
import useTimeoutError from 'hooks/useTimeoutError';
import AVStatus from '../AVStatus/AVStatus';

/**
 * @typedef PreviewDocumentSidebarParams
 * @type {object}
 * @property {import('../../documentRequests').Document[]} documents The list of documents being viewed.
 * @property {number} viewDocumentIndex The index of the document being viewed
 * @property {import('../../documentRequests').DocumentOrVersion?} viewDocumentOrVersion Current document or version
 * @property {boolean?} documentLoading Is the document(s) being fetched?
 * @property {string?} trackId Track ID, used for navigation to track documents
 * @property {object?} scrollPosition current scroll position in the documents list
 */

/** Document Viewer's right side bar which displays a Download Button, Anti-Virus Warning, & Version History
 * @param {PreviewDocumentSidebarParams} params
 * @returns {JSX.Element}
 *    -   Viewer is Embedded                    ->    Never Called
 *    -   Fetching Document(s) or Version(s)    ->    Loader
 *    -   Version History Fetched               ->    Anti-Virus Status + Download Button + Version History
 *        -   File is Unscanned, Encrypted, or Infected                 ->  Anti-Virus Status appears
 *        -   File is Clean, Anti-Virus Disabled, or File is Encrypted  ->  Download Button appears
 *        -   More than 1 Version in the History                        ->  Version History appears
 *
 */
export const PreviewDocumentSidebar = ({
  documents,
  viewDocumentIndex,
  viewDocumentOrVersion,
  documentLoading,
  trackId,
  scrollPosition,
}) => {
  // Action errors appear at the tops of the view for 3s
  const [timeoutError, setTimeoutError] = useTimeoutError();
  const { id: documentId } = documents[viewDocumentIndex] ?? {};

  const navigate = useNavigate();
  const {
    results: versionHistory = [],
    loading: versionsLoading,
    error: versionsError,
    execute: executeGetDocumentVersions,
  } = useAsync(getDocumentVersions, {
    immediate: false,
    defaultLoading: true,
  });

  useEffect(() => {
    // Whenever a document selection is made, fetch the version history
    if (documentId) {
      executeGetDocumentVersions(documentId);
    }
  }, [documentId, executeGetDocumentVersions]);

  const linkPrefix = trackId ? `/tracks/${trackId}` : '';
  const makeDocumentVersionPrimary = async version => {
    try {
      const newDoc = await makeVersionPrimary(version.id);
      // The documents list must be updated to reflect the change in the document's primary version instead of previous one
      // the order should be the same
      // if we want to remove the { documentVersion, pinned: true } check that
      // the Documents' page will not be reloaded
      documents.map(doc => (doc.id === documentId ? newDoc : doc));
      navigate(`${linkPrefix}/documents/${version.id}`, {
        options: { replace: true },
        state: {
          scrollPosition,
          documents,
          viewDocumentIndex,
        },
      });
    } catch (error) {
      setTimeoutError(error);
    }
  };

  const currentVersion = versionHistory.find(x => x.is_current_version === true);
  const otherVersions = versionHistory.filter(x => x.is_current_version !== true);

  if (versionsLoading || documentLoading || !viewDocumentOrVersion || !documents[viewDocumentIndex]) {
    return (
      <div className="PreviewDocumentSidebar loaderContainer">
        <Loader type="spinner" size="large" className="PreviewDocumentSidebarLoader" />
      </div>
    );
  } else if (versionsError) {
    return (
      <div className="PreviewDocumentSidebar errorContainer">
        <Icon name="icon-error" />
        {versionsError}
      </div>
    );
  }

  const { isDownloadAllowed } = viewDocumentOrVersion;

  // Note: document without a created_by user is a system-generated document and the
  // uploader should be displayed as "Hometap"
  const Version = ({ version }) => {
    return (
      <ul className="PreviewDocumentSidebarCurrentVersionContainerList">
        {version.id === viewDocumentOrVersion.id ? (
          <li>{version.filename}</li>
        ) : (
          <Link
            state={{
              scrollPosition,
              viewDocumentIndex,
              documents,
              viewVersion: version,
            }}
            to={`${linkPrefix}/documents/${documentId}/version/${version.id}`}
            replace={false}
          >
            {version.filename}
          </Link>
        )}
        <li>Uploaded {formatMonthDayYearTime(version?.created_at)}</li>
        <li>Modified {formatMonthDayYearTime(version?.modified_at)}</li>
        <li>{version.created_by?.full_name || 'Hometap'}</li>
      </ul>
    );
  };

  return (
    <div className="PreviewDocumentSidebar">
      <FormError className="PreviewDocumentSidebarError" standalone error={timeoutError} />
      <AVStatus {...{ viewDocumentOrVersion }} />
      <div
        className={cx('PreviewDocumentSidebarDownloadContainer', {
          shouldHide: !isDownloadAllowed,
        })}
      >
        <div className="PreviewDocumentSidebarDownload">
          <Button
            className="PreviewDocumentSidebarDownloadContainerButton"
            onClick={() => downloadFile(viewDocumentOrVersion, setTimeoutError)}
          >
            Download
          </Button>
        </div>
        <hr className="PreviewDocumentSidebarDivider" />
      </div>
      <h3 className="PreviewDocumentSidebarTitle">Version History</h3>
      <h4 className="PreviewDocumentSidebarVersionTitle">Primary Version</h4>
      {currentVersion && (
        <div
          className={cx('PreviewDocumentSidebarCurrentVersionContainer', {
            currentVersion: currentVersion.id === viewDocumentOrVersion.id,
          })}
        >
          <Version version={currentVersion} />
        </div>
      )}
      <hr className="PreviewDocumentSidebarVersionDivider" />
      {versionHistory.length > 1 && (
        <div className="PreviewDocumentSidebarOtherVersionContainer">
          <h4 className="PreviewDocumentSidebarVersionTitle">Other Versions</h4>
          {otherVersions.map((version, index) => {
            return (
              <div
                className={cx('PreviewDocumentSidebarIndividualOtherVersion', {
                  currentVersion: version.id === viewDocumentOrVersion.id,
                })}
                key={index}
              >
                <Version version={version} />
                <MenuDropdown id={version.id} className="PreviewDocumentSidebarIndividualOtherVersionDropDown">
                  <MenuDropdownItem onClick={() => makeDocumentVersionPrimary(version)}>
                    <Icon name="icon-file-primary" />
                    Make primary version
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    onClick={() =>
                      onClickItemCloseDropdownMenu(version.id, () => downloadFile(version, setTimeoutError))
                    }
                  >
                    <Icon name="icon-download" />
                    Download
                  </MenuDropdownItem>
                </MenuDropdown>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
