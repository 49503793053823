import React from 'react';
import ActionsDropdown from 'components/ActionsDropdown/ActionsDropdown';
import { TASK_RESOLUTION_KEYS } from 'data/constants/taskStatuses';
import { COMPLETE_DOC_UPLOAD_TASK } from '../mutations';
import { useMutation } from '@apollo/client';
import { showNotification } from 'utils/toasts';
import { getGraphQLError } from 'utils/errors';
import { Loader } from '@hometap/htco-components';

const TodosActionDropdown = ({ todo, refetchQueries }) => {
  const isMaritalStateTodo = todo?.variables.find(variable => variable.name === 'marital_state_signature_type');

  const [completeDocUploadTask, { loading: isCompletingDocUploadTask }] = useMutation(COMPLETE_DOC_UPLOAD_TASK, {
    refetchQueries,
  });
  const completeDocUploadTaskWithErrorHandling = resolution => {
    completeDocUploadTask({ variables: { taskId: todo.identifier, resolution } }).catch(error => {
      showNotification({
        type: 'error',
        title: getGraphQLError(error),
        description: 'Please try refreshing. If the problem persists please contact engineering.',
      });
    });
  };

  if (isCompletingDocUploadTask) {
    return <Loader type="dot-pulse" size="small" />;
  }

  return (
    <ActionsDropdown
      actions={[
        {
          label: 'Mark as Uploaded',
          actionFunc: () => completeDocUploadTaskWithErrorHandling(TASK_RESOLUTION_KEYS.COMPLETED),
          shouldActionDisable: !todo.isOpen || isMaritalStateTodo,
        },
        {
          label: 'Mark as Not Applicable',
          actionFunc: () => completeDocUploadTaskWithErrorHandling(TASK_RESOLUTION_KEYS.NA),
          shouldActionDisable: !todo.isOpen || isMaritalStateTodo,
        },
      ]}
      disabledTooltipContent="No actions available for this to-do"
    />
  );
};

export default TodosActionDropdown;
