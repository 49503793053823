import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { capitalize, isEmpty } from 'lodash';

import { getGraphQLError } from 'utils/errors';
import { getTrackDetailPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { RESEND_APPLICANT_PASSWORD_INITIALIZATION } from 'apps/track-details/ApplicationReview/data/mutations/resendApplicantPasswordInitialization';
import { RESEND_APPLICANT_VERIFICATION } from 'apps/track-details/ApplicationReview/data/mutations/resendApplicantVerification';
import { RESEND_TRACK_USER_PASSWORD_INITIALIZATION } from 'apps/track-details/ApplicationReview/data/mutations/resendTrackUserPasswordInitialization';
import { RESET_APPLICANT_VERIFICATION_ATTEMPTS } from 'apps/track-details/ApplicationReview/data/mutations/resetApplicantVerificationAttempts';
import { showNotification, TOAST_TYPE } from 'utils/toasts';
import { SidebarDetail } from 'apps/track-details/ApplicationReview/components';
import { Tooltip, Icon, Button, ModalConfirm } from '@hometap/htco-components';
import ACTION_TOOLTIP_OPTIONS from 'data/constants/actionTooltipOptions';
import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import DataListDetail from 'components/DataList/DataListDetail/DataListDetail';
import RiskBandDisplay from 'components/RiskBandDisplay/RiskBandDisplay';
import SSNShowHide from 'components/SSNShowHide/SSNShowHide';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';

import './ApplicantDetailsSidebarController.scss';
import { formatMMDDYYYY } from '../../../../../utils/dates';
import SidebarDetailsDisplayValueOrNone from '../SidebarDetailsDisplayValueOrNone/SidebarDetailsDisplayValueOrNone';
import { SidebarDetailSection } from '../../components/SidebarDetailSection/SidebarDetailSection';
import SidebarDetailsCompositeFico from '../SidebarDetailsCompositeFico/SidebarDetailsCompositeFico';

export const ApplicantDetailsSidebarController = ({ track = {} }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { trackId } = useParams();
  const currentUserPermissions = useCurrentUserPermissions();
  const { editMailingAddressUrl } = getTrackDetailPageUrls(trackId);

  const {
    applicants = [],
    regionDefaults,
    incomeWeightedRiskBand,
    estimatedIncomeWeightedRiskBand,
    application,
    fullNameShort,
    phoneNumber,
    mailingAddress,
    compositeFicoData,
  } = track;

  const primaryApplicant = applicants?.filter(applicant => applicant.isPrimaryApplicant)?.[0] || null;
  const coApplicants = applicants?.filter(applicant => !applicant.isPrimaryApplicant) ?? [];
  const fullMailingAddress = mailingAddress?.fullAddress;
  const userHasOpsPermissions = currentUserPermissions.hasOpsPermissions;

  const [resendTrackUserPasswordInitialization] = useMutation(RESEND_TRACK_USER_PASSWORD_INITIALIZATION);
  const [resendApplicantPasswordInitialization] = useMutation(RESEND_APPLICANT_PASSWORD_INITIALIZATION);
  const [resetApplicantVerificationAttempts] = useMutation(RESET_APPLICANT_VERIFICATION_ATTEMPTS);
  const [resendApplicantVerification] = useMutation(RESEND_APPLICANT_VERIFICATION);

  const primaryApplicantName = primaryApplicant ? primaryApplicant.fullName : fullNameShort;
  const primaryMembership = primaryApplicant?.membership;
  const primaryEmail = primaryApplicant?.person?.email;
  const primaryApplicantBirthDate = primaryApplicant?.birthDate && formatMMDDYYYY(primaryApplicant.birthDate);
  const primaryApplicantIdName = primaryApplicant?.governmentId?.nameAsAppearsOnId
    ? primaryApplicant.governmentId.nameAsAppearsOnId
    : 'None';

  const tooltipContent = (incomeWeightedRiskBand => {
    if (incomeWeightedRiskBand?.label === 'Final IWRB' && !incomeWeightedRiskBand?.value) {
      return 'User may be grandfathered into having no IWRB';
    }
    if (incomeWeightedRiskBand?.label === 'Estimated IWRB' && estimatedIncomeWeightedRiskBand) {
      return `Risk band is ${estimatedIncomeWeightedRiskBand?.band}, risk score is ${estimatedIncomeWeightedRiskBand?.score}`;
    }
    return null;
  })(incomeWeightedRiskBand);

  const isFicoFail = score => score && Number(score) && (Number(score) <= 660 || Number(score) > 850);

  const handleApiCallAndToast = (requestMutation, variables) => {
    requestMutation({
      variables: {
        ...variables,
      },
    })
      .then(response => {
        const description = response.data[Object.keys(response.data)[0]].outcomeMessage;
        showNotification({
          type: TOAST_TYPE.success,
          description: description,
        });
      })
      .catch(error => {
        showNotification({
          type: TOAST_TYPE.warning,
          description: getGraphQLError(error),
        });
      });
  };

  const handleModalConfirm = () => {
    setModalOpen(false);
    handleApiCallAndToast(resetApplicantVerificationAttempts, {
      trackId: trackId,
      membershipId: primaryMembership?.identifier,
    });
  };

  const ResetVerificationButton = () => {
    return (
      <div>
        <Button
          className="ResetApplicantVerificationButton"
          size="small"
          theme="link"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          (Reset)
        </Button>

        {modalOpen && (
          <ModalConfirm
            className="activateOpportunityModal"
            open={modalOpen}
            width={600}
            header="Confirm Reset"
            confirmText="Reset Applicant's Verification Attempts"
            onConfirm={() => handleModalConfirm()}
            onClose={() => setModalOpen(false)}
          >
            <p>
              Are you sure you want to reset this co-applicant's membership access? This will allow them to re-enter
              their SSN for confirmation.
            </p>
          </ModalConfirm>
        )}
      </div>
    );
  };

  return (
    <SidebarDetail sidebarDetailTitle={'Applicant Details'} isWithSections={true}>
      <SidebarDetailSection title="General Information">
        <div>
          <span className="SidebarDetailContentLabel">{incomeWeightedRiskBand?.label}: </span>
          <strong>{incomeWeightedRiskBand?.value}</strong>
          {tooltipContent && (
            <Tooltip content={tooltipContent} action="hover">
              <Icon name="icon-info" size="lg" />
            </Tooltip>
          )}
        </div>
        {application?.submitted && compositeFicoData && (
          <SidebarDetailsCompositeFico compositeFicoData={compositeFicoData} />
        )}
      </SidebarDetailSection>

      <SidebarDetailSection title="Primary Applicant">
        <div>
          <span className="SidebarDetailContentLabel">Name: </span>
          <strong>{primaryApplicantName}</strong>
        </div>
        <div>
          <span className="SidebarDetailContentLabel">Risk Band: </span>
          <strong>
            <RiskBandDisplay
              riskBand={primaryApplicant && primaryApplicant.riskBand}
              trackId={trackId}
              hasOpsPermissions={userHasOpsPermissions}
            />
          </strong>
        </div>
        <div className="InlineLabelValue">
          <span className="SidebarDetailContentLabel">Phone: </span>
          <ActionsTooltip
            actions={[
              { actionType: ACTION_TOOLTIP_OPTIONS.phone, phoneNumber: phoneNumber },
              { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: phoneNumber },
            ]}
          >
            <strong>{phoneNumber}</strong>
          </ActionsTooltip>
        </div>
        <div>
          <span className="SidebarDetailContentLabel">Email: </span>
          <ActionsTooltip
            actions={[
              { actionType: ACTION_TOOLTIP_OPTIONS.email, emailAddress: primaryEmail },
              { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: primaryEmail },
              {
                actionType: ACTION_TOOLTIP_OPTIONS.magicLink,
                value: primaryEmail,
                trackId: track.identifier,
              },
            ]}
          >
            <strong>{primaryEmail}</strong>
          </ActionsTooltip>
          {!primaryMembership?.emailConfirmed && <span className="UnconfirmedEmail">(unconfirmed)</span>}
        </div>
        <div>
          <span className="SidebarDetailContentLabel">Mailing Address: </span>
          {userHasOpsPermissions ? (
            <ActionsTooltip
              value={fullMailingAddress}
              actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.edit, onEdit: editMailingAddressUrl }]}
            >
              <strong>{fullMailingAddress}</strong>
            </ActionsTooltip>
          ) : (
            <strong>{fullMailingAddress}</strong>
          )}
        </div>
        <div>
          <span className="SidebarDetailContentLabel">Name as it Appears on ID: </span>
          <strong>{primaryApplicantIdName}</strong>
        </div>
        <div>
          <span className="SidebarDetailContentLabel">FICO: </span>
          <strong>
            {primaryApplicant?.ficoScore || 'None'}
            {isFicoFail(primaryApplicant?.ficoScore) && ' - Fail'}
          </strong>
        </div>
        {application && primaryApplicant && (
          <div className="InlineLabelValue">
            <span className="SidebarDetailContentLabel">SSN: </span>
            <DataListDetail value={primaryApplicant.ssn} actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.copy }]}>
              <SSNShowHide value={primaryApplicant.ssn} />
            </DataListDetail>
          </div>
        )}
        <SidebarDetailsDisplayValueOrNone
          label="DOB"
          value={primaryApplicantBirthDate}
          actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.copy, value: primaryApplicantBirthDate }]}
        />
        <div>
          <span className="SidebarDetailContentLabel">Marital Status: </span>
          <strong>{primaryApplicant?.maritalStatus ? capitalize(primaryApplicant.maritalStatus) : ''}</strong>
        </div>
        <div>
          <span className="SidebarDetailContentLabel">Spouse Signature Required: </span>
          <strong>
            {regionDefaults?.spouseSignatureRequiredInfo ? regionDefaults.spouseSignatureRequiredInfo : ''}
          </strong>
        </div>
        {primaryEmail && primaryMembership && !primaryMembership.emailConfirmed && (
          <>
            <Button
              size="small"
              theme="secondary"
              className="ApplicantResendButton"
              disabled={primaryMembership.passwordInitializationAttemptsRemaining === 0}
              onClick={() =>
                handleApiCallAndToast(resendTrackUserPasswordInitialization, {
                  trackId: trackId,
                  userEmail: primaryEmail,
                })
              }
            >
              Resend Password Email
            </Button>
            <div>
              {primaryMembership.passwordInitializationAttemptsRemaining
                ? `${primaryMembership.passwordInitializationAttemptsRemaining} attempts left!`
                : 'No attempts left!'}
            </div>
          </>
        )}
      </SidebarDetailSection>

      {application && !isEmpty(coApplicants) && (
        <>
          {!application.submitted && <span>Co-Applicants Application In Progress</span>}
          {coApplicants.map((applicant, i) => {
            const { invitedEmail, membership: applicantMembership } = applicant;
            const currentEmail = applicant?.person?.email;

            const applicantBirthDate = applicant?.birthDate && formatMMDDYYYY(applicant.birthDate);
            const applicantPhone = applicant.phoneNumber;
            // eslint-disable-next-line no-nested-ternary
            const membershipValue = !applicantMembership?.userInitialized
              ? 'User Not Created'
              : !applicantMembership?.emailConfirmed
                ? 'Account Not Initialized'
                : `${applicantMembership?.status}`;
            return (
              <SidebarDetailSection title="Co-Applicant" key={applicant.identifier}>
                <div className="SidebarDetailContent">
                  <div>
                    <span className="SidebarDetailContentLabel">Name: </span>
                    <strong>{applicant.fullName}</strong>
                  </div>
                  <div>
                    <span className="SidebarDetailContentLabel">Risk Band: </span>
                    <RiskBandDisplay
                      riskBand={applicant.riskBand}
                      trackId={trackId}
                      hasOpsPermissions={currentUserPermissions.hasOpsPermissions}
                    />
                  </div>
                  {applicant.phoneNumber && (
                    <div className="InlineLabelValue">
                      <span className="SidebarDetailContentLabel">Phone: </span>
                      <ActionsTooltip
                        actions={[
                          { actionType: ACTION_TOOLTIP_OPTIONS.phone, phoneNumber: applicantPhone },
                          { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: applicantPhone },
                        ]}
                      >
                        <strong>{applicantPhone}</strong>
                      </ActionsTooltip>
                    </div>
                  )}

                  {/*
                    If the email the primary input for the co-applicant (applicant.email) matches
                    the recipients current email (applicant.person.email), just display that email
                    in a single field.
                  */}
                  {applicant.emailMatchesPersonEmail ? (
                    <div className="InlineLabelValue">
                      <span className="SidebarDetailContentLabel">Email: </span>
                      <ActionsTooltip
                        actions={[
                          { actionType: ACTION_TOOLTIP_OPTIONS.email, emailAddress: currentEmail },
                          { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: currentEmail },
                          {
                            actionType: ACTION_TOOLTIP_OPTIONS.magicLink,
                            value: currentEmail,
                            trackId: track.identifier,
                            isCoapplicant: true,
                          },
                        ]}
                      >
                        <strong>{currentEmail}</strong>
                      </ActionsTooltip>
                    </div>
                  ) : (
                    // Otherwise, display both the invited email and the current email in separate
                    // fields.
                    <>
                      <div className="InlineLabelValue">
                        <span className="SidebarDetailContentLabel">Invited Email: </span>
                        <ActionsTooltip
                          actions={[
                            { actionType: ACTION_TOOLTIP_OPTIONS.email, emailAddress: invitedEmail },
                            { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: invitedEmail },
                          ]}
                        >
                          <strong>{invitedEmail}</strong>
                        </ActionsTooltip>
                      </div>
                      <div className="InlineLabelValue">
                        <span className="SidebarDetailContentLabel">Current Email: </span>
                        <ActionsTooltip
                          actions={[
                            { actionType: ACTION_TOOLTIP_OPTIONS.email, emailAddress: currentEmail },
                            { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: currentEmail },
                            {
                              actionType: ACTION_TOOLTIP_OPTIONS.magicLink,
                              value: currentEmail,
                              trackId: track.identifier,
                              isCoapplicant: true,
                            },
                          ]}
                        >
                          <strong>{currentEmail}</strong>
                        </ActionsTooltip>
                      </div>
                    </>
                  )}

                  <div>
                    <span className="SidebarDetailContentLabel">Name as it Appears on ID: </span>
                    <strong>{applicant?.governmentId?.nameAsAppearsOnId || 'None'}</strong>
                  </div>
                  <div>
                    <span className="SidebarDetailContentLabel">FICO: </span>
                    <strong>
                      {applicant?.ficoScore || 'None'}
                      {isFicoFail(applicant?.ficoScore) && ' - Fail'}
                    </strong>
                  </div>
                  <div className="InlineLabelValue">
                    <div className="SidebarDetailContentLabel">SSN: </div>
                    <DataListDetail value={applicant.ssn} actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.copy }]}>
                      <SSNShowHide value={applicant.ssn} />
                    </DataListDetail>
                  </div>
                  <SidebarDetailsDisplayValueOrNone
                    label="DOB"
                    value={applicantBirthDate}
                    actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.copy, value: applicantBirthDate }]}
                  />
                  <div>
                    <span className="SidebarDetailContentLabel">Marital Status: </span>
                    <strong>{applicant?.maritalStatus ? capitalize(applicant.maritalStatus) : ''}</strong>
                  </div>
                  <div>
                    <span className="SidebarDetailContentLabel">Relationship to Primary: </span>
                    <strong>{applicant.relationshipToPrimary}</strong>
                  </div>
                  {primaryMembership && (
                    <>
                      <div className="InlineLabelValue">
                        <span className="SidebarDetailContentLabel">Membership Status: </span>
                        <div>
                          <strong>{membershipValue}</strong>
                          {applicantMembership?.status === 'Pending Verification' && (
                            <div>({applicantMembership?.verificationAttemptsRemaining} attempts left!)</div>
                          )}
                        </div>
                        {applicantMembership?.status === 'Max Attempts Exceeded' && (
                          <span>{ResetVerificationButton()}</span>
                        )}
                      </div>
                    </>
                  )}
                  {applicantMembership && !applicantMembership.emailConfirmed && (
                    <>
                      <Button
                        size="small"
                        theme="secondary"
                        className="ApplicantResendButton"
                        disabled={applicantMembership.passwordInitializationAttemptsRemaining === 0}
                        onClick={() =>
                          handleApiCallAndToast(resendApplicantPasswordInitialization, {
                            trackId: trackId,
                            applicantId: applicant.identifier,
                          })
                        }
                      >
                        Resend Password Email
                      </Button>
                      <div>
                        {applicantMembership.passwordInitializationAttemptsRemaining
                          ? `${applicantMembership.passwordInitializationAttemptsRemaining} attempts left!`
                          : 'No attempts left!'}
                      </div>
                    </>
                  )}
                  {primaryMembership &&
                    applicantMembership?.status === 'Pending Verification' &&
                    applicantMembership?.emailConfirmed && (
                      <Button
                        size="small"
                        theme="secondary"
                        className="ApplicantResendButton"
                        disabled={primaryMembership.verificationAttemptsRemaining === 0}
                        onClick={() =>
                          handleApiCallAndToast(resendApplicantVerification, {
                            trackId: trackId,
                            applicantId: applicant.identifier,
                          })
                        }
                      >
                        Resend Verification Email
                      </Button>
                    )}
                </div>
              </SidebarDetailSection>
            );
          })}
        </>
      )}
    </SidebarDetail>
  );
};

export default ApplicantDetailsSidebarController;
