import { underscoreToCapitalCase } from './utils';

const LienKindDisplayOverrides = {
  home_equity_loan: 'Home Equity Loan',
  heloc: 'HELOC',
  property_tax: 'Property Tax Lien',
  contractor: 'Contractor Lien',
  federal: 'Federal Tax Lien',
  judgement: 'Judgment Lien',
};
const LienTypeKindDisplayOverrides = {
  mortgage: 'Mortgage',
  home_equity_loan: 'Home Equity Loan',
  heloc: 'Home Equity Line of Credit (HELOC)',
  private_mortgage: 'Private Mortgage',
  judgement: 'Judgement',
  tax: 'Tax Lien',
  ucc: 'Universal Commercial Code (UCC)',
  partial_claim: 'Partial Claim',
  hei: 'Home Equity Investment (HEI)',
  other: 'Other',
};

/** @type {import('./processSnapshotEventsChanges').ObservedModel[]} */
const appUnlockObservedModels = [
  {
    name: 'application',
    displayName: 'Application',
    getInstanceName: () => {
      return 'Application';
    },
    hiddenFields: [
      'submitted',
      `submission_notes`,
      'application_consents',
      'applicants',
      'lien_types',
      // EN-863: Deprecated field that may be populated on existing submitted applications
      'application_submission',
    ],
    fieldDisplayNamesOverrides: {
      has_hoa: 'Has HOA',
      hoa_name: 'HOA Name',
      hoa_contact_name: 'HOA Contact Name',
      hoa_contact_phone: 'HOA Contact Phone',
      hoa_street: 'HOA Street',
      hoa_unit: 'HOA Unit',
      hoa_city: 'HOA City',
      hoa_state: 'HOA State',
      hoa_zip_code: 'HOA Zipcode',
    },
  },
  {
    name: 'applicant',
    displayName: 'Applicant',
    getInstanceName: ({ first_name, last_name }) => {
      return `${first_name} ${last_name}`;
    },
    hiddenFields: [
      // Hide FKs
      'person', // person may change if invited_email changes
      'risk_score_requests',
      'applicant_consents',
      'co_applicant_consents',
    ],
    fieldDisplayNamesOverrides: {
      invited_email: 'Email',
      ssn: 'SSN',
      on_title: 'On Property Title',
      declaration_derogatory_item: 'Felony, bankruptcy, foreclosure, or short sale',
      derogatory_item_comment: 'Felony, bankruptcy, foreclosure, or short sale comment',
      primary_applicant: 'Is Primary Applicant',
    },
    secretValueFields: ['ssn'],
    booleanFields: ['primary_applicant', 'job_self_employed'],
    currencyFields: ['total_annual_income'],
    enumFields: [
      'gender',
      'marital_status',
      'relationship_to_primary',
      'on_title',
      'declaration_derogatory_item',
      'employment_status',
    ],
    enumFieldsOverrides: {
      gender: {
        m: 'Male',
        f: 'Female',
        o: 'Other',
      },
    },
  },
  {
    name: 'household',
    displayName: 'Household',
    getInstanceName: () => {
      return 'Household';
    },
    fieldDisplayNamesOverrides: {
      has_mailing_address: 'Has Mailing Address',
      hoa: "Has Homeowners' Association",
      hoa_contact_name: 'HOA Contact Name',
      hoa_phone_number: 'HOA Contact Phone Number',
    },
    booleanFields: ['has_mailing_address', 'hoa'],
  },
  {
    name: 'mailingaddress',
    displayName: 'Mailing Address',
    getInstanceName: ({ street }) => {
      return street;
    },
    hiddenFields: ['business_key'],
  },
  // Liens are used in Application V1
  {
    name: 'lien',
    displayName: 'Lien',
    getInstanceName: ({ kind }) => {
      return LienKindDisplayOverrides[kind] ?? underscoreToCapitalCase(kind);
    },
    currencyFields: ['balance_remaining'],
    enumFields: ['kind'],
    enumFieldsOverrides: {
      kind: LienKindDisplayOverrides,
    },
  },
  // Lien Types are used in Application V2
  {
    name: 'lientype',
    displayName: 'Lien Types',
    getInstanceName: ({ kind, other_description }) => {
      const kindDisplay = LienTypeKindDisplayOverrides[kind] ?? underscoreToCapitalCase(kind);
      return kind === 'other' ? `${kindDisplay} ("${other_description}")` : kindDisplay;
    },
  },
];
export default appUnlockObservedModels;
