import { Button, Icon, Modal } from '@hometap/htco-components';
import './FinalApproveButton.scss';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { APPROVE_TRACK } from '../../../../data/mutations/approveTrack';
import { showNotification, TOAST_TYPE } from 'utils/toasts';
import { useParams } from 'react-router-dom';

type FinalApproveButtonProps = {
  enabled: boolean;
  onSuccess?: () => void;
};

export const FinalApproveButton = ({ enabled, onSuccess }: FinalApproveButtonProps) => {
  const { trackId } = useParams();

  const [errors, setErrors] = useState<null | string[]>(null);

  const [approveTrackMutation, { loading }] = useMutation(APPROVE_TRACK, {
    onCompleted: results => {
      if (results.approveTrack.errors?.length) {
        setErrors(results.approveTrack.errors);
      } else {
        onSuccess?.();
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Application successfully approved',
          description: 'Your application has been approved.',
        });
      }
    },
    onError: () =>
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to approve',
        description: 'Could not approve. Please try again.',
      }),
  });

  const showBlockingErrorsModal = !!errors?.length;

  const onFinalApproveButtonClick = () => approveTrackMutation({ variables: { trackId: trackId } });

  const closeModal = () => setErrors(null);

  if (!enabled || !trackId) {
    return null;
  }

  return (
    <>
      <Button className="FinalApproveButton" size="small" loading={loading} onClick={onFinalApproveButtonClick}>
        Final approve
      </Button>

      {showBlockingErrorsModal && (
        <Modal className="FinalApproveModal" open={showBlockingErrorsModal} onClose={closeModal} width={536}>
          <div className="FinalApproveModalDetails">
            <h1 className="FinalApproveModalTitle">Blocking reasons</h1>

            <div className="FinalApproveModalSubTitle">
              Please correct the following issues in order to Final Approve.{' '}
            </div>

            <div className="FinalApproveModalErrors">
              {errors.map((error, i) => (
                <div key={i} className="FinalApproveModalError">
                  <Icon className="FinalApproveModalErrorIcon" name="icon-error" />
                  {error}
                </div>
              ))}
            </div>

            <Button className="FinalApproveModalButton" size="small" onClick={closeModal}>
              Close
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
