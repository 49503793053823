import { useState } from 'react';
import { Icon, MenuDropdown, MenuDropdownItem } from '@hometap/htco-components';
import { onClickItemCloseDropdownMenu } from '../../DocumentsUtils';
import { PinButton } from '../PinButton/PinButton';

import './DocumentActions.scss';
import useDocumentKinds from '../../hooks/useDocumentKinds';

const DocumentActions = ({
  row,
  onUploadNewVersion,
  onTogglePinDocument,
  onOpenEditDocument,
  onDeleteDocumentModal,
  onDownloadDocument,
}) => {
  const { id } = row;
  const [open, setOpen] = useState(false);
  // disable upload new version for final investment Disclosure (IDD)
  const isIDD = row?.kind_display === 'Investment Disclosure';
  const { canUploadDocument, canEditDocument, canDeleteDocument } = useDocumentKinds();

  return (
    <div className={'DocumentActions'}>
      <PinButton pinned={row.pinned} size="lg" onClick={() => onTogglePinDocument(row)} style={{ fontSize: '24px' }} />
      <MenuDropdown
        isMenuOpen={open}
        onMenuChange={setOpen}
        id={id}
        className="DocumentActionsMenuContainer"
        menuWidth="250px"
        openClassName="DocumentActionsMenuContainerOpen"
      >
        <MenuDropdownItem
          onClick={() => onClickItemCloseDropdownMenu(id, () => onUploadNewVersion(row))}
          isDisabled={!canUploadDocument(row.kind) || isIDD}
        >
          <Icon name="icon-upload" />
          Upload new version
        </MenuDropdownItem>
        <MenuDropdownItem
          onClick={() => onClickItemCloseDropdownMenu(id, () => onOpenEditDocument(row))}
          isDisabled={!canEditDocument(row.kind)}
        >
          <Icon name="icon-edit" size="lg" />
          Edit
        </MenuDropdownItem>
        <MenuDropdownItem
          onClick={() => onClickItemCloseDropdownMenu(id, () => onDeleteDocumentModal(row))}
          isDisabled={!canDeleteDocument(row.kind)}
        >
          <Icon name="icon-delete" size="lg" />
          Delete
        </MenuDropdownItem>
        <MenuDropdownItem
          onClick={() => {
            onClickItemCloseDropdownMenu(id, () => onDownloadDocument(row));
          }}
        >
          <Icon name="icon-download" size="lg" />
          Download
        </MenuDropdownItem>
      </MenuDropdown>
      {/* Add a pseudo backdrop to handle clicks outside the menu */}
      {open && <div onClick={() => setOpen(false)} className="DocumentActionsMenuBackdrop" />}
    </div>
  );
};

export default DocumentActions;
