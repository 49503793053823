// source: portals/portals/apps/core/models.py
const APP_REVIEW_STATUSES = {
  readyForReview: 'ops_ready',
  opsInitial: 'ops_initial',
  dueDiligence: 'due_diligence',
  uwReady: 'uw_ready',
  uwApproved: 'uw_approved',
  uwConditionallyApproved: 'uw_conditionally_approved',
  uwResubmit: 'uw_resubmit',
  moreInfo: 'more_info',
  noPass: 'no_pass',
  finalApproved: 'final_approved',
};

export default APP_REVIEW_STATUSES;
