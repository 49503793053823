import { gql } from '@apollo/client';

export const GET_CONDO_MASTER_POLICY = gql`
  query CondoMasterPolicyReview($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      insurancePolicyReviews {
        identifier
        agent
        agentEmail
        agentPhone
        dwellingAmount
        kind
        policyNumber
        provider
        renewalDate
        replacementCost
        wallsInCoverage
      }
      home {
        identifier
        propertyType
        residenceType
        address {
          identifier
          state
        }
      }
    }
  }
`;
