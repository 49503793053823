import { Button } from '@hometap/htco-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import env from '../../utils/env';
import { useCallback, useEffect } from 'react';
import './DemoButton.scss';

// Reusable demo button component, which takes in some data and a click action

const DemoButton = ({ demoData, onClickAction, disabled = false, className, pageLevel = true, label = 'Demo' }) => {
  const handleOnClick = useCallback(() => {
    const data = typeof demoData === 'function' ? demoData() : demoData;
    onClickAction(data);
  }, [demoData, onClickAction]);

  useEffect(() => {
    // Add Ctrl + Shift + D as the shortcut for the demo button
    if (!env.isProd()) {
      const addKeyboardShortcut = e => {
        if (e.ctrlKey && e.shiftKey && e.key === 'D') {
          handleOnClick();
        }
      };

      window.addEventListener('keydown', addKeyboardShortcut);
      return () => {
        window.removeEventListener('keydown', addKeyboardShortcut);
      };
    }
  }, [handleOnClick]);

  if (env.isProd()) {
    return null;
  }

  const classNames = {
    DemoButtonWrapper: true,
    DemoButtonPageLevel: pageLevel,
    ...className,
  };

  return (
    <div className={cx(classNames)}>
      <Button type="button" onClick={handleOnClick} disabled={disabled} title="Ctrl+Shift+D">
        {label}
      </Button>
    </div>
  );
};

DemoButton.propTypes = {
  demoData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClickAction: PropTypes.func.isRequired,
  className: PropTypes.string,
};
export default DemoButton;
