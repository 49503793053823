import './UnderwritingDetailsController.scss';
import { Paper } from '@hometap/htco-components';
import { MultiPicker } from '../../components';
import { UnderwritingDetailsProps, BulletItem } from './types/types';
import { getValueOrDefault } from './helper';
import UnderwriterBulletList from './components/UnderwriterBulletList';
import { FinalApproveButton } from './components/FinalApproveButton/FinalApproveButton';
import APP_REVIEW_STATUSES from 'data/constants/applicationReviewStatuses';
import useCurrentUser from 'hooks/useCurrentUser';
import { UseCurrentUser } from 'hooks/types/types';

const DEFAULT_STRING = '-';

const UnderwritingDetails = ({
  trackHistoryUrl,
  uwRuleVersion,
  applicationSpecialist,
  ownersEndingEquity,
  totalEndingEquity,
  underwriter,
  htLienPosition,
  principalPaydown,
  totalPaydown,
  maximumInvestmentAmount,
  beginnningHomeValuationLabel,
  beginnningHomeValuation,
  lienBalanceTotal,
  pastDueLienBalanceTotal,
  currentIncomeWeightedRiskBand,
  finanalCompositeFico,
  lossThreshold,
  shareUp,
  shareDown,
  trackReviewStatus,
  refetch,
}: UnderwritingDetailsProps) => {
  const { isInUnderwriterGroup } = useCurrentUser() as UseCurrentUser;

  const enabledApproveButton = trackReviewStatus === APP_REVIEW_STATUSES.uwApproved && isInUnderwriterGroup;

  const overviewItems: BulletItem[] = [
    { label: 'Underwriting rule version', value: uwRuleVersion },
    { label: 'Application specialist', value: getValueOrDefault(applicationSpecialist) },
    { label: 'Underwriter', value: getValueOrDefault(underwriter) },
    { label: 'HT lien position', value: getValueOrDefault(htLienPosition?.toString()) },
    {
      label: 'Principal Paydown',
      value: getValueOrDefault(principalPaydown, DEFAULT_STRING, v => `$${v.toLocaleString()}`),
    },
    { label: 'Total Paydown', value: getValueOrDefault(totalPaydown, DEFAULT_STRING, v => `$${v.toLocaleString()}`) },
  ];

  const dealTermsItems1: BulletItem[] = [
    {
      label: 'Maximum investment amount',
      value: getValueOrDefault(maximumInvestmentAmount, DEFAULT_STRING, v => `$${v.toLocaleString()}`),
    },
    {
      label: (
        <>
          <div>Beginning home valuation</div>
          <div>{beginnningHomeValuationLabel}</div>
        </>
      ),
      value: getValueOrDefault(beginnningHomeValuation, DEFAULT_STRING, v => `$${v.toLocaleString()}`),
    },
    {
      label: 'Lien balance total',
      value: getValueOrDefault(lienBalanceTotal, DEFAULT_STRING, v => `$${v.toLocaleString()}`),
    },
    {
      label: 'Past due lien balance total',
      value: getValueOrDefault(pastDueLienBalanceTotal, DEFAULT_STRING, v => `$${v.toLocaleString()}`),
    },
    {
      label: "Owner's ending equity",
      value: getValueOrDefault(ownersEndingEquity, DEFAULT_STRING, v => `${v.toLocaleString()}%`),
    },
    {
      label: 'Total ending equity',
      value: getValueOrDefault(totalEndingEquity, DEFAULT_STRING, v => `${v.toLocaleString()}%`),
    },
  ];

  const dealTermsItems2: BulletItem[] = [
    { label: 'Loss threshold', value: getValueOrDefault(lossThreshold?.toFixed(2)) },
    { label: 'Current Income Weighted risk band', value: getValueOrDefault(currentIncomeWeightedRiskBand?.toString()) },
    { label: 'Final composite fico', value: getValueOrDefault(finanalCompositeFico?.toString()) },
    { label: 'Share up', value: getValueOrDefault(shareUp, DEFAULT_STRING, v => `${v.toFixed(2)}%`) },
    { label: 'Share down', value: getValueOrDefault(shareDown, DEFAULT_STRING, v => `${v.toFixed(2)}%`) },
  ];

  const onApproveSuccess = () => refetch?.();

  return (
    <div className="ApplicationReviewSection ReadonlyUWWrapper">
      <Paper theme="plain" className="MultiPickerContainer ReadonlyUWMultipicker">
        <MultiPicker
          sectionTitle="Underwriting details"
          historyUrl={trackHistoryUrl}
          visibleSummary={false}
          pickList={[]}
          selectedId={0}
          sectionAlert={{}}
          onEdit={null}
        />
      </Paper>

      <Paper theme="plain" className="FormDetailsContainer UnderwritingDetailsContainer">
        <div className="UnderwritingDetails">
          <div className="Section">
            <h3>OVERVIEW</h3>
            <UnderwriterBulletList items={overviewItems} />
          </div>

          <div className="Section DealTermsSection">
            <h3>DEAL TERMS</h3>
            <div className="DealTermsContainer">
              <UnderwriterBulletList items={dealTermsItems1} />
              <UnderwriterBulletList items={dealTermsItems2} />
            </div>
          </div>

          <FinalApproveButton enabled={enabledApproveButton} onSuccess={onApproveSuccess} />
        </div>
      </Paper>
    </div>
  );
};

export default UnderwritingDetails;
