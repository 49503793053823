import { RegisterField } from 'apps/types/genericTypes';
import SliderFormField from '../common/SliderFormField';
import SliderFormSection from '../common/SliderFormSection';
import { MuiTextInput, MuiTextarea } from '@hometap/htco-components';
import cx from 'classnames';
import './AppraisalsFormsStyle.scss';

type RevisionRequestFormProps = {
  registerField: RegisterField;
  disabled?: boolean;
  theme?: 'label' | 'outlined';

  // specify special field names for the registerField function
  revisionFieldName?: string;
  revisionNotesName?: string;
};

const RevisionRequestForm = ({
  registerField,
  disabled = false,
  theme = 'label',
  revisionFieldName = 'title',
  revisionNotesName = 'revisionReason',
}: RevisionRequestFormProps) => {
  return (
    <SliderFormSection className={cx('RevisionRequestForm', theme === 'label' ? 'RevisionRequestForm-label' : 'gap-4')}>
      {theme === 'label' && (
        <>
          <SliderFormField
            label={'Title'}
            field={
              <MuiTextInput
                required
                theme={'outlined'}
                {...registerField(revisionFieldName)}
                placeholder="Title"
                disabled={disabled}
              />
            }
            className="SliderFormTextInputField"
          />
          <SliderFormField
            label={'Request'}
            field={
              <MuiTextarea
                required
                theme={'outlined'}
                rows={5}
                showRequiredAsterisk={false}
                {...registerField(revisionNotesName)}
                disabled={disabled}
              />
            }
            className="RevisionRequestFormTextAreaField"
          />
        </>
      )}

      {theme === 'outlined' && (
        <>
          <MuiTextInput
            label="Title"
            required
            theme={theme}
            {...registerField(revisionFieldName)}
            disabled={disabled}
          />
          <MuiTextarea
            label="Request Notes"
            required
            theme={theme}
            rows={5}
            {...registerField(revisionNotesName)}
            disabled={disabled}
            className="RevisionRequestFormTextAreaField"
          />
        </>
      )}
    </SliderFormSection>
  );
};

export default RevisionRequestForm;
