import FormWrapperWithDeleteConfirmModal from 'components/FormWrapperWithDeleteConfirmModal/FormWrapperWithDeleteConfirmModal';
import { PaydownForm } from '../PaydownForm';
import { useUpdateSectionForm } from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import { usePaydownForm } from '../../hooks/usePaydownForm';
import { useEffect } from 'react';
import { getIsNewId } from '../../utils/liens';

export const PaydownController = ({
  disabled,
  onDelete,
  paydown,
  trackId,
  lienId,
  homeValue,
  lienKind,
  onPropertyReport,
  updateLienFormData,
}) => {
  const { updateSectionFormById } = useUpdateSectionForm();

  const {
    mutatePaydownForm,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid,
    deletePaydownForm,
    showSubmitErrors,
    handleFieldChange,
  } = usePaydownForm(paydown, trackId, lienId);

  const paydownId = paydown.identifier;
  const isNewPaydown = getIsNewId(paydownId);

  useEffect(() => {
    updateSectionFormById(paydownId, {
      complete: mutatePaydownForm,
      initialFormData,
      formData,
      updateFormData,
      isValidForm: isFormValid,
      lienId,
      deletePaydownForm,
      showSubmitErrors,
    });
  }, [
    mutatePaydownForm,
    updateSectionFormById,
    paydownId,
    initialFormData,
    formData,
    lienId,
    updateFormData,
    isFormValid,
    deletePaydownForm,
    showSubmitErrors,
  ]);

  return (
    <FormWrapperWithDeleteConfirmModal
      title="Paydown form"
      header="Delete paydown"
      type="Paydown"
      isDisabled={disabled}
      onDelete={onDelete}
      canShowModal={!isNewPaydown}
    >
      <PaydownForm
        paydownId={paydownId}
        disabled={disabled}
        registerField={registerField}
        updateFormData={updateFormData}
        updateLienFormData={updateLienFormData}
        lienKind={lienKind}
        onPropertyReport={onPropertyReport}
        homeValue={homeValue}
        formData={formData}
        handleFieldChange={handleFieldChange}
        showSubmitErrors={showSubmitErrors}
      />
    </FormWrapperWithDeleteConfirmModal>
  );
};
