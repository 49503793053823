// Homeowner File Upload Todos have the task definition key of ‘upload-file-task’
export const UPLOAD_FILE_TASK_DEFINITION = 'upload-file-task';

// Review Tasks of the File Upload have the task definition of ‘verify-file-task’
export const VERIFY_FILE_TASK_DEFINITION = 'verify-file-task';

export const SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION = 'senior-lien-entry-task';

export const SENIOR_LIEN_REVIEW_TASK_DEFINITION = 'senior-lien-review-task';

export const SENIOR_LIEN_ESCALATE_TASK_DEFINITION = 'senior-lien-escalate-task';

export const REVIEW_PROPERTY_TAX_TASK_DEFINITION = 'review-property-tax-task';

export const REVIEW_TRADITIONAL_APPRAISAL_TASK_DEFINITION = 'review-traditional-appraisal-task';
export const REVIEW_VIRTUAL_APPRAISAL_TASK_DEFINITION = 'review-virtual-appraisal-task';

export const SENIOR_LIEN_REVIEW_TASK_DEFINITIONS = [
  SENIOR_LIEN_REVIEW_TASK_DEFINITION,
  SENIOR_LIEN_ESCALATE_TASK_DEFINITION,
];

export const SENIOR_LIEN_TASK_DEFINITIONS = [
  SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION,
  SENIOR_LIEN_REVIEW_TASK_DEFINITION,
  SENIOR_LIEN_ESCALATE_TASK_DEFINITION,
];
