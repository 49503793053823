import ACTION_TOOLTIP_OPTIONS from 'data/constants/actionTooltipOptions';
import DataListDetail from 'components/DataList/DataListDetail/DataListDetail';
import { capitalize } from 'lodash';
import SSNShowHide from 'components/SSNShowHide/SSNShowHide';
import MinMaxCurrencyDisplay from 'components/MinMaxCurrencyDisplay/MinMaxCurrencyDisplay';
import RiskBandDisplay from 'components/RiskBandDisplay/RiskBandDisplay';
import { getTrackDetailPageUrls, getApplicationReviewPageUrls } from './trackDetailsLinks';
import { formatMMDDYYYY } from 'utils/dates';

const removeFalseyActions = actions => actions.filter(Boolean);

/*
Each exported TRACK_DATA_** constant configures data to be rendered under a specified `header`. The
configuration for each field will render a `label` and a `value`. Each configuration object
requires an `items` array that at a minimum takes in a:

- queryDataPath: a path to the value from a data object defined by the consumer
- label: the label for the field to render a value. It also can be configured as a callback function
  that is passed contextual data as well.

Each configuration object can also define a:
- display: a function to render a field's value in a custom manner. The consumer will be responsible
  for calling and passing the relevant data needed.
- taskKinds: an array of different task kinds. The purpose of this array is to provide data to the
  consumer to preform filter logic. E.g. If the task kind is included in the array, the field will
  be included in the data to render otherwise it will be filtered out. If there is no `taskKinds`
  field present in the object the field will be rendered for every taskKind.
- actions: an array of actions to be used with `ActionTooltip`. If consumer is not using
  `DataListDetail` to render the field's value, they will need to define and style `ActionTooltip`
  within the component that renders the value of a field.
*/

export const getTrackDataGeneralInformation = taskKinds => {
  return {
    header: 'General Information',
    items: [
      {
        queryDataPath: 'friendlyId',
        label: 'Track ID',
        actions: [{ actionType: ACTION_TOOLTIP_OPTIONS.copy }],
      },
      {
        queryDataPath: 'relevantHomeValuation.value',
        label: 'Home Valuation',
        queryDataPathMessages: 'relevantHomeValuation.messages',
        display: ({ value, messages, track }) => {
          return (
            <DataListDetail
              value={
                value && (
                  <MinMaxCurrencyDisplay
                    amount={value}
                    rangeLower={track.relevantHomeValuation?.valueLower}
                    rangeUpper={track.relevantHomeValuation?.valueUpper}
                  />
                )
              }
              messages={messages}
            />
          );
        },
      },
      {
        queryDataPath: 'mailingAddress.fullAddress',
        label: 'Mailing Address',
        display: ({ value, track, extraContext }) => {
          const { editMailingAddressUrl } = getTrackDetailPageUrls(track.identifier);
          const actions = removeFalseyActions([
            { actionType: ACTION_TOOLTIP_OPTIONS.copy },
            extraContext.userPermissions?.hasOpsPermissions && {
              actionType: ACTION_TOOLTIP_OPTIONS.edit,
              onEdit: editMailingAddressUrl,
            },
          ]);

          return <DataListDetail value={value} actions={actions} />;
        },
        taskKinds: [
          taskKinds.ORDER_AND_REVIEW_CREDIT_REPORT,
          taskKinds.MORTGAGES_AND_LIENS,
          taskKinds.INSURANCE,
          taskKinds.PULL_HARD_CREDIT,
          taskKinds.CREDIT_REPORT,
        ],
      },
      {
        queryDataPath: 'incomeWeightedRiskBand.value',
        taskKinds: [taskKinds.RISK_BAND_CHECK_LEGACY, taskKinds.RISK_BAND_CHECK],
        label: ({ track }) => track.incomeWeightedRiskBand?.label || 'Income Weighted Risk Band',
      },
      {
        queryDataPath: 'compositeFicoData.value',
        label: ({ track }) => track.compositeFicoData.statusDisplay,
        taskKinds: [
          taskKinds.RISK_BAND_CHECK_LEGACY,
          taskKinds.RISK_BAND_CHECK,
          taskKinds.ORDER_AND_REVIEW_CREDIT_REPORT,
          taskKinds.CREDIT_REPORT,
          taskKinds.PROPERTY_REPORT,
          taskKinds.PULL_HARD_CREDIT,
          taskKinds.VALIDITY_CONFIRMATION_LEGACY,
          taskKinds.VALIDITY_CONFIRMATION,
        ],
      },
    ],
  };
};

export const getTrackDataPropertyInformation = taskKinds => {
  return {
    header: 'Property Information',
    items: [
      {
        queryDataPath: 'home.propertyTypeLabel',
        label: 'Property Type',
        taskKinds: [taskKinds.FLOOD_ZONE_CHECK, taskKinds.VALUATION_REVIEW, taskKinds.VALUATION, taskKinds.INSURANCE],
        display: ({ value, track, extraContext }) => {
          const { editHomeUrl } = getTrackDetailPageUrls(track.identifier);
          const actions = removeFalseyActions([
            extraContext.userPermissions?.hasOpsPermissions && {
              actionType: ACTION_TOOLTIP_OPTIONS.edit,
              onEdit: editHomeUrl,
            },
          ]);
          return <DataListDetail value={value} actions={actions} />;
        },
      },
      {
        queryDataPath: 'home.residenceTypeLabel',
        label: 'Occupancy Type',
        taskKinds: [taskKinds.INSURANCE, taskKinds.VALUATION_REVIEW],
        display: ({ track, value, extraContext }) => {
          const { editHomeUrl } = getTrackDetailPageUrls(track.identifier);
          const actions = removeFalseyActions([
            extraContext.userPermissions?.hasOpsPermissions && {
              actionType: ACTION_TOOLTIP_OPTIONS.edit,
              onEdit: editHomeUrl,
            },
          ]);

          return <DataListDetail value={value} actions={actions} />;
        },
      },
      {
        queryDataPath: 'floodZone',
        label: 'Flood Zone Code',
        taskKinds: [
          taskKinds.FLOOD_ZONE_CHECK,
          taskKinds.INSURANCE,
          taskKinds.VALIDITY_CONFIRMATION_LEGACY,
          taskKinds.VALIDITY_CONFIRMATION,
          taskKinds.VALUATION_REVIEW,
        ],
      },
      {
        queryDataPath: 'home.address.fullAddress',
        label: 'Property Address',
        taskKinds: [
          taskKinds.ORDER_AND_REVIEW_CREDIT_REPORT,
          taskKinds.VALUATION_REVIEW,
          taskKinds.VALUATION,
          taskKinds.PROPERTY_REPORT,
          taskKinds.TAX_CERTIFICATE,
          taskKinds.PULL_HARD_CREDIT,
          taskKinds.CREDIT_REPORT,
          taskKinds.INSURANCE,
          taskKinds.CLEAR_BANKRUPTCY,
          taskKinds.TITLE_SEARCH,
          taskKinds.MORTGAGES_AND_LIENS,
        ],
        display: ({ track, value, extraContext }) => {
          const { editPropertyAddressUrl } = getTrackDetailPageUrls(track.identifier);
          const actions = removeFalseyActions([
            { actionType: ACTION_TOOLTIP_OPTIONS.copy },
            extraContext.userPermissions?.hasOpsPermissions && {
              actionType: ACTION_TOOLTIP_OPTIONS.edit,
              onEdit: editPropertyAddressUrl,
            },
          ]);

          return <DataListDetail value={value} actions={actions} />;
        },
      },
      {
        queryDataPath: 'appraisalType.label',
        label: 'Appraisal Type',
        taskKinds: [taskKinds.VALUATION],
      },
      {
        queryDataPath: 'relevantHomeValuation.avmEligibility',
        label: 'AVM Eligibility',
        taskKinds: [
          taskKinds.VALUATION,
          taskKinds.VALIDITY_CONFIRMATION_LEGACY,
          taskKinds.VALIDITY_CONFIRMATION,
          taskKinds.VALUATION_REVIEW,
        ],
      },
    ],
  };
};

export const getTrackDataCoApplicant = taskKinds => {
  return {
    header: 'Co-Applicant',
    items: [
      {
        queryDataPath: 'applicant.fullName',
        label: 'Name',
      },
      {
        queryDataPath: 'applicant.riskBand.value',
        label: 'Risk Band',
        display: ({ track, extraContext }) => {
          const { applicant, userPermissions } = extraContext || {};
          return (
            <DataListDetail
              value={
                <RiskBandDisplay
                  riskBand={applicant.riskBand}
                  trackId={track.identifier}
                  hasOpsPermissions={userPermissions.hasOpsPermissions}
                />
              }
            />
          );
        },
      },
      {
        queryDataPath: 'applicant.phoneNumber',
        label: 'Phone',
        actions: [{ actionType: ACTION_TOOLTIP_OPTIONS.copy }],
      },
      {
        queryDataPath: 'applicant.person.email',
        label: 'Email',
        display: ({
          track,
          value,
          extraContext: {
            applicant: { isPrimaryApplicant },
          },
        }) => {
          const actions = [
            { actionType: ACTION_TOOLTIP_OPTIONS.copy },
            {
              actionType: ACTION_TOOLTIP_OPTIONS.magicLink,
              trackId: track.identifier,
              isCoapplicant: !isPrimaryApplicant,
            },
          ];
          return <DataListDetail value={value} actions={actions} />;
        },
      },
      {
        queryDataPath: 'applicant.governmentId.nameAsAppearsOnId',
        label: 'Name as it appears on ID',
        taskKinds: [
          taskKinds.RISK_BAND_CHECK_LEGACY,
          taskKinds.RISK_BAND_CHECK,
          taskKinds.ORDER_AND_REVIEW_CREDIT_REPORT,
          taskKinds.INSURANCE,
          taskKinds.TAX_CERTIFICATE,
          taskKinds.MORTGAGES_AND_LIENS,
          taskKinds.PULL_HARD_CREDIT,
          taskKinds.VALIDITY_CONFIRMATION_LEGACY,
          taskKinds.VALIDITY_CONFIRMATION,
          taskKinds.CLEAR_BANKRUPTCY,
          taskKinds.CREDIT_REPORT,
        ],
        display: ({ value, track, extraContext }) => {
          const { editGovernmentIdsUrl } = getApplicationReviewPageUrls(track.identifier, track?.rolloutFlags);
          const actions = removeFalseyActions([
            { actionType: ACTION_TOOLTIP_OPTIONS.copy },
            extraContext.userPermissions?.hasOpsPermissions && {
              actionType: ACTION_TOOLTIP_OPTIONS.edit,
              onEdit: editGovernmentIdsUrl,
            },
          ]);

          return <DataListDetail value={value} actions={actions} />;
        },
      },
      {
        queryDataPath: 'applicant.ficoScore',
        label: 'FICO',
        taskKinds: [
          taskKinds.RISK_BAND_CHECK_LEGACY,
          taskKinds.RISK_BAND_CHECK,
          taskKinds.ORDER_AND_REVIEW_CREDIT_REPORT,
          taskKinds.PROPERTY_REPORT,
          taskKinds.PULL_HARD_CREDIT,
          taskKinds.VALIDITY_CONFIRMATION_LEGACY,
          taskKinds.VALIDITY_CONFIRMATION,
          taskKinds.CREDIT_REPORT,
        ],
      },
      {
        queryDataPath: 'applicant.ssn',
        label: 'SSN',
        taskKinds: [
          taskKinds.CREDIT_REPORT,
          taskKinds.CLEAR_BANKRUPTCY,
          taskKinds.CHECK_PACER,
          taskKinds.ORDER_AND_REVIEW_CREDIT_REPORT,
          taskKinds.BANKRUPTCY,
          taskKinds.BANKRUPTCY_SEARCH_PACER,
          taskKinds.BANKRUPTCY_UPLOAD_PACER,
        ],
        display: ({ value }) => {
          return (
            <DataListDetail value={value} actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.copy }]}>
              <SSNShowHide value={value} />
            </DataListDetail>
          );
        },
      },
      {
        queryDataPath: 'applicant.birthDate',
        label: 'DOB',
        taskKinds: [taskKinds.ORDER_AND_REVIEW_CREDIT_REPORT, taskKinds.PULL_HARD_CREDIT, taskKinds.CREDIT_REPORT],
        display: ({ value }) => (
          <DataListDetail
            value={value && formatMMDDYYYY(value)}
            actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.copy }]}
          />
        ),
      },
      {
        queryDataPath: 'applicant.maritalStatus',
        label: 'Marital Status',
        taskKinds: [taskKinds.PROPERTY_REPORT],
        display: ({ value }) => {
          return <DataListDetail value={capitalize(value)} />;
        },
      },
    ],
  };
};

export const getTrackDataPrimaryApplicant = taskKinds => {
  return {
    header: 'Primary Applicant',
    items: [
      ...getTrackDataCoApplicant(taskKinds).items,
      {
        queryDataPath: 'applicant.regionDefaults.spouseSignatureRequiredInfo',
        label: 'Spouse Signature Required',
        taskKinds: [taskKinds.PROPERTY_REPORT],
        display: ({ track }) => {
          return <DataListDetail value={track?.regionDefaults?.spouseSignatureRequiredInfo} />;
        },
      },
    ],
  };
};
