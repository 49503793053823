import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  CHANGE_REGGORA_ORDER_APPROVAL,
  GET_REGGORA_ORDERS,
  REFRESH_REGGORA_ORDERS,
  CHANGE_REGGORA_ORDER_PROPERTY_CONDITION,
  ADD_REGGORA_ORDER_NOTES,
  CANCEL_REGGORA_ORDER,
  CANCEL_APPRAISAL_REVIEW_PROCESS,
  SET_REGGORA_ORDER_AS_APPRAISAL_OF_RECORD,
} from '../../ApplicationReview/sections/HomeValuationsController/reggoraRequests';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import useCurrentUser from 'hooks/useCurrentUser';

/**
 * Returns all information about the Reggora orders
 * @param {String} trackId id of the track we need the appraisals for
 * @returns an array of all of the orders that match the track's id
 */
const useAppraisalsData = trackId => {
  const { user } = useCurrentUser();
  // formatted version of the Reggora orders
  const [formattedAppraisalData, setFormattedAppraisalData] = useState();
  // list of the ids of the Reggora orders
  const [orderIds, setOrderIds] = useState([]);
  // used to get the reggora data from the models
  const {
    data: reggoraOrderData,
    refetch: getReggoraOrderData,
    loading: isDataTableLoading,
    error,
  } = useQuery(GET_REGGORA_ORDERS, {
    // ensure that all data is fresh
    fetchPolicy: 'no-cache',
    variables: { trackId: trackId },
  });
  /**
   * Mutation used to refresh the orders with Reggora.
   */
  const [refreshOrdersMutation, { loading: refreshedReggoraDataLoading, error: refreshError }] = useMutation(
    REFRESH_REGGORA_ORDERS,
    {
      onCompleted: result => updateOrders(result.reggoraRefreshOrders),
      onError: () => propagateError(refreshError),
    },
  );

  /**
   * @description Mutation to set an order as the appraisal of record for a track. all errors and success logic is handled in the @setAppraisalOfRecord function
   */
  const [setAppraisalOfRecordMutation, { loading: setAppraisalOfRecordLoading, error: setAppraisalOfRecordError }] =
    useMutation(SET_REGGORA_ORDER_AS_APPRAISAL_OF_RECORD);

  const cancelMutation = user?.rolloutFlags?.includes('use_appraisal_review_task')
    ? CANCEL_APPRAISAL_REVIEW_PROCESS
    : CANCEL_REGGORA_ORDER;

  /**
   * @description Mutation to cancel an order with Reggora. all errors and success logic is handled in the @cancelOrder function
   */
  const [cancelOrderMutation, { loading: cancelOrderLoading, error: cancelOrderError }] = useMutation(cancelMutation);

  /**
   * Mutation used to refresh the orders with Reggora.
   */
  const [
    changeOrderApprovalStatusMutation,
    { loading: changedApprovalStatusReggoraDataLoading, error: changedApprovalStatusError },
  ] = useMutation(CHANGE_REGGORA_ORDER_APPROVAL, {
    onCompleted: result => updateOrderStatusChange(result.reggoraOrderApprovalChange),
    onError: () => propagateError(changedApprovalStatusError),
  });

  /**
   * Mutation used to add notes to the orders with Reggora.
   */
  const [
    changePropertyConditionMutation,
    { loading: changePropertyConditionLoading, error: changePropertyConditionError },
  ] = useMutation(CHANGE_REGGORA_ORDER_PROPERTY_CONDITION, {
    onCompleted: result => {
      updateOrderStatusChange(result.reggoraPropertyConditionChange);
    },
    onError: () => propagateError(changePropertyConditionError),
  });

  /**
   * Mutation used to add notes to the orders with Reggora.
   */
  const [addOrderNotesMutation, { loading: addNotesReggoraDataLoading, error: addNotesError }] = useMutation(
    ADD_REGGORA_ORDER_NOTES,
    {
      onCompleted: result => updateOrderStatusChange(result.reggoraOrderNotes),
      onError: () => propagateError(addNotesError),
    },
  );

  // Makes documentBatchRequests mutable for sorting purposes
  useEffect(() => {
    if (error) {
      // Inform the user if an error occurs while loading the data.
      propagateError(error);
    } else if (reggoraOrderData && !error) {
      // Set the orders in the table after loading them from the models.
      setFormattedAppraisalData(reggoraOrderData?.orders);
      setOrderIds(reggoraOrderData?.orders?.map(order => order.orderId));
    }
  }, [reggoraOrderData, isDataTableLoading, error]);

  /**
   * Set the table with the new orders that have been refreshed with Reggora
   * @param {Object} reggoraRefreshOrders  the orders that have been refreshed with Reggora
   */
  const updateOrders = reggoraRefreshOrders => {
    // Once we get the orders that have been refreshed with Reggora, set the new data with this information.
    setFormattedAppraisalData(reggoraRefreshOrders?.orders);
    // Notify the user about the new information.
    showNotification({
      type: TOAST_TYPE.success,
      title: 'Successfully refreshed appraisals',
      description: 'Successfully loaded appraisals from Reggora.',
    });
  };

  /**
   * Set the table with the new order info that have been refreshed with the approval status change
   * @param {Object} reggoraRefreshOrders  the orders that have been refreshed with Reggora
   */
  const updateOrderStatusChange = reggoraOrderApprovalChange => {
    const updatedAppraisalData = (formattedAppraisalData || []).map(order => {
      if (order.orderId === reggoraOrderApprovalChange?.order?.orderId) {
        return reggoraOrderApprovalChange?.order;
      }
      return order;
    });
    // Once we get the orders that have been refreshed with Reggora, set the new data with this information.
    setFormattedAppraisalData(updatedAppraisalData);
  };

  /**
   * Used to display the error when orders are not loaded
   * @param {Object} resultMessage
   */
  const propagateError = (resultMessage = undefined, errorTitle = 'Error loading appraisals') => {
    showNotification({
      type: TOAST_TYPE.error,
      title: errorTitle,
      description: resultMessage?.message ?? 'There was an error loading the appraisals. Please try again later.',
    });
  };

  /**
   * Used to manually refresh the appraisals in the table with the Reggora lending website.
   */
  const manuallyRefreshAppraisals = () => {
    // refresh the appraisals
    refreshOrdersMutation({
      variables: {
        orderIds: orderIds,
        trackId: trackId,
      },
    });
  };

  /**
   * Used to change the appraisal's underwriter_approved status
   */
  const changeOrderApprovalStatus = (orderId, underwriterApproved) => {
    // refresh the appraisal that underwriter_approved status was changed
    changeOrderApprovalStatusMutation({
      variables: {
        orderId: orderId,
        trackId: trackId,
        underwriterApproved: underwriterApproved,
      },
    });
  };

  /**
   * Used to update the property condition for the appraisal
   */
  const changePropertyCondition = (orderId, propertyCondition) => {
    changePropertyConditionMutation({
      variables: {
        orderId: orderId,
        trackId: trackId,
        propertyCondition: propertyCondition,
      },
    });
  };

  /**
   * Used to add notes for the appraisal
   */
  const addOrderNotes = (orderId, notes) => {
    // refresh the appraisal that underwriter_approved status was changed
    addOrderNotesMutation({
      variables: {
        orderId: orderId,
        trackId: trackId,
        notes: notes,
      },
    });
  };

  /**
   * @description Set an order as the appraisal of record for a track and throw an error after displaying a toast notification if it fails to set the order as the appraisal of record.
   * @async
   * @param {String} orderId: the order id that will be set as the appraisal of record
   * @param {String} trackId: the track id that the order will be set as the appraisal of record for
   */
  const setAppraisalOfRecord = async (orderId, trackId) => {
    try {
      await setAppraisalOfRecordMutation({
        variables: {
          orderId,
          trackId,
        },
      });
    } catch (e) {
      const errorMessage = e?.message ?? 'An unknown error.';
      const completedError = (
        <div>
          <strong>Appraisal of record</strong> could not be set. {errorMessage}
        </div>
      );
      propagateError({ message: completedError }, 'Unable to set appraisal of record');
      // throw an error for error handling in the component that uses this function
      throw e;
    }
  };

  /**
   * @description Cancels an order and throws an error after displaying a toast notification if it fails to cancel the order.
   * @async
   * @param {String} orderId: the order id that will be cancelled
   * @param {String} orderProduct: the name of the order product that will be displayed in the toast notification upon failure or success
   */
  const cancelOrder = async (orderId, orderProduct) => {
    try {
      await cancelOrderMutation({
        variables: {
          orderId,
          trackId,
        },
      });

      showNotification({
        type: TOAST_TYPE.success,
        title: 'Appraisal order cancelled',
        description: (
          <div>
            <strong>{orderProduct}</strong> has been successfully cancelled.
          </div>
        ),
      });
    } catch (e) {
      const errorMessage = e?.message ?? 'An unknown error.';
      const completedError = (
        <div>
          <strong>{orderProduct}</strong> could not be cancelled. {errorMessage}
        </div>
      );
      propagateError({ message: completedError }, 'Unable to cancel appraisal order');
      // throw an error for error handling in the component that uses this function
      throw e;
    }
  };

  return {
    formattedAppraisalData,
    getReggoraOrderData,
    manuallyRefreshAppraisals,
    isDataTableLoading,
    refreshedReggoraDataLoading,
    changeOrderApprovalStatus,
    changePropertyCondition,
    changePropertyConditionLoading,
    changePropertyConditionError,
    addOrderNotes,
    changedApprovalStatusReggoraDataLoading,
    addNotesReggoraDataLoading,
    cancelOrder,
    cancelOrderLoading,
    cancelOrderError,
    setAppraisalOfRecord,
    setAppraisalOfRecordLoading,
    setAppraisalOfRecordError,
  };
};
export default useAppraisalsData;
