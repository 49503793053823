import { gql } from '@apollo/client';

export const GET_CALCULATED_TRACK_DATA = gql`
  query Track($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      identifier
      currentLienBalanceTotal
      currentPastDueLienBalanceTotal
      htLienPosition
      totalPaydown
      principalPaydown
    }
  }
`;
