interface UserAuthProps {
  roles?: string[];
}

export const REVIEWER_ROLES = {
  APPLICATION_SPECIALIST: 'Application Specialist',
  APPLICATION_SPECIALIST_SUPERVISOR: 'Application Specialist Supervisor',
  UNDERWRITER: 'Underwriter',
  SERVICER: 'Servicer',
  SUPER_ADMIN: 'Super Admin',
  INVESTMENT_MANAGER: 'Investment Manager',
  SALES_MANAGER: 'Sales Manager',
  EXTERNAL_DATA_ENTRY_GROUP: 'External Data Entry Group',
  EXTERNAL_CONTRACTOR: 'External Contractor',
};

// user roles can contain roles used only for feature flags, which might need to be filtered out
const filterUserRoleGroups = (user: UserAuthProps) => {
  const reviewerRolesSet = new Set(Object.values(REVIEWER_ROLES));
  const currentUserReviewerRolesOnly = new Set(user?.roles?.filter(role => reviewerRolesSet.has(role)));
  return [...currentUserReviewerRolesOnly];
};

const isInRoleGroup = (group: string) => {
  return (user: UserAuthProps) => user?.roles?.some(role => group === role);
};

const isOnlyInRoleGroup = (group: string) => {
  return (user: UserAuthProps) => {
    const roles = filterUserRoleGroups(user);
    return roles.includes(group) && roles.length === 1;
  };
};

export const isInUnderwriterGroup = isInRoleGroup(REVIEWER_ROLES.UNDERWRITER);

export const isInServicerGroup = isInRoleGroup(REVIEWER_ROLES.SERVICER);

export const isInInvestmentManagerGroup = (user: UserAuthProps) =>
  isInRoleGroup(REVIEWER_ROLES.INVESTMENT_MANAGER)(user) || isInRoleGroup(REVIEWER_ROLES.SALES_MANAGER)(user);

export const isInApplicationSpecialistGroup = (user: UserAuthProps) =>
  isInRoleGroup(REVIEWER_ROLES.APPLICATION_SPECIALIST)(user) ||
  isInRoleGroup(REVIEWER_ROLES.APPLICATION_SPECIALIST_SUPERVISOR)(user);

export const isOnlyApplicationSpecialist = isOnlyInRoleGroup(REVIEWER_ROLES.APPLICATION_SPECIALIST);

export const isOnlyInvestmentManager = isOnlyInRoleGroup(REVIEWER_ROLES.INVESTMENT_MANAGER);

export const hasBatchDocumentSetsPrivileges = (user: UserAuthProps) =>
  isInApplicationSpecialistGroup(user) || (isInUnderwriterGroup(user) && isInServicerGroup(user));

export const isInExternalContractorGroup = isInRoleGroup(REVIEWER_ROLES.EXTERNAL_CONTRACTOR);

export const canReadWriteDataEntryTasks = isInRoleGroup(REVIEWER_ROLES.EXTERNAL_DATA_ENTRY_GROUP);

export const canSendMagicLink = (user: UserAuthProps) => isInInvestmentManagerGroup(user) || isInServicerGroup(user);
