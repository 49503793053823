import axios from 'axios';
import { getPortalsUrl } from 'utils/links';
import apiAuthAuth from 'utils/api';

// # --------------------------------------------------------------------------------------
// # Creates a new Homeowner User, Track, assigns Track to the standard IM user, and copies
// # homeowner autologin link to clipboard
// # Warning: This assumes the server is running on localhost:8000 and won't work otherwise
// # --------------------------------------------------------------------------------------

const QA_CREATE_TRACK = `${getPortalsUrl()}api/qa/stages/actions/create-client-in-stage/`;
const QA_CREATE_LIEN = trackId => `${getPortalsUrl()}api/qa/tracks/${trackId}/actions/create-lien/`;
const QA_CREATE_ADVERSE_EVENT = trackId => `${getPortalsUrl()}api/qa/tracks/${trackId}/actions/create-adverse-event/`;

export const createTrack = formData => axios.post(`${QA_CREATE_TRACK}`, formData);
export const createLien = async (trackId, formData) => {
  return await apiAuthAuth.qa.post(`${QA_CREATE_LIEN(trackId)}`, formData);
};
export const createAdverseEvent = async (trackId, formData) => {
  return await apiAuthAuth.qa.post(`${QA_CREATE_ADVERSE_EVENT(trackId)}`, formData);
};
