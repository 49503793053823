import React, { useEffect, useState } from 'react';
import {
  CurrencyInput,
  DatePicker,
  Icon,
  ModalConfirm,
  MuiSelect,
  MuiTextInput,
  Tooltip,
} from '@hometap/htco-components';

import useConfigurations from 'hooks/useConfigurations';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import {
  validateDateNotInThePast,
  validateMinStringLength,
  validateMaxStringLength,
  validateUsd,
  validateNonEmptyString,
} from 'utils/validators';
import {
  MIN_ACCOUNT_NUMBER_LENGTH,
  MIN_MAX_ROUTING_NUMBER_LENGTH,
  PAYMENT_METHOD_TYPE,
} from './PaydownController/constants';
import { getLabel } from '../utils/liens';
import FlexibleTextarea from 'apps/track-details/ApplicationReview/components/FlexibleTextarea/FlexibleTextarea';
import { helperText } from 'utils/dates';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import { MAX_PAYDOWN_NAME, PAYOFF_ONLY_LIEN_KINDS } from './data/constants';

const PAYDOWN_NAME_TOOLTIP_CONTENT = (
  <p className="PaydownNameTooltip">
    This will display to the Homeowner as the name of the Payoff or Paydown when listed on the investment contract.
    Ideal formatting is:
    <br />
    <span className="PaydownNameTooltipDescription">
      [Institution], [Lien type], [last four digits of account to be paid down]
    </span>
  </p>
);

export const PaydownForm = ({
  paydownId,
  disabled,
  registerField,
  updateFormData,
  updateLienFormData,
  lienKind,
  homeValue,
  onPropertyReport,
  formData,
  handleFieldChange,
  showSubmitErrors,
}) => {
  const { lienPaymentMethods, lienKindsPrincipalAmountBalance } = useConfigurations();
  const cachedData = JSON.parse(sessionStorage.getItem(paydownId));

  const [wasOnPropertyReport, setWasOnPropertyReport] = useState(onPropertyReport === 'true');
  const [modalOpen, setModalOpen] = useState(false);

  const {
    paymentMethod,
    goodThroughDate,
    accountNumber,
    routingNumber,
    isPayoff,
    principalPaydownAmount,
    feePaydownAmount,
  } = formData;

  const isMailCheckMethod = paymentMethod === PAYMENT_METHOD_TYPE.mailCheck;
  const isWireMethod = paymentMethod === PAYMENT_METHOD_TYPE.wire;
  const isOnPropertyReport = onPropertyReport === 'true';
  const isLienPrincipalAmountBalance = lienKindsPrincipalAmountBalance.includes(lienKind);
  const isPayoffOnlyLienKind = PAYOFF_ONLY_LIEN_KINDS.includes(lienKind);
  const hasCachedData = cachedData && Object.keys(cachedData).length && cachedData.paydownId === paydownId;
  const canShowWirePaymentErrors = paymentMethod && showSubmitErrors;

  useEffect(() => {
    // Trigger a confirmation popup if the lien form's value for
    // onPropertyReport changes from true to false or undefined
    if (wasOnPropertyReport && !isOnPropertyReport) {
      setWasOnPropertyReport(false);
      if (principalPaydownAmount) {
        setModalOpen(true);
      }
    } else if (isOnPropertyReport) {
      setWasOnPropertyReport(true);
    }
    // Prevent loop over setting 'wasOnPropertyReport'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnPropertyReport]);

  // The function to fill in wire payment fields if user switched away from - and
  // then back to - Wire after providing that value
  // save routingNumber, accountNumber and paydownId in the Session Storage
  const onPaymentMethodChange = value => {
    if (value === PAYMENT_METHOD_TYPE.wire) {
      // if the cached data exists we update the routingNumber and accountNumber
      // if there is no cached data we clear the fields and remove the item from the session storage
      // update updateFormData to refresh the fields values
      const dataToUpdate = {};
      if (hasCachedData) {
        dataToUpdate.routingNumber = cachedData?.routingNumber;
        dataToUpdate.accountNumber = cachedData?.accountNumber;
      } else {
        dataToUpdate.routingNumber = '';
        dataToUpdate.accountNumber = '';
      }

      updateFormData(dataToUpdate);
      sessionStorage.removeItem(paydownId);
    }

    if (value === PAYMENT_METHOD_TYPE.mailCheck) {
      // When we change the method to the mail check and no cached data exists we should save values
      // for the paydownId, accountNumber and routingNumber
      if (!hasCachedData) {
        const wirePaymentData = { paydownId, accountNumber, routingNumber };
        sessionStorage.setItem(paydownId, JSON.stringify(wirePaymentData));
      }
    }
  };

  useEffect(() => {
    if (isLienPrincipalAmountBalance && isPayoff === 'false') {
      updateFormData({ feePaydownAmount: 0 });
    }
  }, [updateFormData, isPayoff, isLienPrincipalAmountBalance]);

  const paydownAmount = Number(principalPaydownAmount || '0.00') + Number(feePaydownAmount || '0.00');
  const handleModalConfirm = () => {
    setModalOpen(false);
    updateFormData({ principalPaydownAmount: undefined });
  };

  const handleModalCancel = () => {
    setModalOpen(false);
    updateLienFormData({ onPropertyReport: 'true' });
  };

  return (
    <>
      <div className="FormRow">
        <div className="FormItem">
          <SelectionYesNoSet
            label="Will this be a payoff or a paydown?"
            required
            showRequiredAsterisk={false}
            isWithMarginBottom={false}
            disabled={disabled}
            options={[
              {
                label: 'Payoff',
                value: 'true',
                disabled,
              },
              {
                label: 'Paydown',
                value: 'false',
                disabled: disabled || isPayoffOnlyLienKind,
              },
            ]}
            {...registerField('isPayoff')}
          />
        </div>
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Principal paydown amount (optional)"
            padFractionalZeros={true}
            disabled={disabled || !isOnPropertyReport}
            showRequiredAsterisk={false}
            validator={value => validateUsd(value, false)}
            {...registerField('principalPaydownAmount')}
          />
        </div>
        {/* Hide the Fee Paydown field for lien in the LIEN_KINDS_PRINCIPAL_AMOUNT_BALANCE */}
        {!(isLienPrincipalAmountBalance && isPayoff === 'false') && (
          <div className="FormItem">
            <CurrencyInput
              label="Fee paydown amount (optional)"
              padFractionalZeros={true}
              disabled={disabled}
              showRequiredAsterisk={false}
              validator={value => validateUsd(value, false)}
              {...registerField('feePaydownAmount')}
            />
          </div>
        )}
      </div>
      {paydownAmount > homeValue && (
        <div className="FormRow">
          <DetailInformationBlock
            type="error"
            text="This paydown exceeds the balance of the Home Value and indicates that this track should be disqualified. Please discuss with your Investment Manager."
          />
        </div>
      )}
      <div className="FormRow">
        <DatePicker
          label="Good through date (optional)"
          theme="outlined"
          disabled={disabled}
          className="FullWidthDatePicker"
          wrapperClassName="FullWidthDatePicker"
          showRequiredAsterisk={false}
          warning={goodThroughDate && validateDateNotInThePast(goodThroughDate)}
          showWarningIcon={true}
          {...registerField('goodThroughDate')}
          helperText={helperText}
        />
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Per diem (optional)"
            padFractionalZeros={true}
            disabled={disabled}
            showRequiredAsterisk={false}
            validator={value => validateUsd(value, false)}
            {...registerField('perDiem')}
          />
        </div>
        <div className="FormItem">
          <MuiSelect
            label="Method"
            classNamePrefix="MortgageLienSelect"
            options={lienPaymentMethods}
            disabled={disabled}
            theme="outlined"
            width="100%"
            showRequiredAsterisk={false}
            required={true}
            {...registerField('paymentMethod')}
            onChange={(value, name, err) => {
              onPaymentMethodChange(value);
              handleFieldChange(value, name, err);
            }}
          />
        </div>
      </div>
      <div className="FormRow">
        <div className="PaydownNameField">
          <MuiTextInput
            label="Paydown name"
            theme="outlined"
            width="100%"
            required
            disabled={disabled}
            showRequiredAsterisk={false}
            endAdornment={
              <Tooltip position="bottom-start" content={PAYDOWN_NAME_TOOLTIP_CONTENT}>
                <Icon name="info" />
              </Tooltip>
            }
            validator={value => validateMaxStringLength(value, MAX_PAYDOWN_NAME)}
            {...registerField('paydownName')}
          />
        </div>
      </div>
      <div className="FormRow">
        <div>
          <FlexibleTextarea
            label={getLabel({ label: 'Wiring instructions or address for check', required: isMailCheckMethod })}
            disabled={disabled || !paymentMethod}
            required={isMailCheckMethod}
            {...registerField('mailingInstructions')}
          />
        </div>
      </div>
      {!isMailCheckMethod && (
        <div className="TwoItemFormRow">
          <div className="FormItem">
            <MuiTextInput
              label="Target account number"
              theme="outlined"
              width="100%"
              disabled={disabled || !paymentMethod}
              required={isWireMethod}
              showRequiredAsterisk={false}
              {...registerField('accountNumber')}
              error={
                canShowWirePaymentErrors &&
                (validateNonEmptyString(accountNumber) ||
                  validateMinStringLength(accountNumber, MIN_ACCOUNT_NUMBER_LENGTH))
              }
            />
          </div>
          <div className="FormItem">
            <MuiTextInput
              label="Routing number"
              theme="outlined"
              width="100%"
              disabled={disabled || !paymentMethod}
              required={isWireMethod}
              showRequiredAsterisk={false}
              {...registerField('routingNumber')}
              error={
                canShowWirePaymentErrors &&
                (validateNonEmptyString(routingNumber) ||
                  validateMinStringLength(routingNumber, MIN_MAX_ROUTING_NUMBER_LENGTH) ||
                  validateMaxStringLength(routingNumber, MIN_MAX_ROUTING_NUMBER_LENGTH))
              }
            />
          </div>
        </div>
      )}
      {modalOpen && (
        <ModalConfirm
          theme="danger"
          open={modalOpen}
          header="Confirm Change to On Property Report"
          confirmText="Ok"
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          onClose={handleModalCancel}
        >
          <p>This will zero out the Principal Paydown entry - do you wish to proceed?</p>
        </ModalConfirm>
      )}
    </>
  );
};
