import MinimumRequiredDocs from '../../components/TaskDetail/TaskSpecificContent/MinimumRequiredDocs/MinimumRequiredDocs';
import CreditReport from '../../components/TaskDetail/TaskSpecificContent/CreditReport/CreditReport';
import ValidityConfirmation from '../../components/TaskDetail/TaskSpecificContent/ValidityConfirmation/ValidityConfirmation';
import RiskBandCheck from '../../components/TaskDetail/TaskSpecificContent/RiskBandCheck/RiskBandCheck';
import TitleSearch from '../../components/TaskDetail/TaskSpecificContent/TitleSearch/TitleSearch';
import RedFlagCheck from '../../components/TaskDetail/TaskSpecificContent/RedFlagCheck/RedFlagCheck';
import BackgroundCheck from '../../components/TaskDetail/TaskSpecificContent/BackgroundCheck/BackgroundCheck';
import FloodZoneCheck from '../../components/TaskDetail/TaskSpecificContent/FloodZoneCheck/FloodZoneCheck';
import ReviewPropertyTax, {
  PROPERTY_TAX_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/ReviewPropertyTax/ReviewPropertyTax';
import GovernmentIDReview, {
  GOVT_ID_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/GovernmentIDReview/GovernmentIDReview';
import HomeownersInsuranceReview, {
  getHomeownersInsuranceAdditionalTodosToCreate,
  HO_INSURANCE_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/HomeownersInsuranceReview/HomeownersInsuranceReview';
import ReplacementCostEstimatorReview, {
  getIsValidReplacementCostEstimatorReviewTaskForm,
  REPLACEMENT_COST_ESTIMATOR_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/ReplacementCostEstimatorReview/ReplacementCostEstimatorReview';
import FloodInsuranceReview, {
  FLOOD_INSURANCE_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/FloodInsuranceReview/FloodInsuranceReview';
import ProofOfRentalReview, {
  getIsValidProofOfRentalReviewTaskForm,
  getProofOfRentalReviewAdditionalTodosToCreate,
  PROOF_OF_RENTAL_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/ProofOfRentalReview/ProofOfRentalReview';
import HoaDocReview, {
  getIsValidHoaDocReviewTaskForm,
  HOA_DOC_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/HoaDocReview/HoaDocReview';

import OtherInsurance, {
  OTHER_INSURANCE_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/OtherInsurance/OtherInsurance';
import CondoMasterPolicyReview, {
  CONDO_MASTER_POLICY_REVIEW_DEMO_DATA,
  getCondoMasterPolicyReviewAdditionalTodosToCreate,
} from '../../components/TaskDetail/TaskSpecificContent/CondoMasterPolicyReview/CondoMasterPolicyReview';
import { getBasicDocInvalidationNotes, getBasicDocInvalidationReason } from '../../trackTasksUtils';
import { MORTGAGE_LIEN_DEMO_DATA } from '../../components/TaskDetail/TaskSpecificContent/MortgageLienReview/MortgageLienReview';
import { MortgageReviewController } from '../../components/TaskDetail/TaskSpecificContent/MortgageLienReview/MortgageLienReviewController';
import {
  SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION,
  SENIOR_LIEN_REVIEW_TASK_DEFINITION,
  SENIOR_LIEN_ESCALATE_TASK_DEFINITION,
  REVIEW_PROPERTY_TAX_TASK_DEFINITION,
  REVIEW_TRADITIONAL_APPRAISAL_TASK_DEFINITION,
  REVIEW_VIRTUAL_APPRAISAL_TASK_DEFINITION,
} from 'data/constants/bpmConstants';
import SeniorLienDataEntry, {
  SENIOR_LIEN_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/SeniorLienDataEntry/SeniorLienDataEntry';
import SeniorLienReview from '../../components/TaskDetail/TaskSpecificContent/SeniorLienReview/SeniorLienReview';
import {
  getIsValidHOAFeeReviewTaskForm,
  HOA_FEE_DEMO,
  HOAFeeReview,
} from '../../components/TaskDetail/TaskSpecificContent/HOAFeeReview/HOAFeeReview';
import { buildGetInvalidDocWithExpiryReason } from '../../utils/tasks';
import ReviewAppraisal, {
  REVIEW_APPRAISAL_DEMO_DATA,
} from '../../components/TaskDetail/TaskSpecificContent/ReviewAppraisal/ReviewAppraisal';

const TASK_SPECIFIC_CONTENT_DEFINITIONS = {
  // Task Kind i.e. old definition -> Conditions Task Content
  credit_report: {
    content: CreditReport,
  },
  minimum_required_docs: {
    content: MinimumRequiredDocs,
  },
  validity_confirmation_smart_task_v1: {
    content: ValidityConfirmation,
  },
  risk_band_check_smart_task_v1: {
    content: RiskBandCheck,
  },
  title_search_smart_task_v1: {
    content: TitleSearch,
  },
  red_flag_check_smart_task_v1: {
    content: RedFlagCheck,
  },
  background_check_smart_task_v1: {
    content: BackgroundCheck,
  },
  flood_zone_check: {
    content: FloodZoneCheck,
  },
  hoa_organization_documents: {
    content: HoaDocReview,
    getDocInvalidationReason: getBasicDocInvalidationReason,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
    demoFormData: HOA_DOC_DEMO_DATA,
    validatorForm: getIsValidHoaDocReviewTaskForm,
  },
  gov_id: {
    content: GovernmentIDReview,
    demoFormData: GOVT_ID_DEMO_DATA,
    getDocInvalidationReason: buildGetInvalidDocWithExpiryReason('expirationDate'),
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  ho_insurance: {
    content: HomeownersInsuranceReview,
    demoFormData: HO_INSURANCE_DEMO_DATA,
    getDocInvalidationReason: buildGetInvalidDocWithExpiryReason('renewalDate'),
    getAdditionalTodosToCreate: getHomeownersInsuranceAdditionalTodosToCreate,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  replacement_cost_estimator_on_hoi: {
    content: ReplacementCostEstimatorReview,
    demoFormData: REPLACEMENT_COST_ESTIMATOR_DEMO_DATA,
    validatorForm: getIsValidReplacementCostEstimatorReviewTaskForm,
    getDocInvalidationReason: getBasicDocInvalidationReason,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  replacement_cost_estimator_on_condo_master_policy: {
    content: ReplacementCostEstimatorReview,
    demoFormData: REPLACEMENT_COST_ESTIMATOR_DEMO_DATA,
    validatorForm: getIsValidReplacementCostEstimatorReviewTaskForm,
    getDocInvalidationReason: getBasicDocInvalidationReason,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  flood_insurance: {
    content: FloodInsuranceReview,
    demoFormData: FLOOD_INSURANCE_DEMO_DATA,
    getDocInvalidationReason: buildGetInvalidDocWithExpiryReason('renewalDate'),
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  proof_of_rental: {
    content: ProofOfRentalReview,
    demoFormData: PROOF_OF_RENTAL_DEMO_DATA,
    validatorForm: getIsValidProofOfRentalReviewTaskForm,
    getDocInvalidationReason: getBasicDocInvalidationReason,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
    getAdditionalTodosToCreate: getProofOfRentalReviewAdditionalTodosToCreate,
  },
  other_insurance: {
    content: OtherInsurance,
    demoFormData: OTHER_INSURANCE_DEMO_DATA,
    getDocInvalidationReason: buildGetInvalidDocWithExpiryReason('renewalDate'),
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  condo_master_policy: {
    content: CondoMasterPolicyReview,
    demoFormData: CONDO_MASTER_POLICY_REVIEW_DEMO_DATA,
    getDocInvalidationReason: buildGetInvalidDocWithExpiryReason('renewalDate'),
    getAdditionalTodosToCreate: getCondoMasterPolicyReviewAdditionalTodosToCreate,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  mortgage_statement: {
    content: MortgageReviewController,
    demoFormData: MORTGAGE_LIEN_DEMO_DATA,
    getDocInvalidationReason: getBasicDocInvalidationReason,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  [SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION]: {
    content: SeniorLienDataEntry,
    demoFormData: SENIOR_LIEN_DEMO_DATA,
  },
  [SENIOR_LIEN_REVIEW_TASK_DEFINITION]: {
    content: SeniorLienReview,
    demoFormData: SENIOR_LIEN_DEMO_DATA,
  },
  [SENIOR_LIEN_ESCALATE_TASK_DEFINITION]: {
    content: SeniorLienReview,
    demoFormData: SENIOR_LIEN_DEMO_DATA,
  },
  proof_of_hoa_fees: {
    content: HOAFeeReview,
    demoFormData: HOA_FEE_DEMO,
    validatorForm: getIsValidHOAFeeReviewTaskForm,
    getDocInvalidationReason: getBasicDocInvalidationReason,
    getDocInvalidationNotes: getBasicDocInvalidationNotes,
  },
  [REVIEW_PROPERTY_TAX_TASK_DEFINITION]: {
    content: ReviewPropertyTax,
    demoFormData: PROPERTY_TAX_DEMO_DATA,
  },
  [REVIEW_TRADITIONAL_APPRAISAL_TASK_DEFINITION]: {
    content: ReviewAppraisal,
    demoFormData: REVIEW_APPRAISAL_DEMO_DATA,
  },
  [REVIEW_VIRTUAL_APPRAISAL_TASK_DEFINITION]: {
    content: ReviewAppraisal,
    demoFormData: REVIEW_APPRAISAL_DEMO_DATA,
  },
};

export default TASK_SPECIFIC_CONTENT_DEFINITIONS;
