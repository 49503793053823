import useConfigurations from 'hooks/useConfigurations';

const useDocumentKinds = () => {
  const { documentKinds, documentKindsWithPerson } = useConfigurations();

  const documentKindsDropdownOptions = [];
  const documentKindToData = {};
  const newCodeToOldCode = {};
  for (const docKind of documentKinds) {
    const { code, oldCode, displayName, canUpload, isDeprecated } = docKind;
    if (canUpload && !isDeprecated) {
      documentKindsDropdownOptions.push({
        value: oldCode || code, // default to the new code for other
        label: displayName,
      });
    }
    documentKindToData[oldCode] = docKind;
    newCodeToOldCode[code] = oldCode;
  }

  const getDocumentKindVisibility = documentType => {
    const isInternalOnly = documentKindToData[documentType]?.isInternalOnly ?? false;
    return {
      visibilityLabel: isInternalOnly ? 'Internal' : 'Both',
      isVisibilityEditable: !isInternalOnly,
    };
  };

  const getDocumentTypeDisplayName = documentType => {
    return getDocKindData(documentType)?.displayName || 'Other';
  };

  const isDocumentType = documentType => {
    return documentType !== '' && documentType !== 'other' && getDocKindData(documentType) !== undefined;
  };

  const getDocKindData = documentType => {
    return documentKindToData[documentType] ?? documentKindToData[newCodeToOldCode[documentType]];
  };

  const canUploadDocument = documentType => {
    return getDocKindData(documentType)?.canUpload ?? false;
  };

  const canEditDocument = documentType => {
    return getDocKindData(documentType)?.canEdit ?? false;
  };

  const canDeleteDocument = documentType => {
    return getDocKindData(documentType)?.canDelete ?? false;
  };

  return {
    documentKindsDropdownOptions,
    documentKindsWithPerson,
    getDocumentKindVisibility,
    getDocumentTypeDisplayName,
    isDocumentType,
    canUploadDocument,
    canEditDocument,
    canDeleteDocument,
  };
};

export default useDocumentKinds;
