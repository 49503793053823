import { Divider } from '@mui/material';
import SliderFormBody from '../common/SliderFormBody';
import SliderFormField from '../common/SliderFormField';
import SliderFormSection from '../common/SliderFormSection';
import SliderFormText from '../common/SliderFormText';
import { Checkbox, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import cx from 'classnames';
import { emailValidator, phoneValidator } from 'utils/validators';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import type { FormErrors, LabelValue, RegisterField } from 'apps/types/genericTypes';
import type { AppraisalOrderFormValues, AppraisalOrderPrefilledOrderValues } from '../types/types';
import './AppraisalsFormsStyle.scss';

export type AppraisalOrderFormProps = {
  loading?: boolean;
  isFollowUp: boolean;
  errorMessage?: string;
  isInSlider?: boolean;
  prefilledOrderValues: AppraisalOrderPrefilledOrderValues;
  productOptions: LabelValue[];
  formErrors: FormErrors<AppraisalOrderFormValues>;
  registerField: RegisterField;
  productOnBlur: () => void;
  formData: AppraisalOrderFormValues;
  updateFormData: (newValues: Partial<AppraisalOrderFormValues>) => void;
};

const AppraisalOrderForm = ({
  loading = false,
  isFollowUp,
  errorMessage,
  isInSlider = false,
  prefilledOrderValues,
  productOptions,
  formErrors,
  registerField,
  productOnBlur,
  formData,
  updateFormData = () => {},
}: AppraisalOrderFormProps) => {
  return (
    <SliderFormBody>
      <SliderFormSection heading={'INFO'}>
        <SliderFormField label={'Order ID'} field={<SliderFormText text={prefilledOrderValues?.friendlyId} />} />
        <SliderFormField
          label={'Address'}
          field={
            <div className="flex flex-col">
              <SliderFormText text={prefilledOrderValues?.addressLine1} />
              <SliderFormText text={prefilledOrderValues?.addressLine2} />
            </div>
          }
        />
        <SliderFormField label={'Property Type'} field={<SliderFormText text={prefilledOrderValues?.propertyType} />} />
      </SliderFormSection>
      <Divider />
      <SliderFormSection heading={'APPRAISAL'}>
        <SliderFormField
          label={'Appraisal Type'}
          field={<SliderFormText text={prefilledOrderValues?.appraisalType} />}
        />
        <SliderFormField
          label={'Product'}
          field={
            <MuiSelect
              required
              showRequiredAsterisk={false}
              options={productOptions}
              {...registerField('products')}
              width={'100%'}
              theme="outlined"
              classNamePrefix="SliderFormSelect"
              disabled={loading}
              onBlur={productOnBlur}
            />
          }
        />
      </SliderFormSection>
      <Divider />
      <SliderFormSection heading={'CONTACT'}>
        {errorMessage ? (
          <>
            <SliderFormField label={'First name'} field={<SliderFormText text={formData?.firstName} />} />
            <SliderFormField label={'Last name'} field={<SliderFormText text={formData?.lastName} />} />
            <SliderFormField label={'Email address'} field={<SliderFormText text={formData?.emailAddress} />} />
            <SliderFormField label={'Phone Number'} field={<SliderFormText text={formData?.phoneNumber} />} />
          </>
        ) : (
          <>
            <SliderFormField
              label={'First name'}
              field={
                <MuiTextInput
                  required
                  theme="outlined"
                  {...registerField('firstName')}
                  disabled={loading || isFollowUp}
                  error={formErrors?.firstName?.message}
                />
              }
              className={cx('SliderFormTextInputField', { 'mb-[32px]': formErrors?.firstName })}
            />
            <SliderFormField
              label={'Last name'}
              field={
                <MuiTextInput
                  required
                  theme="outlined"
                  {...registerField('lastName')}
                  disabled={loading || isFollowUp}
                  error={formErrors?.lastName?.message}
                />
              }
              className={cx('SliderFormTextInputField', { 'mb-[32px]': formErrors?.lastName })}
            />
            <SliderFormField
              label={'Email address'}
              field={
                <MuiTextInput
                  required
                  theme="outlined"
                  validator={emailValidator}
                  {...registerField('emailAddress')}
                  disabled={loading || isFollowUp}
                  error={formErrors?.emailAddress?.message}
                />
              }
              className={cx('SliderFormTextInputField', { 'mb-[32px]': formErrors?.emailAddress })}
            />
            <SliderFormField
              label={'Phone Number'}
              field={
                <MuiTextInput
                  required
                  theme="outlined"
                  validator={phoneValidator}
                  {...registerField('phoneNumber')}
                  disabled={loading || isFollowUp}
                  error={formErrors?.phoneNumber?.message}
                />
              }
              className={cx('SliderFormTextInputField', { 'mb-[32px]': formErrors?.phoneNumber })}
            />
          </>
        )}
      </SliderFormSection>
      {errorMessage && (
        <ErrorBlock
          icon={'list'}
          error={
            <>
              {errorMessage} Please try again or <a href="https://hometap.slack.com/archives/CBN5REDQ9">reach out</a> to
              report the issue.
            </>
          }
        />
      )}

      {!isInSlider && (
        <Checkbox
          disabled={loading}
          label={<strong className="text-sm">Rush order</strong>}
          checked={formData?.rush}
          onChange={(value: boolean) => updateFormData({ rush: value })}
        />
      )}
    </SliderFormBody>
  );
};

export default AppraisalOrderForm;
