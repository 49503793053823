import { getAPStyleTime, parseCreatedAtDateParts } from '../../../utils/dates';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { getVariableValue } from './components/TaskList/TaskListUtils';
import {
  SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION,
  SENIOR_LIEN_ESCALATE_TASK_DEFINITION,
  SENIOR_LIEN_REVIEW_TASK_DEFINITION,
  UPLOAD_FILE_TASK_DEFINITION,
  VERIFY_FILE_TASK_DEFINITION,
  REVIEW_PROPERTY_TAX_TASK_DEFINITION,
  REVIEW_TRADITIONAL_APPRAISAL_TASK_DEFINITION,
  REVIEW_VIRTUAL_APPRAISAL_TASK_DEFINITION,
} from 'data/constants/bpmConstants';

export const getFirstIncompleteTask = (tasks = [], reviewStatusValue) => {
  const filteredTasks = reviewStatusValue
    ? tasks.filter(({ reviewStatus }) => reviewStatusValue === reviewStatus)
    : tasks;

  const inProcessTask = filteredTasks.find(({ taskStatus }) => taskStatus === TASK_STATUSES.IN_PROCESS);

  if (inProcessTask) return inProcessTask;

  const notStartedTask = filteredTasks.find(({ taskStatus }) => taskStatus === TASK_STATUSES.NOT_STARTED);
  return notStartedTask || filteredTasks?.[0] || tasks?.[0];
};

export const getMostRecentChangeDescription = mostRecentChanges => {
  if (!mostRecentChanges) {
    return;
  }

  const { historyUser, historyDate, isTaskCondition, taskConditionKind } = mostRecentChanges;

  let recentChangeData = [];

  if (historyUser) {
    recentChangeData.push(historyUser);
  }

  if (historyDate) {
    const [month, year, hours] = parseCreatedAtDateParts(historyDate);
    let eventWithMonth = month;

    if (isTaskCondition && taskConditionKind) {
      eventWithMonth = `${taskConditionKind} ${month}`;
    }

    recentChangeData = [...recentChangeData, eventWithMonth, year, getAPStyleTime(hours)];
  }

  if (recentChangeData.length > 0) {
    return `Last Changed: ${recentChangeData.join(', ')}`;
  }
  return '';
};

/** Group task list by type within each review status, in chronological order
 * and update the label kind label for the TASK_KINDS_WITH_UPDATED_KIND_LABEL.
 * @param {Task[]} tasks
 * @return {Task[]} sorted and updated tasks
 */
export const getSortedInternalTasks = tasks => {
  const mappedTasksByKind = [...tasks]
    .sort((a, b) => a.isManual - b.isManual)
    .reduce(
      (acc, task) => {
        const docKindElseTaskDefKey = getDocKindElseTaskDefKey(task);

        return acc[docKindElseTaskDefKey]
          ? { ...acc, [docKindElseTaskDefKey]: [...acc[docKindElseTaskDefKey], task] }
          : {
              ...acc,
              [docKindElseTaskDefKey]: [task],
            };
      },

      {},
    );

  const sortedTasksWithUpdatedKindLabel = [];
  for (const taskKind in mappedTasksByKind) {
    // sorting in chronological order
    mappedTasksByKind[taskKind] = mappedTasksByKind[taskKind].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    );
    sortedTasksWithUpdatedKindLabel.push(...mappedTasksByKind[taskKind]);
  }
  return sortedTasksWithUpdatedKindLabel;
};

/**
 * Returns the doc kind else the task definition key
 * @param {Task} task - The task object.
 * @returns {string} - The doc kind or the legacy task definition key i.e. the task kind
 */
export const getDocKindElseTaskDefKey = task => {
  if (
    task?.taskDefinitionKey === UPLOAD_FILE_TASK_DEFINITION ||
    task?.taskDefinitionKey === VERIFY_FILE_TASK_DEFINITION
  ) {
    return getVariableValue(task, 'doc_kind');
  }
  return task?.taskDefinitionKey;
};

export const getIsSimpleReviewTask = taskDefinitionKey =>
  taskDefinitionKey !== UPLOAD_FILE_TASK_DEFINITION &&
  taskDefinitionKey !== VERIFY_FILE_TASK_DEFINITION &&
  taskDefinitionKey !== SENIOR_LIEN_REVIEW_TASK_DEFINITION &&
  taskDefinitionKey !== SENIOR_LIEN_ESCALATE_TASK_DEFINITION &&
  taskDefinitionKey !== SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION &&
  taskDefinitionKey !== REVIEW_PROPERTY_TAX_TASK_DEFINITION &&
  taskDefinitionKey !== REVIEW_TRADITIONAL_APPRAISAL_TASK_DEFINITION &&
  taskDefinitionKey !== REVIEW_VIRTUAL_APPRAISAL_TASK_DEFINITION;

export const getIsBpmTask = taskDefinitionKey =>
  taskDefinitionKey === REVIEW_TRADITIONAL_APPRAISAL_TASK_DEFINITION ||
  taskDefinitionKey === REVIEW_VIRTUAL_APPRAISAL_TASK_DEFINITION;
/**
 * @param {Record<string, *>} [formData]
 * @returns {string | null}
 */
export const getBasicDocInvalidationReason = ({ isValid, reason }) => {
  return isValid === 'false' ? reason : null;
};

/**
 * @param {Record<string, *>} [formData]
 * @returns {string | null}
 */
export const getBasicDocInvalidationNotes = ({ isValid, reason, reasonNote }) => {
  if (reasonNote && isValid === 'false' && reason === 'other') {
    return reasonNote;
  }
  return null;
};

export const capitalizeFirstLetter = value => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};
