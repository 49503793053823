import cx from 'classnames';
import { Icon } from '@hometap/htco-components';

type SliderFormTitleProps = {
  title: string;
  onClose: () => void;
  className?: string;
  closeDisabled?: boolean;
  renderClose?: boolean;
};

const SliderFormTitle = ({
  title,
  onClose,
  className,
  closeDisabled = false,
  renderClose = true,
}: SliderFormTitleProps) => {
  return (
    <div
      className={cx(
        'flex h-[62px] w-full items-center justify-between border-b border-neutral-light-100 p-[24px] text-lg font-bold',
        className,
      )}
    >
      <span>{title}</span>
      {renderClose && (
        <button
          onClick={onClose}
          disabled={closeDisabled}
          className={`${closeDisabled ? 'cursor-default' : 'cursor-pointer'}`}
          aria-label="SliderForm-close-button"
        >
          <Icon name="close" size="lg" className={`${closeDisabled ? 'opacity-50' : 'opacity-100'}`} />
        </button>
      )}
    </div>
  );
};

export default SliderFormTitle;
