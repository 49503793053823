import React, { useState, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Button, Checkbox, Loader, useAsync, MuiSelect } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { getInitialSectionsAdverseEvents, getNewAdverseEventSection, sortAdverseEvents } from './utils/adverseEvents';
import { useAdverseEventSections } from './hooks/useAdverseEventSections';
import { AdverseEventForm } from './components/AdverseEventForm';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { useTrackApplicants } from '../../../Documents/hooks/useTrackApplicants';
import { SET_TRACK_HAS_NO_ADVERSE_EVENTS } from '../../data/mutations/setTrackHasNoAdverseEvents';
import { GET_ADVERSE_EVENT_DATA } from 'apps/track-details/ApplicationReview/data/queries/getAdverseEventData';
import { SECTION_ALERT_TYPES, useFormsForAppReviewSection } from '../../hooks/useSpecificContentQuery';
import './AdverseEventController.scss';
import { HeapWrapper } from 'components/HeapWrapper/HeapWrapper';
import DemoButton from '../../../../../components/DemoButton/DemoButton';
import { createAdverseEvent } from '../../../../../data/trackRequest';
import env from 'utils/env';
const isResolvedWithinYears = (resolutionDate, years) => dayjs().diff(resolutionDate, 'year') < years;
const existingAndValid = form => !form?.formData?.newAdverseEvent || form?.isValidForm;

const SECTION_ALERT_DEFINITIONS_IN_PRIORITY_ORDER = [
  {
    type: SECTION_ALERT_TYPES.ERROR,
    message: 'Please correct the form errors in order to publish changes',
    getTriggeredDetails: idToDBRecordAndForm => {
      const triggeringIds = Object.entries(idToDBRecordAndForm)
        // TODO https://hometap.atlassian.net/browse/EH-533 Rename newAdversEvent -> isDraft.
        // Check that form exists, is not deleted, is not valid, and is not a new lien
        .filter(([_, { form }]) => form?.formData && !form.isValidForm && form.showSubmitErrors)
        .map(([id, _]) => id);
      return {
        isTriggered: triggeringIds.length > 0,
        triggeringIds,
      };
    },
  },
  {
    type: SECTION_ALERT_TYPES.ERROR,
    message:
      'There are more than three adverse events on this track that were resolved within the last 7 years. Please coordinate with your IM to Disqualify this track.',
    getTriggeredDetails: idToDBRecordAndForm => {
      const eventIdsResolvedInLast7Years = Object.entries(idToDBRecordAndForm)
        // Filter out the events that are not resolved in the last 7 years
        .filter(([_, { form, getCurrentValue }]) => {
          // Ignore deleted forms
          if (form?.isDeleted) return false;
          const resolutionDate = getCurrentValue('resolutionDate');
          // Is the resolutionDate from the last 7 years?
          return resolutionDate ? isResolvedWithinYears(resolutionDate, 7) : false;
        })
        // Get the ids for the return
        .map(([id]) => id);
      // If there are 3+ events resolved in the last 7 years, display a DQ error. Note that section level DQ errors don’t display the icon next to the triggering form ids
      return eventIdsResolvedInLast7Years.length > 2
        ? {
            isTriggered: true,
          }
        : null;
    },
  },
  {
    type: SECTION_ALERT_TYPES.ERROR,
    message: 'One or more of the Adverse Events listed imply a DQ for this track.',
    getTriggeredDetails: idToDBRecordAndForm => {
      const conditions = [
        {
          // Short Sales resolved in last 5 years)
          filter: ([_, { form, getCurrentValue }]) => {
            const resolutionDate = getCurrentValue('resolutionDate');
            const kind = getCurrentValue('kind');
            return kind?.toLowerCase() === 'shortsale' && resolutionDate && existingAndValid(form)
              ? isResolvedWithinYears(resolutionDate, 5)
              : false;
          },
        },
        {
          // Events without a resolution date
          filter: ([_, { form, getCurrentValue }]) => {
            const resolutionDate = getCurrentValue('resolutionDate');
            return !resolutionDate && existingAndValid(form);
          },
        },
        {
          // Bankruptcies resolved in last year
          filter: ([_, { form, getCurrentValue }]) => {
            const resolutionDate = getCurrentValue('resolutionDate');
            const kind = getCurrentValue('kind');
            return kind?.toLowerCase() === 'bankruptcy' && resolutionDate && existingAndValid(form)
              ? isResolvedWithinYears(resolutionDate, 1)
              : false;
          },
        },
        {
          // Foreclosures resolved in last 5 years
          filter: ([_, { form, getCurrentValue }]) => {
            const resolutionDate = getCurrentValue('resolutionDate');
            const kind = getCurrentValue('kind');
            return kind?.toLowerCase() === 'foreclosure' && resolutionDate && existingAndValid(form)
              ? isResolvedWithinYears(resolutionDate, 5)
              : false;
          },
        },
      ];

      const triggeringIds = conditions.reduce((acc, { filter }) => {
        const ids = Object.entries(idToDBRecordAndForm)
          .filter(filter)
          .map(([id]) => id);
        return acc.concat(ids);
      }, []);

      return {
        isTriggered: triggeringIds.length > 0,
        triggeringIds,
      };
    },
  },
  {
    type: SECTION_ALERT_TYPES.WARNING,
    message: 'Potential duplicate events.',
    getTriggeredDetails: idToDBRecordAndForm => {
      const hashToIds = Object.entries(idToDBRecordAndForm).reduce((acc, [id, { getCurrentValue }]) => {
        const resolutionDate = getCurrentValue('resolutionDate');
        const kind = getCurrentValue('kind')?.toLowerCase();
        if (resolutionDate && kind) {
          const hash = `${resolutionDate}-${kind}`;
          acc[hash] = acc[hash] ? [...acc[hash], id] : [id];
        }
        return acc;
      }, {});
      // Return the ids if there are 2+ events with the same resolutionDate and kind
      const triggeringIds = Object.values(hashToIds)
        .filter(ids => ids.length > 1)
        .flat();

      return {
        isTriggered: triggeringIds.length > 1,
        triggeringIds,
      };
    },
  },
];

export const AdverseEventController = ({ editDisabled }) => {
  const client = useApolloClient();
  const { trackId } = useParams();
  const { historyAdverseEventUrl } = getApplicationReviewPageUrls(trackId);
  const {
    loading = true,
    error,
    data,
    refetch,
  } = useQuery(GET_ADVERSE_EVENT_DATA, { variables: { trackId }, fetchPolicy: 'no-cache' });
  const {
    adverseEvents,
    adverseEventKindOptions,
    adverseEventBankruptcyOrderOptions,
    adverseEventBankruptcyChapterOptions,
    noAdverseEventsToReview,
  } = data?.track || {};
  const { applicantsOptions } = useTrackApplicants(trackId);
  const [demoAdverseEventsKind, setAdverseEventsKind] = useState(
    adverseEventKindOptions ? adverseEventKindOptions[0].value : 'bankruptcy',
  );
  const memoizedAdverseEvents = useMemo(
    () => getInitialSectionsAdverseEvents(adverseEvents, adverseEventKindOptions),
    [adverseEvents, adverseEventKindOptions],
  );

  const sortedAdverseEvents = sortAdverseEvents(adverseEvents);
  const sortedMemoizedAdverseEvents = sortAdverseEvents(memoizedAdverseEvents);
  const [sections, setSections] = useState(sortedMemoizedAdverseEvents); // i.e. tabs
  const [currentSection, setCurrentSection] = useState((sortedAdverseEvents && sortedAdverseEvents[0]) || {}); // i.e. current tab
  const [noEventsBoxWasChecked, setNoEventsBoxWasChecked] = useState();
  const [hasNoAdverseEvents, setHasNoAdverseEvents] = useState();

  // Set initial value of hasNoAdverseEvents checkbox once the data has been fetched
  useEffect(() => {
    setHasNoAdverseEvents(noAdverseEventsToReview);
    setNoEventsBoxWasChecked(noAdverseEventsToReview);
  }, [noAdverseEventsToReview]);

  const { execute: createDemoAdverseEvent } = useAsync(createAdverseEvent, {
    onSuccess: () => {
      refetch();
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: `created a new demo adverse event`,
      });
    },
    onError: err => {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to create an adverse event',
        description: err.message || 'An error occurred while creating adverse event.',
      });
    },
  });

  const {
    isValid,
    isSaving,
    isChanged,
    confirmSection,
    cancelSectionChanges,
    getHighestPrioritySectionAlert,
    setSectionFormsMap,
  } = useFormsForAppReviewSection({
    sectionAlertDefinitionsInPriorityOrder: SECTION_ALERT_DEFINITIONS_IN_PRIORITY_ORDER,
  });

  // TODO https://hometap.atlassian.net/browse/EH-569 Consolidate App Review Section Tab related code into a single hook with settings
  useEffect(() => {
    // Update tabs with the latest adverse events and adjust the current section accordingly.
    if (!loading && data) {
      // Set the tabs to the lastest from the database
      setSections(sortedMemoizedAdverseEvents);
      // Set the current tab
      setCurrentSection(currentSection => {
        // TODO How do we deal with the "new-..." keyed tabs that are now invalid?
        const updatedCurrentSection = sortedMemoizedAdverseEvents.find(
          adverseEvent => adverseEvent.identifier === currentSection.identifier,
        );
        return updatedCurrentSection || (sortedMemoizedAdverseEvents && sortedMemoizedAdverseEvents[0]) || {};
      });
      // Reset the forms in memory when the tabs are updated from the database
      setSectionFormsMap({});
    }
  }, [data, loading, sortedMemoizedAdverseEvents, setSectionFormsMap]);

  const { adverseEventSections } = useAdverseEventSections(
    sections,
    currentSection.identifier,
    adverseEventKindOptions,
  );
  const [prevSectionId, setPrevSectionId] = useState(sortedAdverseEvents && sortedAdverseEvents[0]?.identifier);

  const handleHasAdverseEventsChange = async noEventsValueToSet => {
    await setHasNoAdverseEvents(noEventsValueToSet);

    const variables = {
      trackId,
      hasNoAdverseEvents: noEventsValueToSet,
    };

    try {
      await client.mutate({
        mutation: SET_TRACK_HAS_NO_ADVERSE_EVENTS,
        variables,
      });
    } catch (error) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to save changes',
        description: error.message || 'An error occurred while updating the adverse event section.',
      });
    }
  };

  const handleHasAdverseEventsCheckboxChange = () => {
    handleHasAdverseEventsChange(!hasNoAdverseEvents);
    setNoEventsBoxWasChecked(!hasNoAdverseEvents);
  };

  const changeCurrentSection = selectorId => {
    // i.e. change current tab/item
    if (selectorId !== currentSection.selectorId) {
      const selectedSection = adverseEventSections.find(adverseEvent => adverseEvent.selectorId === selectorId);
      setPrevSectionId(currentSection.identifier);
      setCurrentSection(selectedSection);
    }
  };

  const handleDeleteAdverseEvent = deletedAdverseEventId => {
    const updatedSections = adverseEventSections.filter(section => section.identifier !== deletedAdverseEventId);
    setSections(updatedSections);

    if (currentSection.identifier === deletedAdverseEventId) {
      setCurrentSection(updatedSections[0] ?? {});
    }

    if (noEventsBoxWasChecked && !updatedSections.length) {
      handleHasAdverseEventsChange(true);
    }
  };

  if (error) {
    return <div>Please try refreshing. If the problem persists please contact engineering.</div>;
  }

  if (loading) {
    <div className="CenterAlign">
      <Loader type="dot-pulse" />
    </div>;
  }

  const saveChanges = async () => {
    await confirmSection();
    setNoEventsBoxWasChecked(false);
    setHasNoAdverseEvents(false);
    refetch();
  };

  const cancelChanges = () => {
    cancelSectionChanges(currentSection.identifier);
    const newSections = getInitialSectionsAdverseEvents(sortedAdverseEvents, adverseEventKindOptions);
    setSections(newSections);

    if (!newSections.find(sections => sections.selectorId === currentSection?.selectorId)) {
      if (newSections.length < 1) setCurrentSection({});
      else setCurrentSection(newSections[0]);
    }

    if (noEventsBoxWasChecked && !newSections.length) {
      handleHasAdverseEventsChange(true);
    }
  };

  const sectionAlert = getHighestPrioritySectionAlert({ dbRecords: sortedAdverseEvents });

  return (
    <ApplicationReviewSection
      anchorId="adverse-event-section"
      sectionTitle="Adverse events"
      historyUrl={historyAdverseEventUrl}
      pickList={adverseEventSections}
      selectedId={(currentSection && currentSection.selectorId) || 0}
      onSelect={changeCurrentSection}
      isWithActionBar={!isEmpty(currentSection)}
      sectionAlert={sectionAlert}
      actionBarProps={{
        isPrimaryButtonEnabled: isValid && isChanged && !isSaving,
        isSecondaryButtonEnabled: (isChanged && !isSaving) || adverseEvents?.length !== adverseEventSections?.length,
        isShowPrompt: isChanged,
        isSaving,
        onPrimaryButtonClick: saveChanges,
        onSecondaryButtonClick: cancelChanges,
      }}
      onEdit={null}
      bottomLink={
        <>
          <HeapWrapper
            dataId="has-duplicates"
            dataValue={sectionAlert?.message === 'Potential duplicate events.' ? 'true' : 'false'}
          >
            <Button
              theme="link"
              onClick={() => {
                setPrevSectionId(currentSection.identifier);
                const newSection = getNewAdverseEventSection();
                setSections([...adverseEventSections, newSection]);
                setCurrentSection(newSection);
                handleHasAdverseEventsChange(false);
              }}
              disabled={editDisabled}
            >
              Add adverse event
            </Button>
          </HeapWrapper>
          <div className="HasAdverseEventsCheckbox">
            <Checkbox
              label="There are no Adverse Events to enter for this track."
              checked={hasNoAdverseEvents}
              disabled={editDisabled || !!sections?.length}
              onChange={() => handleHasAdverseEventsCheckboxChange()}
            />
          </div>
        </>
      }
      demoButton={
        !env.isProd() && (
          <div className="TwoItemFormRow DemoLienWrapper">
            <DemoButton
              onClickAction={() => {
                handleHasAdverseEventsChange(false);
                createDemoAdverseEvent(trackId, { kind: demoAdverseEventsKind });
              }}
              label="Demo"
              pageLevel={false}
              disabled={editDisabled}
            />
            <MuiSelect
              label="Adverse Events Kind"
              options={adverseEventKindOptions}
              className="DemoLienSelector"
              disabled={editDisabled}
              theme="outlined"
              value={demoAdverseEventsKind}
              onChange={setAdverseEventsKind}
            />
          </div>
        )
      }
    >
      {isEmpty(currentSection) ? (
        <div>
          {!hasNoAdverseEvents
            ? 'There is no adverse event data'
            : 'This section has been intentionally left blank, as there are no Adverse Events to list for this track.'}
        </div>
      ) : (
        <AdverseEventForm
          adverseEvent={currentSection}
          trackId={trackId}
          adverseEventKindOptions={adverseEventKindOptions}
          disabled={editDisabled}
          applicantsOptions={applicantsOptions}
          adverseEventBankruptcyChapterOptions={adverseEventBankruptcyChapterOptions}
          adverseEventBankruptcyOrderOptions={adverseEventBankruptcyOrderOptions}
          onDelete={handleDeleteAdverseEvent}
          prevSectionId={prevSectionId}
          refetch={refetch}
        />
      )}
    </ApplicationReviewSection>
  );
};
