import { BulletItem } from '../types/types';

type UnderwriterBulletListProps = {
  items: BulletItem[];
};

const UnderwriterBulletList = ({ items }: UnderwriterBulletListProps) => {
  return (
    <ul>
      {items.map(({ label, value }, index) => (
        <li key={index}>
          <span className="UnderwriterBulletLabel">{label}</span>
          <span className="UnderwriterBulletValue">{value}</span>
        </li>
      ))}
    </ul>
  );
};

export default UnderwriterBulletList;
